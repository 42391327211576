import React from 'react';

import CRD_FEATURES from './crd_features/crd_features';



export default function TAB_SETTINGS() {

    //  variable declarations ------------------------------------------------------------------------------------------



    //  event listeners ------------------------------------------------------------------------------------------------



    //  functions ------------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (

        <div className='card rounded-lg shadow-sm'>

            <CRD_FEATURES />

        </div>

    );

};