import React, { useState, useEffect } from 'react';
import API from '../../../../../../libs/api-lib';
import { Button, Form, Message } from 'semantic-ui-react';
import form_helper from '../../../../../../libs/form-lib';
import Icon from '../../../../../../components/cmp_icon';
import {FORM_INPUT, FORM_SELECT} from '../../../../../../components/cmp_form/cmp_form';



export default function CRD_NAMEADDRESS({ var_organization_id, var_location, set_location, status, onClick_nextstep }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_errors, set_errors ] = useState([]);

    const [ var_countries ] = useState([{value: null, text: 'Select country'}, {value: 'Canada', text: 'Canada'}, {value: 'United States', text: 'United States'}]);
    const [ var_regions, set_regions ] = useState([]);
    const [ var_disableregions, set_disableregions ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_errors([]);
    }, [status]);

    useEffect(() => {
        if (var_location.country === 'Canada') {
            set_regions([{value: null, text: 'Select region'},
                {value: 'Alberta', text: 'Alberta'},
                {value: 'British Columbia', text: 'British Columbia'},
                {value: 'Manitoba', text: 'Manitoba'},
                {value: 'New Brunswick', text: 'New Brunswick'},
                {value: 'Newfoundland and Labrador', text: 'Newfoundland and Labrador'},
                {value: 'Northwest Territories', text: 'Northwest Territories'},
                {value: 'Nova Scotia', text: 'Nova Scotia'},
                {value: 'Nunavut', text: 'Nunavut'},
                {value: 'Ontario', text: 'Ontario'},
                {value: 'Prince Edward Island', text: 'Prince Edward Island'},
                {value: 'Quebec', text: 'Quebec'},
                {value: 'Saskatchewan', text: 'Saskatchewan'},
                {value: 'Yukon', text: 'Yukon'}]);
            set_disableregions(false);
        } else if (var_location.country === 'United States') {
            set_regions([{value: null, text: 'Select region'},
                {value: 'Alabama', text: 'Alabama'},
                {value: 'Alaska', text: 'Alaska'},
                {value: 'Arizona', text: 'Arizona'},
                {value: 'Arkansas', text: 'Arkansas'},
                {value: 'California', text: 'California'},
                {value: 'Colorado', text: 'Colorado'},
                {value: 'Connecticut', text: 'Connecticut'},
                {value: 'Delaware', text: 'Delaware'},
                {value: 'District of Columbia', text: 'District of Columbia'},
                {value: 'Florida', text: 'Florida'},
                {value: 'Georgia', text: 'Georgia'},
                {value: 'Guam', text: 'Guam'},
                {value: 'Hawaii', text: 'Hawaii'},
                {value: 'Idaho', text: 'Idaho'},
                {value: 'Illinois', text: 'Illinois'},
                {value: 'Indiana', text: 'Indiana'},
                {value: 'Iowa', text: 'Iowa'},
                {value: 'Kansas', text: 'Kansas'},
                {value: 'Kentucky', text: 'Kentucky'},
                {value: 'Louisiana', text: 'Louisiana'},
                {value: 'Maine', text: 'Maine'},
                {value: 'Maryland', text: 'Maryland'},
                {value: 'Massachusetts', text: 'Massachusetts'},
                {value: 'Michigan', text: 'Michigan'},
                {value: 'Minnesota', text: 'Minnesota'},
                {value: 'Mississippi', text: 'Mississippi'},
                {value: 'Missouri', text: 'Missouri'},
                {value: 'Montana', text: 'Montana'},
                {value: 'Nebraska', text: 'Nebraska'},
                {value: 'Nevada', text: 'Nevada'},
                {value: 'New Hampshire', text: 'New Hampshire'},
                {value: 'New Jersey', text: 'New Jersey'},
                {value: 'New Mexico', text: 'New Mexico'},
                {value: 'New York', text: 'New York'},
                {value: 'North Carolina', text: 'North Carolina'},
                {value: 'North Dakota', text: 'North Dakota'},
                {value: 'Ohio', text: 'Ohio'},
                {value: 'Oklahoma', text: 'Oklahoma'},
                {value: 'Oregon', text: 'Oregon'},
                {value: 'Pennsylvania', text: 'Pennsylvania'},
                {value: 'Puerto Rico', text: 'Puerto Rico'},
                {value: 'Rhode Island', text: 'Rhode Island'},
                {value: 'South Carolina', text: 'South Carolina'},
                {value: 'South Dakota', text: 'South Dakota'},
                {value: 'Tennessee', text: 'Tennessee'},
                {value: 'Texas', text: 'Texas'},
                {value: 'Utah', text: 'Utah'},
                {value: 'Vermont', text: 'Vermont'},
                {value: 'Virginia', text: 'Virginia'},
                {value: 'Washington', text: 'Washington'},
                {value: 'West Virginia', text: 'West Virginia'},
                {value: 'Wisconsin', text: 'Wisconsin'},
                {value: 'Wyoming', text: 'Wyoming'}]);
            set_disableregions(false);
        } else {
            set_regions([]);
            set_disableregions(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_location.country]);



    //  functions ------------------------------------------------------------------------------------------------------

    async function check_location_exists(location_name) {
        return await API_get_location_exists(location_name) === 'YES' ? true : false;
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_location_exists(location_name) {
        return API.get('alpha-org', '/get-location-exists/' + var_organization_id,
            {
                queryStringParameters: {
                    location_name: location_name
            }
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event) {
        let location = {...var_location};
        location[event.target.name] = event.target.value;
        set_location(location);
        if (var_errors.length > 0) {
            set_errors(var_errors.filter(error => error.property !== event.target.name))
        }
    }

    async function onClick_next() {
        let location = {...var_location};
        let errors = [];
        // validation
        if (!form_helper.validate_required_string(location.location_name)) {
            errors.push({property: 'location_name', description: 'Assignment name is required.'});
        } else if (await check_location_exists(location.location_name)) {
            errors.push({property: 'location_name', description: 'An assignment with this name already exists.'});
        }
        set_errors(errors);
        if (errors.length > 0) return;

        // cleanse properties
        form_helper.cleanse_string_property(location, 'location_name');
        form_helper.cleanse_string_property(location, 'address');
        form_helper.cleanse_string_property(location, 'city');
        form_helper.cleanse_string_property(location, 'province');
        form_helper.cleanse_string_property(location, 'country');
        set_location(location);
        onClick_nextstep();
    }

    function onChange_country(e, { value }) {
        let location = { ...var_location };
        if (location.country !== value) {
            location.province = null;
        }
        location.country = value;
        set_location(location);
    }

    function onChange_region(e, { value }) {
        let location = { ...var_location };
        location.province = value;
        set_location(location);
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            {status === 'COMPLETE' || status === 'COMPLETE-PENDING'
                ?
                <div className='card__complete'>
                    <div>{var_location.location_name}</div>
                    {render_address()}
                </div>
                :
                <div></div>
            }
            {status === 'CURRENT' && render_form()}
        </>
    );

    function render_address() {
        let lines = [];
        var_location.address && lines.push(var_location.address);
        if (var_location.city) {
            lines.length === 0 ? lines.push(var_location.city) : lines[lines.length-1] += ', ' + var_location.city;
        }
        if (var_location.province) {
            lines.length === 0 ? lines.push(var_location.province) : lines[lines.length-1] += ', ' + var_location.province;
        }
        var_location.country && lines.push(var_location.country);
        return (
            <>
                {lines.map((item, index) => <div key={'locaddress-' + index}>{item}</div>)}
            </>
        );
    }

    function render_form() {
        return (
            <>
                {var_errors.length > 0 &&
                <Message error
                         icon={<Icon name='error' className='icon' />}
                         header={'This step contained errors'}
                />
                }
                <Form>

                    <FORM_INPUT
                        property='location_name'
                        label='Name (required)'
                        value={var_location.location_name}
                        onChange={onChange_input}
                        placeholder='Name'
                        maxLength={100}
                        disabled={false}
                        errors={var_errors}
                    />

                    <FORM_INPUT
                        property='address'
                        label='Address'
                        value={var_location.address}
                        onChange={onChange_input}
                        placeholder='Address'
                        maxLength={100}
                        disabled={false}
                        errors={var_errors}
                    />

                    <FORM_SELECT
                        property='country'
                        label='Country'
                        value={var_location.country || ''}
                        onChange={onChange_country}
                        placeholder='Select'
                        options={var_countries}
                        disabled={false}
                        errors={var_errors}
                    />

                    <FORM_SELECT
                        property='province'
                        label='Province/State'
                        value={var_location.province || ''}
                        onChange={onChange_region}
                        placeholder='Select'
                        options={var_regions}
                        disabled={var_disableregions}
                        errors={var_errors}
                    />

                    <FORM_INPUT
                        property='city'
                        label='City'
                        value={var_location.city}
                        onChange={onChange_input}
                        placeholder='City'
                        maxLength={100}
                        disabled={false}
                        errors={var_errors}
                    />

                </Form>

                <div className='card__footer'>
                    <Button className='primary' onClick={onClick_next}>Continue to step 2</Button>
                </div>
            </>
        );
    }

};