import React, { useEffect, useState } from 'react';
import API from '../../../libs/api-lib';
import { useNavigate } from 'react-router-dom';
import { Button, Popup } from 'semantic-ui-react';
import { CHECKBOX } from '../../../components/cmp_form/cmp_form';
import Table, { default_populateconfig } from '../../../components/cmp_table/cmp_table';
import Processing from '../../../components/cmp_processing';
import Icon from '../../../components/cmp_icon';

export default function TAB_LOCKEDWALLETS(){

    //  variable declarations ------------------------------------------------------------------------------------------
    const navigate = useNavigate();

    const [ var_locked_wallets, set_locked_wallets ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, sortby: 'name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_statuspopup_id, set_statuspopup_id ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_statuspopup_value, set_statuspopup_value ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_refreshtable(!var_refreshtable);
        populate_locked_wallets({ ...var_populateconfig, offset: 0, filter: {} });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_locked_wallets(populateconfig) {

        set_ready(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;


        try {
            let results = await API_get_locked_vc_wallets(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_locked_wallets(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_locked_vc_wallets(var_populateconfig.maxfilteritems, 0, filtername, sortorder, [], filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_locked_wallets(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_locked_vc_wallets(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    async function onClick_unlock_wallet(item) {
        if (var_statuspopup_value === 'LOCKED') {
            set_statuspopup_id(null);
            return;
        }

        set_processing(true);
        try {
            await API_post_vc_wallet_unlock(item.id);
        } catch(e) {
            console.log(e);
        }

        set_processing(false);
        set_statuspopup_id(null);
        populate_locked_wallets();
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_locked_vc_wallets(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('alpha-org', '/get-locked-vc-wallets/',
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }

    function API_post_vc_wallet_unlock(individual_id) {
        return API.post('alpha-org', '/post-vc-wallet-unlock/' + individual_id, {});
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_individual(organization_id, individual_id) {
        navigate('/organizations/' + organization_id + '/individuals/' + individual_id);
    }

    function onClick_status(id) {
        set_statuspopup_value('LOCKED');
        set_statuspopup_id(id);
    }

    // RENDER APP ======================================================================================================

    return (

        <>
            <div className='card rounded-lg shadow-sm' id='individuals__tab__lockedwallets'>

                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>Locked wallets</div>
                    </div>
                </div>

                <Table loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={1} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname='Locked wallets' downloadfunction={download_locked_wallets}
                        onChange={populate_locked_wallets}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='name' datatype='text' filtertype='text'>NAME</Table.HeaderCell>
                            <Table.HeaderCell field='email' datatype='text' filtertype='text'>EMAIL</Table.HeaderCell>
                            <Table.HeaderCell field='organization' datatype='text' filtertype='text'>ORGANIZATION</Table.HeaderCell>
                            <Table.HeaderCell field='permission' datatype='text' filtertype='option'>PERMISSION</Table.HeaderCell>
                            <Table.HeaderCell field='lock_status' datatype='text' filtertype='option'>WALLET STATUS</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_locked_wallets.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell
                                    className={(item.organization_id && item.organization !== 'Multiple') ? 'text--anchor' : ''}
                                    onClick={() => (item.organization_id && item.organization !== 'Multiple') ? onClick_individual(item.organization_id, item.id) : null}>
                                        {item.name}
                                </Table.Cell>
                                <Table.Cell>{item.email}</Table.Cell>
                                <Table.Cell>{item.organization}</Table.Cell>
                                <Table.Cell>
                                    <div className={((item.organization_id && item.permission !== 'Multiple') ? 'badge ' : '') + (    item.permission === 'Superadmin' ? 'badge--solid_blue' :
                                                                    item.permission === 'Admin' ? 'badge--solid_green' :
                                                                    item.permission === 'Manager' ? 'badge--solid_orange' :
                                                                    item.permission === 'Auditor' ? 'badge--solid_yellow' :
                                                                    item.permission === 'Global Admin' ? 'badge--solid_purple' :
                                                                    item.permission === 'User' && 'badge--solid_gray')}>{item.permission}</div></Table.Cell>
                                <Table.Cell>
                                    {render_status(item)}
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>

                </Table>
                <Processing display={var_processing} />
            </div>
        </>
    )

    function render_status(item) {
        let is_open = item.id === var_statuspopup_id;
        return (
            <Popup
                trigger={
                    <div className='badge text--sm-medium badge--red'>
                        Locked
                        <Icon name='badge_dropdown' className='badge__icon' />
                    </div>
                }
                content={
                    is_open ?
                        <div className='badge__popup shadow-lg rounded-md'>
                            <div className='badge__popup__title text--md-medium'>Locked</div>
                            <div className='badge__popup__option'>
                                <CHECKBOX radio name='updatestatus' label='Unlock wallet' value='UNLOCK' checked={var_statuspopup_value === 'UNLOCK'} onChange={() => set_statuspopup_value('UNLOCK')} />
                            </div>
                            <Button className='badge__popup__button secondary' onClick={(e) => onClick_unlock_wallet(item)}>Update</Button>

                            <Processing display={var_processing} processingtext='' />

                        </div>
                        : null
                }
                on='click'
                open={is_open}
                onOpen={() => onClick_status(item.id)}
                onClose={() => set_statuspopup_id(null)}
                hideOnScroll
                position='bottom left' />
        );
    };

}
