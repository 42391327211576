import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import CHECKBOX from './checkbox';

function FORM_CHECKBOX({ property, description, formlabel, label, checked, onChange, disabled, errors, ...props }) {


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER ==========================================================================================================
    return (
        <div className='detailsgroup'>
            <div className='detailsgroup__label text--sm-medium'>{formlabel}</div>
            <Form.Field className='detailsgroup__value'>
                {description && <div className='detailsgroup__description text--sm-regular'>{description}</div>}
                <CHECKBOX
                    name={property}
                    label={label}
                    onChange={onChange}
                    checked={checked}
                    disabled={disabled}
                    errors={errors}
                    {...props}
                />
            </Form.Field>
        </div>
    );
}


FORM_CHECKBOX.propTypes = {
    description: propTypes.string,
    property: propTypes.string,
    onChange: propTypes.func,
};

export default FORM_CHECKBOX;