import React, { useEffect, useState } from 'react';
import API from '../../../../libs/api-lib';
import { Button } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import Table, { default_populateconfig } from '../../../../components/cmp_table/cmp_table';
import { TOGGLE } from '../../../../components/cmp_form/cmp_form';



export default function CRD_ORGANIZATIONS(){

    //  variable declarations ------------------------------------------------------------------------------------------
    const navigate = useNavigate();

    const [ var_organizations, set_organizations ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_archive, set_archive ] = useState('NO');
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, sortby: 'name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if (var_archive !== undefined) {
            set_refreshtable(!var_refreshtable); // tell the table component to refresh - clear all filter / filter information
            populate_organizations({ ...var_populateconfig, offset: 0, filter: {} }); // repopulate using offset 0 and no filter
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_archive]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_organizations(populateconfig, archive) {
        set_ready(false);
        set_loading(true);
        set_loadingerror(false);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        if (archive) {
            set_archive(archive);
        } else {
            archive = var_archive;
        }

        try {
            let results = await API_get_organizations(limit, offset, sortby, sortorder, archive, filter);
            set_totalrows(results.totalrows);
            set_organizations(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_organizations(var_populateconfig.maxfilteritems, 0, filtername, sortorder, var_archive, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_organizations(limit, offset) {
       try {
           let { sortby, sortorder, filter } = var_populateconfig;
           return (await API_get_organizations(limit, offset, sortby, sortorder, var_archive, filter)).results;
       }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_organizations(limit, offset, sortby, sortorder, archive, filter, filtername) {
        return API.post('alpha-org', '/get-organizations',
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    archive: archive
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_addorganization(){
        navigate('/organizations/add');
    }

    function onClick_organization(var_organizationid){
        navigate('/organizations/'+var_organizationid);
    }

    function onClick_set_archive(state) {
        set_archive(state);
    }



    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd__organizations'>

            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>Organizations</div>
                </div>
                <div className='card__header__right card__header__btns'>
                    <Button className='primary' onClick={() => onClick_addorganization()}>Add organization</Button>
                </div>
            </div>

            <Table loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={1} refresh={var_refreshtable} totalrows={var_totalrows}
                    populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                    downloadname='Organizations' downloadfunction={download_organizations}
                    onChange={populate_organizations}>
                <Table.OptionsButtons>
                    <TOGGLE value={var_archive} onChange={onClick_set_archive} options={[{value: 'NO', text: 'Active'}, {value: 'YES', text: 'Archived'}]} />
                </Table.OptionsButtons>

                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell field='name' datatype='text' filtertype='text'>ORGANIZATION</Table.HeaderCell>
                        <Table.HeaderCell field='city' datatype='text' filtertype='text'>CITY</Table.HeaderCell>
                        <Table.HeaderCell field='credentials' datatype='text' filtertype='option'>CREDENTIALS</Table.HeaderCell>
                        <Table.HeaderCell field='dailycheck' datatype='text' filtertype='option'>DAILY CHECK</Table.HeaderCell>
                        <Table.HeaderCell field='documents' datatype='text' filtertype='option'>DOCUMENTS</Table.HeaderCell>
                        <Table.HeaderCell field='forms' datatype='text' filtertype='option'>FORMS</Table.HeaderCell>
                        <Table.HeaderCell field='issuer' datatype='text' filtertype='option'>ISSUER</Table.HeaderCell>
                        <Table.HeaderCell field='networks' datatype='text' filtertype='option'>NETWORK</Table.HeaderCell>
                        <Table.HeaderCell field='activeusers' datatype='number' filtertype='number'>USERS</Table.HeaderCell>
                        <Table.HeaderCell field='default_language' datatype='text' filtertype='option'>LANGUAGE</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {var_organizations.map(item =>
                        <Table.Row key={item.id} onClick={() => onClick_organization(item.id)}>
                            <Table.Cell>{item.name}</Table.Cell>
                            <Table.Cell>{item.city}</Table.Cell>
                            <Table.Cell>{item.credentials}</Table.Cell>
                            <Table.Cell>{item.dailycheck}</Table.Cell>
                            <Table.Cell>{item.documents}</Table.Cell>
                            <Table.Cell>{item.forms}</Table.Cell>
                            <Table.Cell>{item.issuer}</Table.Cell>
                            <Table.Cell>{item.networks}</Table.Cell>
                            <Table.Cell>{item.activeusers}</Table.Cell>
                            <Table.Cell>{item.default_language}</Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>

            </Table>

            <div className='card__footer--mobilebtns'>
                <Button className='primary' onClick={() => onClick_addorganization()}>Add organization</Button>
            </div>
        </div>
    )

}
