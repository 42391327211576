import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../../../../../../libs/api-lib';
import { Form, Button, Message } from 'semantic-ui-react';
import config from '../../../../../../config';
import form_helper from '../../../../../../libs/form-lib';
import datelib from '../../../../../../libs/date-lib';
import Processing from '../../../../../../components/cmp_processing';
import Icon from '../../../../../../components/cmp_icon';
import { FORM_INPUT, FORM_SELECT, CHECKBOX } from '../../../../../../components/cmp_form/cmp_form';



export default function TAB_SINGLE({ organization_id, assignments }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const navigate = useNavigate();
    const [ var_individual, set_individual ] = useState({ sendemail: 'YES' });
    const [ var_permission_options, set_permission_options ] = useState([]);
    const [ var_location_options, set_location_options ] = useState([]);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_API_error, set_API_error ] = useState('');

    const language_options = Object.keys(config.language).map(key => ({ key, text: config.language[key].name, value: key }));

    //  event listeners ------------------------------------------------------------------------------------------------


    useEffect(() => {
        load_permission_options();
        load_location_options();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  functions ------------------------------------------------------------------------------------------------------


    async function load_permission_options() {
        let permissions = await API_get_permissions();
        set_permission_options(permissions.map(item => ({value: item.id, text: item.permission})));
    }

    async function load_location_options() {
        let locations = await API_get_dd_locations();
        set_location_options(locations.map(item => ({value: item.id, text: item.location_name})));
        if (locations.length > 0) {
            set_individual({...var_individual, location_id: locations[0].id});
        }
    }

    async function invite_individual(individual) {
        set_processing(true);
        set_API_error(null);
        try {
            let result = await API_post_indv_invite(individual);
            if (result === 'DUPLICATE') {
                set_API_error('This individual already exists.');
                set_processing(false);
                return;
            }
            set_processing(false);
            navigate('/organizations/' + organization_id + '/individuals/' + result.individual_id);
        } catch (exception) {
            console.log(exception);
            set_API_error('There was a problem saving.  Please try again later.');
            set_processing(false);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------


    function API_get_permissions() {
        return API.get('alpha-org', '/get-permissions');
    }

    function API_get_dd_locations() {
        return API.get('alpha-org', '/get-dd-locations/' + organization_id, { queryStringParameters: { active: 'YES' } });
    }

    function API_post_indv_invite(individual) {
        individual.organization_id = organization_id;
        individual.tz = datelib.timezone
        return API.post('alpha-org', '/post-indv-invite', {
            body: individual
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event, { name, value }) {
        set_API_error(null);
        let individual = {...var_individual};
        individual[name] = value;
        set_individual(individual);
        if (var_errors.length > 0) {
            set_errors(var_errors.filter(error => error.property !== event.target.name))
        }
    }

    function onChange_select(event, data) {
        set_API_error(null);
        let individual = {...var_individual};
        individual[data.name] = data.value;
        set_individual(individual);
    }

    function onClick_save() {
        if (var_processing) return;

        let individual = {...var_individual};
        let errors = [];
        // validation:
        if (!form_helper.validate_required_string(individual.firstname)) {
            errors.push({property: 'firstname', description: 'Please enter the first name of the individual'});
        }
        if (!form_helper.validate_required_string(individual.lastname)) {
            errors.push({property: 'lastname', description: 'Please enter the last name of the individual'});
        }
        if (!form_helper.validate_required_string(individual.email)) {
            errors.push({property: 'email', description: 'Please enter the email of the individual'});
        } else if (!form_helper.validate_email_address(individual.email, false)) {
            errors.push({property: 'email', description: 'Please enter a valid email address'});
        }
        if (!individual.permission_id) {
            errors.push({property: 'permission_id', description: 'Please select the permission level of the individual'});
        }
        if (!individual.email_language) {
            errors.push({property: 'email_language', description: 'Please select the language for the email.'});
        }
        set_errors(errors);
        if (errors.length > 0) return;

        // cleanse properties
        form_helper.cleanse_string_property(individual, 'firstname');
        form_helper.cleanse_string_property(individual, 'lastname');
        form_helper.cleanse_string_property(individual, 'job_title');
        form_helper.cleanse_string_property(individual, 'email');
        individual.email = individual.email.toLowerCase();
        set_individual(individual);
        invite_individual(individual);
    }

    function onClick_cancel() {
        navigate('/organizations/' + organization_id + '/individuals');
    }



    // RENDER APP ======================================================================================================

    return (

        <div className='card rounded-lg shadow-sm' id='individual_details__tab__singleinvite'>

            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>Add an individual</div>
                    <div className='text--sm-regular'>Add an individual to the organization by providing the information below.</div>
                </div>
            </div>

            <div className='card__content'>
                {var_API_error &&
                    <Message error
                        header={var_API_error}
                        icon={<Icon name='error' className='icon' />}
                    />
                }
                {var_errors.length > 0 &&
                    <Message error
                        icon={<Icon name='error' className='icon' />}
                        header={'This page contained errors'}
                    />
                }
                <Form>
                    <FORM_INPUT
                        property='firstname'
                        label='First name'
                        value={var_individual.firstname}
                        onChange={onChange_input}
                        placeholder='First name'
                        maxLength={100}
                        disabled={false}
                        errors={var_errors}
                    />

                    <FORM_INPUT
                        property='lastname'
                        label='Last name'
                        value={var_individual.lastname}
                        onChange={onChange_input}
                        placeholder='Last name'
                        maxLength={100}
                        disabled={false}
                        errors={var_errors}
                    />

                    <FORM_INPUT
                        property='job_title'
                        label='Title (optional)'
                        value={var_individual.job_title}
                        onChange={onChange_input}
                        placeholder='Job title'
                        maxLength={100}
                        disabled={false}
                        errors={var_errors}
                    />

                    <FORM_INPUT
                        property='email'
                        label='Email'
                        value={var_individual.email}
                        onChange={onChange_input}
                        placeholder='example@email.com'
                        maxLength={100}
                        disabled={false}
                        errors={var_errors}
                    />

                    <div className='detailsgroup' style={{ borderTop: 'none', paddingTop: '0' }}>
                        <div className='detailsgroup__label'></div>
                        <CHECKBOX name='suppress_invite' label='Do not send an invite email to this individual' checked={var_individual.sendemail === 'NO'} onChange={(e) => onChange_input(e, { name:'sendemail', value: var_individual.sendemail === 'YES' ? 'NO' : 'YES' })} />
                    </div>

                    <FORM_SELECT
                        property='email_language'
                        label='Email language'
                        description='Choose the language for the invite email. This selection may be overridden if the individual has already specified a preferred language in their profile.'
                        value={var_individual.email_language}
                        onChange={onChange_input}
                        placeholder='Select'
                        options={language_options}
                        errors={var_errors}
                    />

                    <FORM_SELECT
                        property='permission_id'
                        label='Permission level'
                        value={var_individual['permission_id'] || ''}
                        onChange={onChange_select}
                        placeholder='Select'
                        options={var_permission_options}
                        disabled={false}
                        errors={var_errors}
                    />

                    {assignments === 'YES' &&
                        <FORM_SELECT
                            property='location_id'
                            label='Assignment'
                            value={var_individual['location_id'] || ''}
                            onChange={onChange_select}
                            placeholder='Select'
                            options={var_location_options}
                            disabled={false}
                            errors={var_errors}
                        />
                    }

                </Form>
            </div>

            <div className='card__footer'>
                <Button className='primary' onClick={onClick_save}>Add Individual</Button>
                <Button className='secondary' onClick={onClick_cancel}>Cancel</Button>
            </div>

            <Processing display={var_processing} />
        </div>
    );

};