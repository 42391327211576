import React from 'react';

import CRD_FEATURES from './crd_features/crd_features';
import CRD_CONFIGURATIONS from './crd_configurations/crd_configurations';

import './tab_settings.css';



export default function TAB_SETTINGS({ var_organizationid }) {

    //  variable declarations ------------------------------------------------------------------------------------------



    //  event listeners ------------------------------------------------------------------------------------------------



    //  functions ------------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (

        <>
            <CRD_FEATURES organization_id={var_organizationid} />
            <CRD_CONFIGURATIONS organization_id={var_organizationid} />
        </>
    );

};