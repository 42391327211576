import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import Icon from '../cmp_icon';

import './cmp_copy.css';

function CMP_COPY ({var_text, var_type = 'Icon', var_display_text = 'Copy'}) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_copied, set_copied ] = useState(false);


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_copy(){
        set_copied(true);
        setTimeout(() => {
            set_copied(false);
        }, 3000);

        if (navigator.clipboard) {
            navigator.clipboard.writeText(var_text);
        } else {
            // legacy support for older browsers
            var dummy = document.createElement('textarea');
            document.body.appendChild(dummy);
            dummy.value = var_text;
            dummy.select();
            document.execCommand('copy');
            document.body.removeChild(dummy);
        }
    }

    // RENDER ==========================================================================================================
    return (
        <>
        {var_type === 'Icon' &&
        <div className='icon--sm cmp_copy'>
            <Icon name='copy' className='icon' alt='copy icon' onClick={() => onClick_copy()}/>
        </div>
        }
        {var_type === 'Button' &&
        <div className='cmp_copy'>
            <Button className='tertiary' onClick={() => onClick_copy()}>{var_copied ? 'Copied!' : var_display_text}</Button>
        </div>
        }
        {var_type === 'Anchor' &&
        <div className='cmp_copy anchor'>
            <Button className='tertiary' onClick={() => onClick_copy()}>{var_copied ? 'Copied!' : var_display_text}</Button>
        </div>
        }

        </>
    );
}

export default CMP_COPY;