import React, { useState, useEffect } from 'react';
import API from '../../../../../../libs/api-lib';
import { Button, Form, Message } from 'semantic-ui-react';
import { FORM_DETAILSGROUP, FORM_INPUT, FORM_TOGGLE } from '../../../../../../components/cmp_form/cmp_form';
import Icon from '../../../../../../components/cmp_icon';
import form_helper from '../../../../../../libs/form-lib';
import datelib from '../../../../../../libs/date-lib';


export default function CRD_DETAILS({ location_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_mode, set_mode ] = useState('LOADING');
    const [ var_location, set_location ] = useState({});
    const [ var_working_location, set_working_location ] = useState({});
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_record();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  functions ------------------------------------------------------------------------------------------------------

    async function populate_record() {
        let record = await API_get_org_location();
        set_location(record);
        set_mode('VIEW');
    }

    async function save_record(working_location) {
        set_processing(true);
        try {
            await API_put_org_location(working_location);
            set_location({...var_location, ...working_location});
        } finally {
            set_mode('VIEW');
            set_processing(false);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_location() {
        return API.get('alpha-org', '/get-org-location/' + location_id);
    }

    function API_put_org_location(working_location) {
        return API.put('alpha-org', '/put-org-location', {
            body: working_location
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        let {organization_id, id, default_org, area, businessunit, costobject, startdate, enddate} = var_location;
        let working_location = {organization_id, id, default_org, area, businessunit, costobject, startdate, enddate};
        working_location.startdate_date = working_location.startdate
            ? new Date(datelib.utcmoment_to_local_midnight_date(datelib.epoch_to_utcmoment(working_location.startdate)))
            : null;
        working_location.enddate_date = working_location.enddate
            ? new Date(datelib.utcmoment_to_local_midnight_date(datelib.epoch_to_utcmoment(working_location.enddate)))
            : null;
        set_working_location(working_location);
        set_errors([]);
        set_mode('EDIT');
    }

    function onClick_cancel() {
        set_mode('VIEW');
    }

    function onChange_default_assignment(value) {
        let working_location = {...var_working_location};
        working_location.default_org = value;
        set_working_location(working_location);
    }

    function onChange_input(event) {
        let working_location = {...var_working_location};
        working_location[event.target.name] = event.target.value;
        set_working_location(working_location);
    }

    function onClick_save() {
        if (var_processing) return;

        let location = {...var_working_location};
        // validation: none

        // cleanse properties
        form_helper.cleanse_string_property(location, 'area');
        form_helper.cleanse_string_property(location, 'businessunit');
        form_helper.cleanse_string_property(location, 'costobject');
        location.startdate = location.startdate_date === null ? null : datelib.localdate_to_utc_midnight_epoch(location.startdate_date);
        location.enddate = location.enddate_date === null ? null : datelib.localdate_to_utc_midnight_epoch(location.enddate_date);
        set_working_location(location);
        save_record(location);
    }



    // RENDER APP ======================================================================================================

    return (
        <>

            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>Assignment details</div>
                </div>
                <div className='card__header__right'>
                    {var_mode === 'VIEW' &&
                    <Button className='secondary' onClick={() => onClick_edit()}>Edit</Button>
                    }
                </div>
            </div>

            <div className='card__content'>
                <Form>
                    {
                        var_mode === 'VIEW' ?
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <FORM_DETAILSGROUP label='Default Assignment' value={var_location.default_org} />
                            <FORM_DETAILSGROUP label='Area' value={var_location.area} />
                            <FORM_DETAILSGROUP label='Business unit' value={var_location.businessunit} />
                            <FORM_DETAILSGROUP label='Cost object' value={var_location.costobject} />

                            {/*<FORM_DETAILSGROUP label='Start date' value={var_location.startdate} />*/}
                            {/*<FORM_DETAILSGROUP label='End date' value={var_location.enddate} />*/}
                        </div>
                        : var_mode === 'EDIT' ?
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {var_errors.length > 0 &&
                                <Message error
                                    icon={<Icon name='error' className='icon' />}
                                    header='There are some errors with your inputs'
                                />
                            }

                            <FORM_TOGGLE
                                description='There can only be one default assignment for an organization.  If one already exists this will override it to become the new default assignment'
                                label='Default assignment'
                                value={var_working_location.default_org}
                                options={[{value: 'YES', text: 'Yes'}, {value: 'NO', text: 'No' }]}
                                onChange={onChange_default_assignment}
                            />

                            <FORM_INPUT
                                description="A clear and simple explanation of what a business unit is and how it's used."
                                property='businessunit'
                                label='Business unit (optional)'
                                value={var_working_location.businessunit}
                                onChange={onChange_input}
                                placeholder='Business unit'
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />

                            <FORM_INPUT
                                description="A clear and simple explanation of what an area is and how it's used."
                                property='area'
                                label='Area (optional)'
                                value={var_working_location.area}
                                onChange={onChange_input}
                                placeholder='Area'
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />

                            <FORM_INPUT
                                description="A clear and simple explanation of what a cost object is and how it's used."
                                property='costobject'
                                label='Cost object (optional)'
                                value={var_working_location.costobject}
                                onChange={onChange_input}
                                placeholder='Name'
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />

                            {/*<FORM_DATEPICKER*/}
                            {/*    property='startdate_date'*/}
                            {/*    label='Start date'*/}
                            {/*    value={var_working_location.startdate_date || ''}*/}
                            {/*    onChange={(e, { value }) => onChange_input(e, {name: 'startdate_date', value })}*/}
                            {/*    placeholder='Select a date'*/}
                            {/*    minDate={null}*/}
                            {/*    maxDate={var_working_location.enddate_date}*/}
                            {/*    errors={[]}*/}
                            {/*/>*/}

                            {/*<FORM_DATEPICKER*/}
                            {/*    property='enddate_date'*/}
                            {/*    label='End date'*/}
                            {/*    value={var_working_location.enddate_date || ''}*/}
                            {/*    onChange={(e, { value }) => onChange_input(e, {name: 'enddate_date', value })}*/}
                            {/*    placeholder='Select a date'*/}
                            {/*    minproperty={var_working_location.startdate_date}*/}
                            {/*    maxproperty={null}*/}
                            {/*    errors={[]}*/}
                            {/*/>*/}

                            <div className='card__footer'>
                                <Button className='primary' onClick={onClick_save}>Save</Button>
                                <Button className='secondary' onClick={onClick_cancel}>Cancel</Button>
                            </div>
                        </div>
                        : null
                    }
                </Form>
            </div>
        </>
    );

};