import React, { useEffect, useState } from 'react';
import API from '../../../libs/api-lib';
import { useNavigate } from 'react-router-dom';
import Table, { default_populateconfig } from '../../../components/cmp_table/cmp_table';
import { TOGGLE } from '../../../components/cmp_form/cmp_form';



export default function TAB_INDIVIDUALS(){

    //  variable declarations ------------------------------------------------------------------------------------------
    const navigate = useNavigate();

    const [ var_individuals, set_individuals ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_status, set_status ] = useState('ALL');
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, sortby: 'name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_status !== undefined) {
            set_refreshtable(!var_refreshtable);
            populate_individuals({ ...var_populateconfig, offset: 0, filter: {} });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_status]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_individuals(populateconfig, status){

        if (!populateconfig?.filter?.search_term || populateconfig.filter.search_term.length < 3) {
            set_individuals([]);
            set_totalrows(0);
            set_loading(false);
            set_ready(true);
            return;
        }

        set_ready(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        if (status) {
            set_status(status);
        } else {
            status = var_status
        }

        try {
            let results = await API_get_individuals(limit, offset, sortby, sortorder, status, filter);
            set_totalrows(results.totalrows);
            set_individuals(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_individuals(var_populateconfig.maxfilteritems, 0, filtername, sortorder, var_status, [], filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_individuals(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_individuals(limit, offset, sortby, sortorder, var_status, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_individuals(limit, offset, sortby, sortorder, status, filter, filtername) {
        return API.post('alpha-org', '/get-individuals/',
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    status: status
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_individual(organization_id, individual_id){
        navigate('/organizations/' + organization_id + '/individuals/' + individual_id);
    }

    function onClick_set_status(state) {
        set_status(state);
    }



    // RENDER APP ======================================================================================================

    return (

        <div className='card rounded-lg shadow-sm' id='individuals__tab__individuals'>

            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>Individuals</div>
                    <div className='text--sm-regular'>Search for an individual by entering their name or email address in the search field.</div>
                </div>
            </div>

            <Table loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={1} refresh={var_refreshtable} totalrows={var_totalrows}
                    populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                    downloadname='Individuals' downloadfunction={download_individuals}
                    onChange={populate_individuals}>
                <Table.OptionsButtons>
                    <TOGGLE value={var_status} onChange={onClick_set_status} options={[{value: 'ALL', text: 'All'}, {value: 'ACTIVE', text: 'Active'}, {value: 'INVITED', text: 'Invited'}, {value: 'ARCHIVE', text: 'Inactive'}]} />
                </Table.OptionsButtons>

                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell field='name' datatype='text' filtertype='text'>NAME</Table.HeaderCell>
                        <Table.HeaderCell field='email' datatype='text' filtertype='text'>EMAIL</Table.HeaderCell>
                        <Table.HeaderCell field='organization' datatype='text' filtertype='text'>ORGANIZATION</Table.HeaderCell>
                        <Table.HeaderCell field='permission' datatype='text' filtertype='option'>PERMISSION</Table.HeaderCell>
                        <Table.HeaderCell field='invitestatus' datatype='text' filtertype='option'>STATUS</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {var_individuals.map(item =>
                        <Table.Row key={item.id} onClick={() => onClick_individual(item.organization_id, item.id)}>
                            <Table.Cell>{item.name}</Table.Cell>
                            <Table.Cell>{item.email}</Table.Cell>
                            <Table.Cell>{item.organization}</Table.Cell>
                            <Table.Cell>
                                <div className={'badge text--sm-medium ' + (    item.permission === 'Superadmin' ? 'badge--solid_blue' :
                                                                item.permission === 'Admin' ? 'badge--solid_green' :
                                                                item.permission === 'Manager' ? 'badge--solid_orange' :
                                                                item.permission === 'Auditor' ? 'badge--yellow' :
                                                                item.permission === 'Global Admin' ? 'badge--solid_purple' :
                                                                item.permission === 'User' && 'badge--solid_gray')}>{item.permission}</div></Table.Cell>
                            <Table.Cell>
                                <div className={'badge text--sm-medium ' + (    item.invitestatus === 'Active' ? 'badge--green' :
                                                                item.invitestatus === 'Authenticating' ? 'badge--yellow' :
                                                                item.invitestatus === 'Declined' ? 'badge--red' :
                                                                item.invitestatus === 'Archive' ? 'badge--gray' :
                                                                item.invitestatus === 'Invited' ? 'badge--gray' :
                                                                item.invitestatus === 'Pending Invite' ? 'badge--gray' :
                                                                item.invitestatus === 'Pending' && 'badge--gray')}>{item.invitestatus}</div></Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>

            </Table>
        </div>
    )

}
