import React from 'react';
import propTypes from 'prop-types';

import './cmp_displayrows.css'


function CMP_DISPLAYROWS({ ready, offset, limit, totalrows }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const var_activepage = () => Math.floor(offset / limit) + 1;
    const var_displaystart = () => (var_activepage() - 1) * limit + 1;
    const var_displayend = () => var_activepage() * limit > totalrows ? totalrows : var_activepage() * limit;



    //  variable listeners ---------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <div className='displayrows text--sm-regular'>
            {totalrows > 0 && limit && ready &&
            <>
                {totalrows <= limit ?
                    <div>Showing <div className='text--sm-bold'>{totalrows}</div> {totalrows !== 1 ? 'Results' : 'Result'}</div>
                    :
                    <div>{'Showing'} <div className='text--sm-bold'>{var_displaystart()}</div>
                        {'to'} <div className='text--sm-bold'>{var_displayend()}</div>
                        {'of'} <div className='text--sm-bold'>{totalrows}</div> Results</div>
                }
            </>
            }
        </div>
    );
}

CMP_DISPLAYROWS.propTypes = {
    offset: propTypes.number.isRequired,
    limit: propTypes.number.isRequired,
    ready: propTypes.bool.isRequired,
    totalrows: propTypes.number.isRequired
};

export default CMP_DISPLAYROWS;