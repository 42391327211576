import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TAB_APIS from './crd_api/tab_apis/tab_apis';
import TAB_LOGS from './crd_api/tab_logs/tab_logs';
import TAB_RAWDATA from './crd_api/tab_rawdata/tab_rawdata';
import CMP_BREADCRUMB from '../../components/cmp_breadcrumb/cmp_breadcrumb';



export default function API_MAIN () {

    //  variable declarations ------------------------------------------------------------------------------------------

    const params = useParams()
    //DEFAULTING TO LOGS TAB AS THE OTHERS HAVEN'T BEEN IMPLEMENTED YET
    const [ var_tab, set_tab ] = useState('LOGS');

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (params.tab) {
            set_tab(params.tab.toUpperCase());
        }
    }, [params.tab]);


    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_activetab){
        set_tab(var_activetab);
    }


    // RENDER APP ======================================================================================================

    return(
        <div className='page' id='api_main'>

            <div className='page__header page__header--background3'>
                <CMP_BREADCRUMB
                    icon='api'
                    breadcrumb_elements={[]}
                    current_page_text='API'
                />
                <h1 className='display--sm-regular'>API</h1>

            </div>

            <div className='tab'>
                <div className={'tab__item text--md-bold' + (var_tab === 'APIS' ? ' current' : '')} onClick={()=>onClick_tab('APIS')}>APIs</div>
                <div className={'tab__item text--md-bold' + (var_tab === 'LOGS' ? ' current' : '')} onClick={()=>onClick_tab('LOGS')}>Logs</div>
                <div className={'tab__item text--md-bold' + (var_tab === 'RAWDATA' ? ' current' : '')} onClick={()=>onClick_tab('RAWDATA')}>Raw Data</div>
            </div>

            {var_tab === 'APIS' && <TAB_APIS />}
            {var_tab === 'LOGS' && <TAB_LOGS />}
            {var_tab === 'RAWDATA' && <TAB_RAWDATA />}
        </div>
    )
};
