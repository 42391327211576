import React from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';
import auth from './libs/auth-lib';


import NOTFOUND from './containers/notfound/notfound';
import ORGANIZATION_LIST from './containers/organizations/organizations_list/organizations_list';
import INDIVIDUALS from './containers/individuals/individuals';
import ORGANIZATION_DETAILS from './containers/organizations/organization_details/organization_details';
import ORGANIZATION_ADD from './containers/organizations/organization_add/organization_add';
import LOCATION_DETAILS from './containers/organizations/organization_details/locations/location_details/location_details';
import LOCATION_ADD from './containers/organizations/organization_details/locations/location_add/location_add';
import INDIVIDUAL_DETAILS from './containers/organizations/organization_details/individuals/individual_details/individual_details';
import INDIVIDUAL_ADD from './containers/organizations/organization_details/individuals/individual_add/individual_add';
import API_MAIN from './containers/api/api_main';
import CORPORATE_SCORECARD from './containers/reports/corporate_scorecard/corporate_scorecard';
import SELF_VERIFIED_CREDENTIALS from './containers/reports/self_verified_credentials/self_verified_credentials';
import SETTINGS from './containers/settings/settings';
import PROFILE from './containers/profile/profile';
import TRANSLATIONS from './containers/translations/translations';


export default function Routes({ appProps }) {
    return (
        <RouterRoutes>
            <Route path='/' element={auth.is_loggedin ? <ORGANIZATION_LIST {...appProps} /> : null} />
            {auth.is_loggedin && <Route path='/individuals' element={<INDIVIDUALS {...appProps} />} />}
            {auth.is_loggedin && <Route path='/organizations' element={<ORGANIZATION_LIST {...appProps} />} />}
            {auth.is_loggedin && <Route path='/organizations/add' element={<ORGANIZATION_ADD {...appProps} />} />}
            {auth.is_loggedin && <Route path='/organizations/:organizationid/individuals/add' element={<INDIVIDUAL_ADD {...appProps} />} />}
            {auth.is_loggedin && <Route path='/organizations/:organizationid/individuals/:individualid' element={<INDIVIDUAL_DETAILS {...appProps} />} />}
            {auth.is_loggedin && <Route path='/organizations/:organizationid/locations/add' element={<LOCATION_ADD {...appProps} />} />}
            {auth.is_loggedin && <Route path='/organizations/:organizationid/locations/:locationid' element={<LOCATION_DETAILS {...appProps} />} />}
            {auth.is_loggedin && <Route path='/organizations/:organizationid' element={<ORGANIZATION_DETAILS {...appProps} />} />}
            {auth.is_loggedin && <Route path='/organizations/:organizationid/:tab' element={<ORGANIZATION_DETAILS {...appProps} />} />}
            {auth.is_loggedin && <Route path='/settings' element={<SETTINGS {...appProps} />} />}
            {auth.is_loggedin && <Route path='/profile' element={<PROFILE {...appProps} />} />}
            {auth.is_loggedin && <Route path='/API' element={<API_MAIN {...appProps} />} />}
            {auth.is_loggedin && <Route path='/reports/corporate-scorecard' element={<CORPORATE_SCORECARD {...appProps} />} />}
            {auth.is_loggedin && <Route path='/reports/self-verified-credentials' element={<SELF_VERIFIED_CREDENTIALS {...appProps} />} />}
            {auth.is_loggedin && <Route path='/translations' element={<TRANSLATIONS {...appProps} />} />}
            {/* fallback for not found */}
            <Route path='*' element={<NOTFOUND />} />
        </RouterRoutes>
    );
};
