import React, { useEffect, useState } from 'react';
import API from '../../../../libs/api-lib';
import { Modal, Button } from 'semantic-ui-react';
import { FORM_CHECKBOX, FORM_TEXTAREA } from '../../../../components/cmp_form/cmp_form';
import Icon from '../../../../components/cmp_icon';
import Processing from '../../../../components/cmp_processing';

import './mdl_edit.css';


export default function MDL_EDIT({ display, onChange, onClose, set_message_type, set_message_text, translation }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_errors, set_errors ] = useState([]);
    const [ var_updated_translation, set_updated_translation ] = useState({});
    const [ var_processing, set_processing ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (display) {
            set_updated_translation({ value: translation.value, status: translation.value_status });
        }
    }, [display])

    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_translation() {
        return API.put('translation', `/put-translation/${translation.id}`, {
            body: {
                value: var_updated_translation.value,
                status: var_updated_translation.status === 'Verified' ? 'Verified': 'Pending'
            }
        });
    }

    function API_translate_text(target_lang, text) {
        return API.post('translation', '/post-translate-text', {
            body: {
                target_lang,
                text
            }
        });

    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(e, { name, value }) {
        set_errors([]);
        let updated = {...var_updated_translation};
        updated[name] = value;
        set_updated_translation(updated);
    }

    async function onClick_autotranslate() {
        set_processing(true);
        try {
            let translated_text = await API_translate_text(translation.language_name, translation.key);
            set_updated_translation({ ...var_updated_translation, value: translated_text });
        } catch (exception) {
            console.log(exception);
        }
        set_processing(false);
    }

    async function onClick_save() {
        set_processing(true);
        try {
            await API_post_translation();
            set_message_type('SUCCESS');
            set_message_text('Translation updated');
            onChange(null);
        } catch (exception) {
            console.log(exception);
            set_message_type('ERROR');
            set_message_text('An unexpected error occurred. Please try again.');
        }
        set_processing(false);
    }

    function onChange_approval() {
        set_errors([]);
        let updated = {...var_updated_translation};
        updated.status = var_updated_translation.status === 'Verified' ? 'Pending' : 'Verified';
        set_updated_translation(updated);
    }



    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='translations__crd_editor__mdl_edit'
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
        >

            <div className='modal__header'>

                <Processing display={var_processing} processingtext='' />

                <div className='modal__header__left'>
                    <div className='text--xl-medium'>Translation</div>
                </div>
                <div className='modal__header__right'>
                    <div className='modal__header__closeicon color--primary-500'>
                        <Icon onClick={onClose} name='xicon_nocircle' className='' alt='x icon' />
                    </div>
                </div>
            </div>

            <div className='modal__content'>
                <FORM_TEXTAREA
                    property='key'
                    label='Key'
                    value={translation?.key}
                    onChange={onChange_input}
                    placeholder='Key'
                    maxLength={500}
                    disabled={true}
                    errors={var_errors}
                />

                <FORM_TEXTAREA
                    property='value'
                    label='Translation'
                    value={var_updated_translation.value}
                    onChange={onChange_input}
                    placeholder='Translation'
                    maxLength={500}
                    disabled={false}
                    description='Enter the translation in the field below or click the ‘Auto-translate’ button to use a machine generated translation.'
                    errors={var_errors}
                />

                <div className='detailsgroup'>
                    <div className='detailsgroup__label'/>
                    <div className='detailsgroup__value'>
                        <Button className='secondary' onClick={onClick_autotranslate}>Auto-translate</Button>
                    </div>
                </div>

                <FORM_CHECKBOX
                    name='approval'
                    formlabel='Approval'
                    label='The translation has been reviewed and approved.'
                    onChange={onChange_approval}
                    checked={var_updated_translation.status === 'Verified'}
                    disabled={false}
                    errors={var_errors}
                />
            </div>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_save}>Save</Button>
                    <Button className='secondary' onClick={onClose}>Close</Button>
                </div>
            </div>


        </Modal>
    );

}