import React, { useState, useEffect } from 'react';
import API from '../../../../../libs/api-lib';
import { Button, Form, Message } from 'semantic-ui-react';
import form_helper from '../../../../../libs/form-lib';
import Icon from '../../../../../components/cmp_icon';
import { FORM_DETAILSGROUP, FORM_INPUT } from '../../../../../components/cmp_form/cmp_form';



export default function CRD_CONFIGURATIONS({ organization_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_mode, set_mode ] = useState('LOADING');
    const [ var_configurations, set_configurations ] = useState({});
    const [ var_working_configurations, set_working_configurations ] = useState({});
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_record();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  functions ------------------------------------------------------------------------------------------------------

    async function populate_record() {
        let record = await API_get_organization_configurations();
        set_configurations({id: organization_id, ...record});
        set_mode('VIEW');
    }

    async function save_record(working_configurations) {
        set_processing(true);
        try {
            await API_put_organization_configurations(working_configurations);
            set_configurations({...var_configurations, ...working_configurations});
        } finally {
            set_mode('VIEW');
            set_processing(false);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_organization_configurations() {
        return API.get('alpha-org', '/get-organization-configurations/' + organization_id);
    }

    function API_put_organization_configurations(working_configurations) {
        return API.put('alpha-org', '/put-organization-configurations', {
            body: working_configurations
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        set_working_configurations({...var_configurations});
        set_errors([]);
        set_mode('EDIT');
    }

    function onClick_cancel() {
        set_mode('VIEW');
    }

    function onChange_input(event) {
        let working_configurations = {...var_working_configurations};
        working_configurations[event.target.name] = event.target.value;
        set_working_configurations(working_configurations);
    }

    function onClick_save() {
        if (var_processing) return;

        let working_configurations = {...var_working_configurations};
        let errors = [];
        // validation
        if (!form_helper.validate_required_string(working_configurations.host)) {
            errors.push({property: 'host', description: 'Plase enter the host for the organization'});
        }
        if (!form_helper.validate_required_string(working_configurations.notificationemail)) {
            errors.push({property: 'notificationemail', description: 'Plase enter the notification email address'});
        } else if (!form_helper.validate_email_address(working_configurations.notificationemail, true)) {
            errors.push({property: 'notificationemail', description: 'Please enter a valid notification email address'});
        }
        if (working_configurations.emailcc && !form_helper.validate_email_address(working_configurations.emailcc, true)) {
            errors.push({property: 'emailcc', description: 'Please enter a valid email CC address'});
        }
        if (working_configurations.emailbcc && !form_helper.validate_email_address(working_configurations.emailbcc, true)) {
            errors.push({property: 'emailbcc', description: 'Please enter a valid email BCC address'});
        }
        set_errors(errors);
        if (errors.length > 0) return;

        // cleanse properties
        form_helper.cleanse_string_property(working_configurations, 'host');
        form_helper.cleanse_string_property(working_configurations, 'notificationemail');
        form_helper.cleanse_string_property(working_configurations, 'emailcc');
        form_helper.cleanse_string_property(working_configurations, 'emailbcc');
        set_working_configurations(working_configurations);

        save_record(working_configurations);
    }



    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='organizations_details__crd_configurations'>

            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>Configurations</div>
                </div>
                <div className='card__header__right'>
                    {var_mode === 'VIEW' &&
                    <Button className='secondary' onClick={() => onClick_edit()}>Edit</Button>
                    }
                </div>
            </div>

            <div className='card__content'>
                <Form>
                    {
                        var_mode === 'VIEW' ?
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <FORM_DETAILSGROUP label='Host' value={var_configurations['host']} />
                            <FORM_DETAILSGROUP label='Notification email' value={var_configurations['notificationemail']} />
                            <FORM_DETAILSGROUP label='Email CC' value={var_configurations['emailcc']} />
                            <FORM_DETAILSGROUP label='Email BCC' value={var_configurations['emailbcc']} />
                        </div>
                        : var_mode === 'EDIT' ?
                        <div style={{display: 'flex', flexDirection: 'column'}}>

                            {var_errors.length > 0 &&
                                <Message error
                                    icon={<Icon name='error' className='icon' />}
                                    header='There are some errors with your inputs'
                                />
                            }

                            <FORM_INPUT
                                property='host'
                                label='Host'
                                value={var_working_configurations['host'] || ''}
                                onChange={onChange_input}
                                placeholder='https://example.com'
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />

                            <FORM_INPUT
                                property='notificationemail'
                                label='Notification email'
                                value={var_working_configurations['notificationemail'] || ''}
                                onChange={onChange_input}
                                placeholder='name <email@example.com>'
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />

                            <FORM_INPUT
                                property='emailcc'
                                label='Email CC'
                                value={var_working_configurations['emailcc'] || ''}
                                onChange={onChange_input}
                                placeholder='name <email@example.com>'
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />

                            <FORM_INPUT
                                property='emailbcc'
                                label='Email BCC'
                                value={var_working_configurations['emailbcc'] || ''}
                                onChange={onChange_input}
                                placeholder='name <email@example.com>'
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />

                            <div className='card__footer'>
                                <Button className='primary' onClick={onClick_save}>Save</Button>
                                <Button className='secondary' onClick={onClick_cancel}>Cancel</Button>
                            </div>
                        </div>
                        : null
                    }
                </Form>
            </div>
        </div>
    );

};