import React, { useState, useEffect } from 'react';
import API from '../../../../libs/api-lib';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from '../../../../components/cmp_icon';
import form_helper from '../../../../libs/form-lib';
import CMP_FILEUPLOAD from '../../../../components/cmp_fileupload/cmp_fileupload';
import { FORM_INPUT, FORM_SELECT } from "../../../../components/cmp_form/cmp_form";



export default function CRD_DETAILS({ var_organization, set_organization, status, onClick_nextstep, var_formstatus, set_formstatus }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_errors, set_errors ] = useState([]);
    const [ var_fileupload_status, set_fileupload_status ] = useState(null);

    const [ var_countries ] = useState([{value: null, text: 'Select country'}, {value: 'Canada', text: 'Canada'}, {value: 'United States', text: 'United States'}]);
    const [ var_regions, set_regions ] = useState([]);
    const [ var_disableregions, set_disableregions ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_errors([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);


    useEffect(() => {
        if (var_fileupload_status === 'UPLOAD COMPLETE') {
            set_formstatus('LOGO UPLOAD COMPLETE');
        } else if (var_fileupload_status === 'UPLOAD FAIL') {
            set_formstatus('LOGO UPLOAD FAILED');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_fileupload_status]);

    useEffect(() => {
        if (var_formstatus === 'CHECKING LOGO UPLOAD') {
            switch (var_fileupload_status) {
                case 'UPLOAD READY':
                case 'START UPLOAD':
                case 'UPLOAD FAIL':
                    set_fileupload_status('START UPLOAD');
                    break;
                case 'UPLOAD COMPLETE':
                    set_formstatus('LOGO UPLOAD COMPLETE');
                    break;
                case 'NONE':
                    set_formstatus('NO LOGO UPLOAD');
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_formstatus]);

    useEffect(() => {
        if (var_organization.country === 'Canada') {
            set_regions([{value: null, text: 'Select region'},
                {value: 'Alberta', text: 'Alberta'},
                {value: 'British Columbia', text: 'British Columbia'},
                {value: 'Manitoba', text: 'Manitoba'},
                {value: 'New Brunswick', text: 'New Brunswick'},
                {value: 'Newfoundland and Labrador', text: 'Newfoundland and Labrador'},
                {value: 'Northwest Territories', text: 'Northwest Territories'},
                {value: 'Nova Scotia', text: 'Nova Scotia'},
                {value: 'Nunavut', text: 'Nunavut'},
                {value: 'Ontario', text: 'Ontario'},
                {value: 'Prince Edward Island', text: 'Prince Edward Island'},
                {value: 'Quebec', text: 'Quebec'},
                {value: 'Saskatchewan', text: 'Saskatchewan'},
                {value: 'Yukon', text: 'Yukon'}]);
            set_disableregions(false);
        } else if (var_organization.country === 'United States') {
            set_regions([{value: null, text: 'Select region'},
                {value: 'Alabama', text: 'Alabama'},
                {value: 'Alaska', text: 'Alaska'},
                {value: 'Arizona', text: 'Arizona'},
                {value: 'Arkansas', text: 'Arkansas'},
                {value: 'California', text: 'California'},
                {value: 'Colorado', text: 'Colorado'},
                {value: 'Connecticut', text: 'Connecticut'},
                {value: 'Delaware', text: 'Delaware'},
                {value: 'District of Columbia', text: 'District of Columbia'},
                {value: 'Florida', text: 'Florida'},
                {value: 'Georgia', text: 'Georgia'},
                {value: 'Guam', text: 'Guam'},
                {value: 'Hawaii', text: 'Hawaii'},
                {value: 'Idaho', text: 'Idaho'},
                {value: 'Illinois', text: 'Illinois'},
                {value: 'Indiana', text: 'Indiana'},
                {value: 'Iowa', text: 'Iowa'},
                {value: 'Kansas', text: 'Kansas'},
                {value: 'Kentucky', text: 'Kentucky'},
                {value: 'Louisiana', text: 'Louisiana'},
                {value: 'Maine', text: 'Maine'},
                {value: 'Maryland', text: 'Maryland'},
                {value: 'Massachusetts', text: 'Massachusetts'},
                {value: 'Michigan', text: 'Michigan'},
                {value: 'Minnesota', text: 'Minnesota'},
                {value: 'Mississippi', text: 'Mississippi'},
                {value: 'Missouri', text: 'Missouri'},
                {value: 'Montana', text: 'Montana'},
                {value: 'Nebraska', text: 'Nebraska'},
                {value: 'Nevada', text: 'Nevada'},
                {value: 'New Hampshire', text: 'New Hampshire'},
                {value: 'New Jersey', text: 'New Jersey'},
                {value: 'New Mexico', text: 'New Mexico'},
                {value: 'New York', text: 'New York'},
                {value: 'North Carolina', text: 'North Carolina'},
                {value: 'North Dakota', text: 'North Dakota'},
                {value: 'Ohio', text: 'Ohio'},
                {value: 'Oklahoma', text: 'Oklahoma'},
                {value: 'Oregon', text: 'Oregon'},
                {value: 'Pennsylvania', text: 'Pennsylvania'},
                {value: 'Puerto Rico', text: 'Puerto Rico'},
                {value: 'Rhode Island', text: 'Rhode Island'},
                {value: 'South Carolina', text: 'South Carolina'},
                {value: 'South Dakota', text: 'South Dakota'},
                {value: 'Tennessee', text: 'Tennessee'},
                {value: 'Texas', text: 'Texas'},
                {value: 'Utah', text: 'Utah'},
                {value: 'Vermont', text: 'Vermont'},
                {value: 'Virginia', text: 'Virginia'},
                {value: 'Washington', text: 'Washington'},
                {value: 'West Virginia', text: 'West Virginia'},
                {value: 'Wisconsin', text: 'Wisconsin'},
                {value: 'Wyoming', text: 'Wyoming'}]);
            set_disableregions(false);
        } else {
            set_regions([]);
            set_disableregions(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_organization.country]);



    //  functions ------------------------------------------------------------------------------------------------------

    async function check_orgname_exists() {
        return await API_get_orgname_exists() === 'YES' ? true : false
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_orgname_exists() {
        return API.get('alpha-org', '/get-orgname-exists',
        {
            queryStringParameters: {
                name: var_organization.name
            }
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event) {
        let organization = { ...var_organization };
        organization[event.target.name] = event.target.value;
        set_organization(organization);
        if (var_errors.length > 0) {
            const errors = var_errors.filter(error => error.property !== event.target.name)
            set_errors(errors);
        }
    }

    function onChange_country(e, { value }) {
        let organization = { ...var_organization };
        if (organization.country !== value) {
            organization.province = null;
        }
        organization.country = value;
        set_organization(organization);
    }

    function onChange_region(e, { value }) {
        let organization = { ...var_organization };
        organization.province = value;
        set_organization(organization);
    }

    function onChange_logodetails(details) {
        set_organization({ ...var_organization, logo: details.filename, submittedfilename: details.display_name });
    }

    async function onClick_next() {
        let organization = { ...var_organization };
        let errors = [];
        // validation
        if (!form_helper.validate_required_string(organization.name)) {
            errors.push({property: 'name', description: 'Organization name is required.'});
        } else if (await check_orgname_exists()) {
            errors.push({property: 'name', description: 'An organization with this name already exists.'});
        }
        if (var_fileupload_status === 'UPLOAD INVALID') {
            errors.push({property: 'logo', description: 'Please select a valid logo file'})
        }
        set_errors(errors);
        if (errors.length > 0) return;

        // cleanse properties
        form_helper.cleanse_string_property(organization, 'name');
        form_helper.cleanse_string_property(organization, 'address1');
        form_helper.cleanse_string_property(organization, 'country');
        form_helper.cleanse_string_property(organization, 'province');
        form_helper.cleanse_string_property(organization, 'city');
        form_helper.cleanse_string_property(organization, 'postal');
        form_helper.cleanse_string_property(organization, 'website');
        form_helper.cleanse_string_property(organization, 'linkedin_id');
        set_organization(organization);
        onClick_nextstep();
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            {status === 'COMPLETE' || status === 'COMPLETE-PENDING' ?
                <div className='card__complete'>
                    <div>{var_organization.name}</div>
                    {render_address()}
                </div>
                :
                <div></div>
            }
            <Form id='crd_add_org_details' className='details_form'>
                {status === 'CURRENT' && render_mainform()}
                {render_upload(status === 'CURRENT')}
            </Form>
            {status === 'CURRENT' && render_formbuttons()}
        </>
    );

    function render_address() {
        let lines = [];
        var_organization.address1 && lines.push(var_organization.address1);
        if (var_organization.city) {
            lines.length === 0 ? lines.push(var_organization.city) : lines[lines.length-1] += ', ' + var_organization.city;
        }
        if (var_organization.province) {
            lines.length === 0 ? lines.push(var_organization.province) : lines[lines.length-1] += ', ' + var_organization.province;
        }
        if (var_organization.country) {
            lines.length === 0 ? lines.push(var_organization.country) : lines[lines.length-1] += ', ' + var_organization.country;
        }
        var_organization.postal && lines.push(var_organization.postal);
        return (
            <>
                {lines.map((item, index) => <div key={'address-' + index}>{item}</div>)}
            </>
        );
    }

    function render_mainform() {
        return (
            <>
                {var_errors.length > 0 &&
                    <Message error
                        icon={<Icon name='error' className='icon' />}
                        header='Required fields are empty or contain invalid data.'
                    />
                }

                <FORM_INPUT
                    property='name'
                    label='Name'
                    value={var_organization.name}
                    onChange={onChange_input}
                    placeholder='Organization name'
                    maxLength={100}
                    disabled={false}
                    errors={var_errors}
                />

                <FORM_INPUT
                    property='address1'
                    label='Address'
                    value={var_organization.address1}
                    onChange={onChange_input}
                    placeholder='Address'
                    maxLength={100}
                    disabled={false}
                    errors={var_errors}
                />

                <FORM_SELECT
                    property='country'
                    label='Country'
                    value={var_organization.country || ''}
                    onChange={onChange_country}
                    placeholder='Select'
                    options={var_countries}
                    disabled={false}
                    errors={var_errors}
                />

                <FORM_SELECT
                    property='province'
                    label='Province/State'
                    value={var_organization.province || ''}
                    onChange={onChange_region}
                    placeholder='Select'
                    options={var_regions}
                    disabled={var_disableregions}
                    errors={var_errors}
                />

                <FORM_INPUT
                    property='city'
                    label='City'
                    value={var_organization.city}
                    onChange={onChange_input}
                    placeholder='City'
                    maxLength={100}
                    disabled={false}
                    errors={var_errors}
                />

                <FORM_INPUT
                    property='postal'
                    label='Postal/Zip code'
                    value={var_organization.postal}
                    onChange={onChange_input}
                    placeholder='Postal/Zip code'
                    maxLength={100}
                    disabled={false}
                    errors={var_errors}
                />

                <FORM_INPUT
                    property='website'
                    label='Website (optional)'
                    value={var_organization.website}
                    onChange={onChange_input}
                    placeholder='URL'
                    maxLength={100}
                    disabled={false}
                    errors={var_errors}
                />

                <FORM_INPUT
                    property='linkedin_id'
                    label='LinkedIn ID (optional)'
                    value={var_organization.linkedin_id}
                    onChange={onChange_input}
                    placeholder='LinkedIn ID'
                    maxLength={100}
                    disabled={false}
                    errors={var_errors}
                />

            </>
        );
    }

    function render_upload(display) {
        let error = var_errors.find(item => item.property === 'logo');
        return (
            <div className='detailsgroup' style={display ? null : {display: 'none'}}>
                <div className='detailsgroup__label text--sm-medium'>Logo (optional)</div>
                <Form.Field>
                    <CMP_FILEUPLOAD
                        single_or_multiple={'SINGLE'}
                        var_status={var_fileupload_status}
                        set_status={set_fileupload_status}
                        onChange_details={onChange_logodetails}
                        uploaddirectory='logo' max_size={10} allowed_fileextensions={['png','jpg','jpeg']}
                        instructions='Only JPG and PNG file formats are accepted and should be a minimum 335x50 pixels.'
                        />
                    {error && <Icon name='error' className='error_icon logoerror_icon' />}
                </Form.Field>
            </div>
        );
    }

    function render_formbuttons() {
        return (
            <div className='card__footer'>
                <Button className='primary' onClick={onClick_next}>Continue to step 2</Button>
            </div>
        );
    }
};
