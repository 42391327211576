import React, { useState, useEffect } from 'react';
import API from '../../../libs/api-lib';
import { Button, Message } from 'semantic-ui-react';
import Table, { default_populateconfig } from '../../../components/cmp_table/cmp_table';
import { FORM_SELECT } from '../../../components/cmp_form/cmp_form';
import Icon from '../../../components/cmp_icon';

import MDL_EDIT from './mdl_edit/mdl_edit';
import MDL_ADDKEY from './mdl_addkey/mdl_addkey';
import CMP_CONFIRMATION from '../../../components/cmp_confirmation/cmp_confirmation';

import './crd_editor.css'



export default function CRD_EDITOR({ set_language, var_language }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_translations, set_translations ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, sortby: 'key'});
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_translationgroup, set_translationgroup ] = useState('ALL');
    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);

    const [ var_languages, set_languages ] = useState([]);
    const [ var_languagename, set_languagename ] = useState(null);

    const [ var_selected_translation, set_selected_translation ] = useState(null);
    const [ var_mdl_edit_open, set_mdl_edit_open ] = useState(false);
    const [ var_mdl_addkey_open, set_mdl_addkey_open ] = useState(false);

    const [ var_confirmdelete_open, set_confirmdelete_open ] = useState(false);
    const [ var_translationdelete_id, set_translationdelete_id ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_message_text(null);
        populate_languages();
    }, []);

    useEffect(() => {
        if (var_language) {
            populate_translations({ ...var_populateconfig, offset: 0, filter: {} });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_language, var_translationgroup]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function delete_key(id) {
        try {
            await API_delete_key(id);
            populate_translations();
            set_message_type('SUCCESS');
            set_message_text('Key has been deleted');
        } catch (e) {
            console.log(e);
            set_message_type('ERROR');
            set_message_text('There was a problem saving.  Please try again later');
        }
    }

    async function populate_languages() {
        let results = await API_get_languages();
        set_languages(results.map(item => ({
            value: item.id,
            text: item.display_name
        })));
        set_languagename(results[0] ? results.find(o => o.id === var_language).display_name : '');
        set_loading(false);
        set_ready(true);
    }

    async function populate_translations(populateconfig) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);
        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let { totalrows, results } = await API_get_translations(limit, offset, sortby, sortorder, filter);

            set_totalrows(totalrows);
            set_translations(results || []);
            set_loading(false);
            set_ready(true);
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_translations(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_translations(limit, offset, sortby, sortorder, filter)).results;
        } catch (e) {
            console.log(e);
            throw e;
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_delete_key(id) {
        return API.del('translation', `/delete-key/${id}`);
    }

    function API_get_languages() {
        return API.get('translation', '/get-languages');
    }

    function API_get_translations(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('translation', '/get-translations/' + var_language, {
            queryStringParameters: {
                limit: limit,
                offset: offset,
                sortby: sortby,
                sortorder: sortorder,
                filtername: filtername
            },
            body: filter
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_translationgroup(translationgroup) {
        set_translationgroup(translationgroup);
    }

    function onClick_addKey() {
        set_mdl_addkey_open(true);
    }

    function onChange_select(e, { value }) {
        set_message_type(null);
        set_message_text(null);
        set_language(value);
        set_languagename(var_languages[0] ? var_languages.find(o => o.value === value).text : '');
    }

    function onClick_updatetranslation(translation) {
        set_message_type(null);
        set_message_text(null);
        set_selected_translation(translation);
        set_mdl_edit_open(true);
    }

    function onClick_delete(event, id) {
        event.stopPropagation()
        set_message_type(null);
        set_message_text(null);
        set_confirmdelete_open(true);
        set_translationdelete_id(id);
    }

    function onConfirm_delete() {
        delete_key(var_translationdelete_id);
        set_confirmdelete_open(false);
    }

    function onChange_addkey(updatemessage, messagetype, messagetext) {
        set_mdl_addkey_open(false);
        populate_translations();
        if (updatemessage) {
            set_message_type(messagetype);
            set_message_text(messagetext);
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd__organizations'>

                <div id='select__language'>
                    <FORM_SELECT
                        property='language'
                        label='Language'
                        value={var_language || ''}
                        onChange={onChange_select}
                        placeholder='Select'
                        options={var_languages}
                        disabled={false}
                    />
                </div>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>{var_languagename} translations</div>
                    </div>
                    {var_languagename === 'English' &&
                        <div className='card__header__right card__header__btns'>
                            <Button className='primary' onClick={() => onClick_addKey()}>Add key</Button>
                        </div>
                    }
                </div>

                {var_message_text &&
                    <Message success={var_message_type === 'SUCCESS'} error={var_message_type === 'ERROR'}
                             icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'error'} className='icon' />}
                             header={var_message_text} />
                }

                <Table id='tbl_translations' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={'download'} downloadfunction={download_records}
                        onChange={populate_translations}>
                    {/*<Table.OptionsButtons>*/}
                    {/*    <TOGGLE value={var_translationgroup} onChange={onClick_translationgroup} options={[{value: 'ALL', text: 'All'}, {value: 'PUBLIC', text: 'Public'}, {value: 'ORG-ADMIN', text: 'Org-Admin'}, {value:'OTHER', text: 'Other'}]} />*/}
                    {/*</Table.OptionsButtons>*/}

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='value_status' datatype='text' filtertype='option'>STATUS</Table.HeaderCell>
                            <Table.HeaderCell field='key' datatype='text' filtertype='text'>KEY</Table.HeaderCell>
                            <Table.HeaderCell field='value' datatype='text' filtertype='text'>TRANSLATION</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_translations.map(item =>
                            <Table.Row key={item.id} onClick={() => onClick_updatetranslation(item)}>
                                <Table.Cell>
                                    <div className={'badge text--sm-medium ' +
                                        (   item.value_status === 'Verified' ?
                                                'badge--green'
                                            : item.value_status === 'Pending' ?
                                                'badge--yellow'
                                            : item.value_status === 'Missing' ?
                                                'badge--red'
                                            : item.value_status === 'Unused' ?
                                                'badge--gray'
                                            : 'badge--gray')}>
                                        {item.value_status}
                                    </div>
                                </Table.Cell>
                                <Table.Cell>{item.key}</Table.Cell>
                                <Table.Cell>
                                    <div className='cell__icon--right'>
                                        {item.value}
                                        {item.language_name === 'en' && item.value_status === 'Unused' &&
                                            <Icon name='delete' className='color--primary-500' onClick={(event) => onClick_delete(event, item.translation_key_id)}/>
                                        }
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>

                </Table>


                <div className='card__footer--mobilebtns'>
                    <Button className='primary' onClick={onClick_addKey}>Add key</Button>
                </div>
            </div>

            <MDL_EDIT
                display={var_mdl_edit_open}
                onClose={() => set_mdl_edit_open(false)}
                onChange={() => { set_mdl_edit_open(false); populate_translations(); }}
                set_message_text={set_message_text}
                set_message_type={set_message_type}
                translation={var_selected_translation}
            />

            <MDL_ADDKEY
                display={var_mdl_addkey_open}
                onClose={() => set_mdl_addkey_open(false)}
                onChange={onChange_addkey}
            />

            <CMP_CONFIRMATION
                display={var_confirmdelete_open}
                title='Delete key'
                message={
                    <div style={{flexDirection: 'column'}}>
                        <div style={{marginBottom: '0.5rem'}}>Deleting this key will remove it, and all associated translations, from the system.</div>
                        <div style={{marginBottom: '0.5rem'}}>This action cannot be undone.</div>
                    </div>
                }
                positive_option='Delete'
                negative_option='Cancel'
                onConfirm={onConfirm_delete}
                onCancel={() => set_confirmdelete_open(false)}
            />

        </>
    )

}
