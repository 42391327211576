import React from 'react';
import propTypes from 'prop-types';
import { Form, Message } from 'semantic-ui-react';
import TOGGLE from './toggle';
import Icon from '../../cmp_icon';

function FORM_TOGGLE({ disabled, label, description, instructions, message_type, value, onChange, options }) {


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER ==========================================================================================================
    return (
        <div className='detailsgroup'>
            <div className='detailsgroup__label text--sm-medium'>{label}</div>

            <Form.Field className='detailsgroup__value'>

                {message_type ?
                    <Message success={message_type === 'SUCCESS'} error={message_type === 'ERROR'}
                             warning={message_type === 'WARNING'}
                             icon={<Icon name={message_type === 'SUCCESS' ? 'checkmark' : message_type === 'ERROR' ? 'error' : 'warning'} className='icon'/>}
                             header={description}/>
                    :
                    <>
                        {description && <div className='detailsgroup__description text--sm-regular'>{description}</div>}
                    </>
                }
                <TOGGLE
                    value={value}
                    onChange={onChange}
                    options={options}
                    disabled={disabled}
                />
                {instructions && <div className='detailsgroup__instructions'>{instructions}</div>}
            </Form.Field>
        </div>
    );
}


FORM_TOGGLE.propTypes = {
    disabled: propTypes.bool.isRequired,
    label: propTypes.string.isRequired,
    description: propTypes.string,
    instructions: propTypes.string,
    message_type: propTypes.oneOf(['ERROR', 'SUCCESS', 'WARNING']),
    value: propTypes.oneOfType([propTypes.string, propTypes.bool]),
    onChange: propTypes.func,
    options: propTypes.arrayOf(propTypes.exact({
        value: propTypes.oneOfType([propTypes.string, propTypes.bool]),
        text: propTypes.string
    }))
};

FORM_TOGGLE.defaultProps = {
    disabled: false
};

export default FORM_TOGGLE;