import React, { useEffect, useState } from 'react';
import { Modal, Button, Message } from 'semantic-ui-react';
import API from '../../../../libs/api-lib';
import { SELECT_RADIO } from '../../../../components/cmp_form/cmp_form';
import Icon from '../../../../components/cmp_icon';
import Processing from '../../../../components/cmp_processing';

import './mdl_addlanguage.css';



function MDL_ADDLANGUAGE({ display, onClose, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_languages, set_languages ] = useState([]);
    const [ var_selected_language, set_selected_language ] = useState({});
    const [ var_processing, set_processing ] = useState(false);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_save_error, set_save_error ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (display){
            // reset and populate options
            set_languages([]);
            set_selected_language([]);
            set_processing(false);
            set_errors([]);
            set_save_error(null);
            populate_languages();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_languages() {
        let languages = [
            {name: 'en', display_name: 'English'},
            {name: 'fr', display_name: 'French'},
            {name: 'de', display_name: 'German'},
            {name: 'it', display_name: 'Italian'},
            {name: 'pt', display_name: 'Portuguese'},
            {name: 'es', display_name: 'Spanish'},
        ];

        let results = await API_get_languages();

        let transformed = languages.map(item => {
            let already_added = results.some(language => language.name === item.name);

            return {
                value: item.name,
                primary_text: item.display_name,
                disabled: already_added,
                secondary_text: already_added ? 'Already Added' : null
            };
        });
        set_languages(transformed);
    }

    async function add_language() {
        set_processing(true);
        try {
            await API_post_language();
            onChange();
            onClose();
        } catch (e) {
            console.log(e);
        } finally {
            set_processing(false);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_languages() {
        return API.get('translation', '/get-languages');
    }

    function API_post_language() {
        return API.post('translation', '/post-language', {
            body: {
                name: var_selected_language.value,
                display_name: var_selected_language.primary_text
            }
        });
    }
    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_languages(value) {
        set_save_error(null);
        set_errors([]);
        set_selected_language(var_languages.find(language => language.value === value));
    }

    function onClick_add() {
        set_save_error(null);
        if (var_selected_language.length === 0) {
            set_errors([{ property: 'languages', description: 'Please select at least one language to add' }]);
            return;
        }
        set_errors([]);

        add_language();
    }



    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_addlanguage'
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            className='language__modal'>

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium'>Add Language</div>
                </div>
            </div>

            <div className='modal__content'>
                {var_save_error &&
                    <Message error
                        header={var_save_error}
                    />
                }
                <SELECT_RADIO name='languages' value={var_selected_language.value} options={var_languages} single_or_multiple='SINGLE' onChange={onChange_languages} errors={var_errors} />
            </div>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_add} disabled={var_languages.length === 0}>Save</Button>
                    <Button className='secondary' onClick={onClose}>Cancel</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext='' />

        </Modal>
    );
}

export default MDL_ADDLANGUAGE;