const CONFIG = {
    api_alpha_admin: {
        url: 'https://gamma-qa-cace-apim.azure-api.net/alpha-admin'
    },
    api_alpha_org: {
        url: 'https://gamma-qa-cace-apim.azure-api.net/alpha-org'
    },
    api_gamma_admin: {
        url: 'https://gamma-qa-cace-apim.azure-api.net/gamma-admin'
    },
    api_translation: {
        url: 'https://gamma-qa-cace-apim.azure-api.net/translation'
    },
    iam: {
        url: 'https://auth-qa.credivera.com',
        client_id: 'gamma-ui',
        realm: 'gamma'
    },
    language: {
        en: {
            name: "English",
            i18n: "en-US",
            moment: "en-ca",
            datepicker: "en-US"
        },
        fr: {
            name: "French",
            i18n: "fr-CA",
            moment: "fr-ca",
            datepicker: "fr-FR"
        }
    },
    images: {
        url: "https://imagedelivery.net/GLWlvH-xr3pnmldzciaCLA/",
        stage: "/qa",
        assets: {
            not_found: "ebf6a661-8a04-4082-3a2b-579dc3218000",
            Credivera_Horizontal_Blue: "5639ed9b-db64-40ab-8a93-5eaa1ffbd500",
            Credivera_Horizontal_White: "4296a9d7-7f81-4d33-7e86-d683b5840200",
            Credivera_White_Icon: "ab289ed7-74cc-4a5f-bc9e-aa2231397800",
            Credivera_Blue_PNG: "72052f8a-00da-4eb1-4076-052c5f75ee00",
            mail_sent: "7f8f9dbd-00e3-4c74-eed7-f7cd2c131500",
            pdf_document: "5c5e5923-61c7-4aa7-bca7-52d584eebd00",
        }
    }
};

export default CONFIG;
