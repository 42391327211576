import React, { useEffect, useState } from 'react';
import API from '../../../../libs/api-lib';
import datelib from '../../../../libs/date-lib';
import Moment from 'moment';
import Table, { default_populateconfig } from '../../../../components/cmp_table/cmp_table';
import DATEPICKER from '../../../../components/cmp_form/children/datepicker';

import './crd_self_verified_credentials.css';

export default function CRD_SELF_VERIFIED_CREDENTIALS(){

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_credentials, set_credentials ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, sortby: 'holder' });
    // eslint-disable-next-line no-unused-vars
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_start_date, set_start_date ] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7));
    const [ var_end_date, set_end_date ] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));
    const [ var_errors, set_errors ] = useState([]);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        let errors = [];
        if (var_start_date && var_end_date && Moment(var_end_date).format('YYYY-MM-DD') < Moment(var_start_date).format('YYYY-MM-DD')) {
            errors.push({ property: 'enddate', description: 'To date cannot be before From date' });
        }
        if (!var_start_date) {
            errors.push({ property: 'startdate', description: 'From date is required' });
        }
        if (!var_end_date) {
            errors.push({ property: 'enddate', description: 'To date is required' });
        }
        set_errors(errors);

        if (errors.length === 0) {
            set_refreshtable(!var_refreshtable);
            populate_credentials({ ...var_populateconfig, offset: 0, filter: {} }, var_start_date, var_end_date);
        } else {
            set_totalrows(0);
            set_credentials([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_start_date, var_end_date]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credentials(populateconfig) {

        set_loading(true);
        set_loadingerror(false);
        set_ready(false);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_self_verified_report(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_credentials(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_self_verified_report(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_credentials(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_self_verified_report(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
             console.log(e);
             throw e;
         }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_self_verified_report(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('alpha-admin', '/get-self-verified-report',
            {
                queryStringParameters: {
                    start_date: datelib.date_to_epoch(var_start_date),
                    end_date: datelib.date_to_end_of_day_epoch(var_end_date),
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }


    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd__self-verified-credentials'>
            <Table loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={1} refresh={var_refreshtable} totalrows={var_totalrows}
                    populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                    downloadname='Self-verified credentials' downloadfunction={download_credentials}
                    onChange={populate_credentials}>
                <Table.OptionsButtons>
                        <div className='content_filter'>
                            <div className='datepicker_group'>
                                <label className='label_top'>From</label>
                                <DATEPICKER
                                    className='datepicker_icon'
                                    property='startdate'
                                    label='From'
                                    value={var_start_date}
                                    onChange={(event, data) => set_start_date(data.value)}
                                    placeholder='Select a date'
                                    minproperty={null}
                                    maxproperty={new Date()}
                                    errors={var_errors}
                                />
                            </div>
                            <div className='datepicker_group'>
                                <label className='label_top'>To</label>
                                <DATEPICKER
                                    className='datepicker_icon'
                                    property='enddate'
                                    label='To'
                                    value={var_end_date}
                                    onChange={(event, data) => set_end_date(data.value)}
                                    placeholder='Select a date'
                                    minproperty={null}
                                    maxproperty={new Date()}
                                    errors={var_errors}
                                />
                            </div>
                        </div>
                    </Table.OptionsButtons>

                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell field='holder' datatype='text' filtertype='text'>HOLDER</Table.HeaderCell>
                        <Table.HeaderCell field='organizations' datatype='text' filtertype='text'>ORGANIZATION</Table.HeaderCell>
                        <Table.HeaderCell field='credential_name' datatype='text' filtertype='text'>CREDENTIAL</Table.HeaderCell>
                        <Table.HeaderCell field='issuer' datatype='text' filtertype='text'>ISSUER</Table.HeaderCell>
                        <Table.HeaderCell field='epoch_issued_date' datatype='datetime' filtertype='date'>ISSUED</Table.HeaderCell>
                        <Table.HeaderCell field='epoch_expires_date' datatype='datetime' filtertype='date'>EXPIRES</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {var_credentials.map(item =>
                        <Table.Row key={item.id}>
                            <Table.Cell>{item.holder}</Table.Cell>
                            <Table.Cell>{item.organizations}</Table.Cell>
                            <Table.Cell>{item.credential_name}</Table.Cell>
                            <Table.Cell>{item.issuer}</Table.Cell>
                            <Table.Cell>{datelib.epoch_to_date_string(item.epoch_issued_date)}</Table.Cell>
                            <Table.Cell>{datelib.epoch_to_date_string(item.epoch_expires_date)}</Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>

            </Table>
        </div>
    )

}
