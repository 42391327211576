import React from 'react';
import propTypes from 'prop-types';
import { Input, Form } from 'semantic-ui-react';
import Icon from '../../cmp_icon';

function INPUT({ autoComplete, property, value, onChange, onKeyPress, placeholder, maxLength, disabled, errors }) {

    //  event functions ------------------------------------------------------------------------------------------------
    let error = errors ? errors.find(item => item.property === property) : undefined;



    // RENDER ==========================================================================================================
    return (
        <Form.Field>
            <Input
                autoComplete={autoComplete || null}
                name={property}
                value={value || ''}
                onChange={onChange}
                onKeyPress={onKeyPress}
                placeholder={placeholder || ''}
                maxLength={maxLength}
                disabled={disabled}
                error={error !== undefined}
                icon={error && <Icon name='error' className='icon icon__field__error' />}
            />
            {error &&
                <div className='field__feedback error'>
                    <div className='description text--sm-regular'>{error.description}</div>
                </div>
            }
        </Form.Field>
    );
}

INPUT.propTypes = {
    property: propTypes.string,
    value: propTypes.string,
    onChange: propTypes.func,
    placeholder: propTypes.string,
    maxLength: propTypes.number,
};

export default INPUT;