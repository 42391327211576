import React, { useEffect, useState } from 'react';
import API from '../../../../libs/api-lib';
import { Message, Popup } from 'semantic-ui-react';
import { Chart } from 'react-google-charts';
import Icon from '../../../../components/cmp_icon';
import Processing from '../../../../components/cmp_processing';
import datelib from '../../../../libs/date-lib';

import './crd_corporate_scorecard.css';



export default function CRD_CORPORATESCORECARD() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_individuals, set_individuals ] = useState(null);
    const [ var_issuers, set_issuers ] = useState(null);
    const [ var_credential_transactions, set_credential_transactions ] = useState(null);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_data();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_data() {
        try {
            set_processing(true);
            let results = await API_get_corporate_scorecard();
            set_individuals([['Date', 'Count'], ...results.individuals.map(item => [ datelib.epoch_to_date_string(item.thedate), item.thecount ])]);
            set_issuers([['Date', 'Count'], ...results.issuers.map(item => [ datelib.epoch_to_date_string(item.thedate), item.thecount ])]);
            set_credential_transactions([
                [ 'Date', 'Total', { type: 'string', role: 'tooltip', p: { html: true }},
                    'Added', { type: 'string', role: 'tooltip', p: { html: true }},
                    'Verified', { type: 'string', role: 'tooltip', p: { html: true }},
                    'Shared', { type: 'string', role: 'tooltip', p: { html: true }},
                    'LinkedIn', { type: 'string', role: 'tooltip', p: { html: true }} ],
                ...results.credential_transactions.map(item => transform_credential_transaction(item))
            ]);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
        set_processing(false);
    }

    function transform_credential_transaction(item) {
        let datestring = datelib.epoch_to_date_string(item.thedate);
        let total_count = item.added_count + item.verification_count + item.share_count + item.linkedin_count;
        let tooltip = `<div class='corporatescorecard__tooltip'><div>${datestring}</div>
            <div><div>Total: </div><div>${total_count}</div></div>
            <div><div>Added: </div><div>${item.added_count}</div></div>
            <div><div>Verified: </div><div>${item.verification_count}</div></div>
            <div><div>Shared: </div><div>${item.share_count}</div></div>
            <div><div>LinkedIn: </div><div>${item.linkedin_count}</div></div></div>`;
        return [ datestring, total_count, tooltip, item.added_count, tooltip, item.verification_count, tooltip, item.share_count, tooltip, item.linkedin_count, tooltip ];
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_corporate_scorecard() {
        return API.get('alpha-admin', '/get-corporate-scorecard');
    }



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm corporatescorecard__wrapper'>

            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>Corporate Scorecard</div>
                </div>
            </div>

            {var_loadingerror &&
                <Message error
                    icon={<Icon name='error' className='icon' />}
                    header='An unexpected error occurred. Please try again.'
                />
            }

            {var_individuals !== null &&
                <div className='corporatescorecard__chart'>
                    <div className='corporatescorecard__charttitle'>
                        <div className='text--lg-medium'>Active Individuals</div>
                        <Popup trigger={<Icon name='questionmark_circle' className='corporatescorecard__helpicon' />} on='click' position='bottom center'
                            content='Count of all individuals attached to an ACTIVE organization with an invite status of ACTIVE at the end of the day UTC.' />
                    </div>
                    <Chart chartType='LineChart' width='100%' height='25rem' data={var_individuals} options={{ legend: 'none', vAxis: { format: 'decimal' }, pointSize: 5 }} />
                </div>
            }

            {var_issuers !== null &&
                <div className='corporatescorecard__chart'>
                    <div className='corporatescorecard__charttitle'>
                        <div className='text--lg-medium'>Active Issuers</div>
                        <Popup trigger={<Icon name='questionmark_circle' className='corporatescorecard__helpicon' />} on='click' position='bottom center'
                            content='Count of all ACTIVE organizations with the issuer feature at the end of the day UTC.' />
                    </div>
                    <Chart chartType='LineChart' width='100%' height='25rem' data={var_issuers} options={{ legend: 'none', vAxis: { format: 'decimal' }, pointSize: 5 }} />
                </div>
            }

            {var_credential_transactions !== null &&
                <div className='corporatescorecard__chart'>
                    <div className='corporatescorecard__charttitle'>
                        <div className='text--lg-medium'>Credential Transactions</div>
                        <Popup trigger={<Icon name='questionmark_circle' className='corporatescorecard__helpicon' />} on='click' position='bottom center'
                            content={<div>Rolling 7 day count of credential transactions at the end of the day UTC.<br />Note that if an admin adds a credential to a worker's wallet, it will count as 2 transactions: one for adding and one for verifying</div>} />
                    </div>
                    <Chart chartType='LineChart' width='100%' height='25rem' data={var_credential_transactions} options={{ vAxis: { format: 'decimal' }, pointSize: 5,
                        animation: { startup: true, easing: 'linear', duration: 1500 }, tooltip: { isHtml: true } }} />
                </div>
            }

            <Processing display={var_processing} />
        </div>
    )

}