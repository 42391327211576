import React, { useEffect, useState } from 'react';
import API from '../../../../libs/api-lib';
import DATEPICKER from '../../../../components/cmp_form/children/datepicker';
import datelib from '../../../../libs/date-lib';
import Moment from 'moment';
import Table, { default_populateconfig } from '../../../../components/cmp_table/cmp_table';
import SELECT from '../../../../components/cmp_form/children/select';
import { Modal, Button } from 'semantic-ui-react';

import './tab_logs.css';


async function copy(text) {
    if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
    } else {
        // legacy support for older browsers
        var dummy = document.createElement('textarea');
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
    }
}

export default function TAB_LOGS(){

    //  variable declarations ------------------------------------------------------------------------------------------


    const [ var_logs, set_logs ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_log_start_date, set_log_start_date ] = useState();
    const [ var_log_end_date, set_log_end_date ] = useState();
    const [ var_product_id, set_product_id ] = useState('ALPHA');
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, sortby: 'epochlogdate', sortorder: 'descending' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);

    const [ var_mdl_textviewer_open, set_mdl_textviewer_open ] = useState(false);
    const [ var_textviewer_title, set_textviewer_title ] = useState('');
    const [ var_textviewer_text, set_textviewer_text ] = useState('');
    const [ var_copied, set_copied ] = useState(false);

    const [ var_errors, set_errors ] = useState([]);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (!var_log_start_date) {
            set_log_start_date(new Date());
        }
        if (!var_log_end_date) {
            set_log_end_date(new Date());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!var_mdl_textviewer_open) {
            if (var_copied) set_copied(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_mdl_textviewer_open]);

    useEffect(() => {
        let errors = [];
        if (var_log_start_date && var_log_end_date && Moment(var_log_end_date).format('YYYY-MM-DD') < Moment(var_log_start_date).format('YYYY-MM-DD')) {
            errors.push({property: 'enddate', description: 'To date cannot be before From date'});
        }
        if (!var_log_start_date) {
            errors.push({property: 'startdate', description: 'From date is required'});
        }
        if (!var_log_end_date) {
            errors.push({property: 'enddate', description: 'To date is required'});
        }
        set_errors(errors);

        if (var_log_start_date && var_log_end_date && Moment(var_log_start_date).format('YYYY-MM-DD') <= Moment(var_log_end_date).format('YYYY-MM-DD')) {
            set_refreshtable(!var_refreshtable);
            populate_logs({ ...var_populateconfig, offset: 0, filter: {} }, var_log_start_date, var_log_end_date, var_product_id, errors);
        } else {
            set_totalrows(0);
            set_logs([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_log_start_date, var_log_end_date, var_product_id]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_logs(populateconfig, log_start_date, log_end_date, product_id, errors = var_errors) {
        if (errors.length !== 0) return;

        set_loading(true);
        set_loadingerror(false);
        set_ready(false);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        if (log_start_date) {
            set_log_start_date(log_start_date);
        } else {
            log_start_date = var_log_start_date
        }

        if (log_end_date) {
            set_log_end_date(log_end_date);
        } else {
            log_end_date = var_log_end_date
        }

        if (product_id) {
            set_product_id(product_id);
        } else {
            product_id = var_product_id
        }

        try {
            let results = await API_get_logs(log_start_date, log_end_date, product_id, limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_logs(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_logs(var_log_start_date, var_log_end_date, var_product_id, var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_logs(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_logs(var_log_start_date, var_log_end_date, var_product_id, limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    function onClick_text(title, field) {
        set_textviewer_title(title);
        set_textviewer_text(field);
        set_mdl_textviewer_open(true);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_logs(log_start_date, log_end_date, product_id, limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('gamma-admin', '/get-api-logs/'+ product_id.toLowerCase(),
            {
                queryStringParameters: {
                    log_start_date: datelib.date_to_epoch(Moment(log_start_date).format('YYYY-MM-DD')),
                    log_end_date: datelib.date_to_epoch(Moment(log_end_date).format('YYYY-MM-DD')),
                    tz: datelib.timezone,
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_copy() {
        copy(var_textviewer_text);
        set_copied(true);
        setTimeout(() => {
            set_copied(false);
        }, 3000);
    }

    function onChange_log_start_date(value) {
        set_log_start_date(value);
    }

    function onChange_log_end_date(value) {
        set_log_end_date(value);
    }



    // RENDER APP ======================================================================================================
    const appOptions = [
        {
            key: 'ALPHA',
            text: 'ALPHA',
            value: 'ALPHA'
        },
        {
            key: 'GAMMA',
            text: 'GAMMA',
            value: 'GAMMA'
        }
    ];

    return (
        <>

            <div className='card rounded-lg shadow-sm' id='api__tab__logs'>

                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>Logs</div>
                        <div className='text--sm-regular'>A record of API events and exceptions.</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                    </div>
                </div>

                <Table loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={1} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname='API Logs' downloadfunction={download_logs}
                        onChange={populate_logs}>
                    <Table.OptionsButtons>
                        <div className='content_filter'>
                            <div className='datepicker_group'>
                                <label className='label_top'>From</label>
                                <DATEPICKER
                                    className='datepicker_icon'
                                    property='startdate'
                                    label='From'
                                    value={var_log_start_date}
                                    onChange={(event, data) => onChange_log_start_date(data.value)}
                                    placeholder='Select a date'
                                    minproperty={null}
                                    maxproperty={new Date()}
                                    errors={var_errors}
                                />
                            </div>
                            <div className='datepicker_group'>
                                <label className='label_top'>To</label>
                                <DATEPICKER
                                    className='datepicker_icon'
                                    property='enddate'
                                    label='To'
                                    value={var_log_end_date}
                                    onChange={(event, data) => onChange_log_end_date(data.value)}
                                    placeholder='Select a date'
                                    minproperty={null}
                                    maxproperty={new Date()}
                                    errors={var_errors}
                                />
                            </div>
                            <div className='dropdown_group'>
                                <label className='label_top'>Application</label>
                                <SELECT
                                    property='application'
                                    value={var_product_id}
                                    onChange={(event, data) => set_product_id(data.value)}
                                    placeholder='Application'
                                    options={appOptions}
                                />
                            </div>
                        </div>
                    </Table.OptionsButtons>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='resource' datatype='text' filtertype='text'>NAME</Table.HeaderCell>
                            <Table.HeaderCell field='logtype' datatype='text' filtertype='option'>TYPE</Table.HeaderCell>
                            <Table.HeaderCell field='individual' datatype='text' filtertype='text'>INDIVIDUAL</Table.HeaderCell>
                            <Table.HeaderCell field='epochlogdate' datatype='datetime' filtertype='date'>TIME STAMP</Table.HeaderCell>
                            <Table.HeaderCell>EVENT</Table.HeaderCell>
                            <Table.HeaderCell>EXCEPTION</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_logs.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell>{item.resource}</Table.Cell>
                                <Table.Cell>{item.logtype}</Table.Cell>
                                <Table.Cell>{item.individual}</Table.Cell>
                                <Table.Cell>{Moment.unix(item.epochlogdate).format('lll')}</Table.Cell>
                                <Table.Cell className='text--anchor' onClick={()=>onClick_text('Event', item.event, item.id)}> {item.event ? 'View' : null}</Table.Cell>
                                <Table.Cell className='text--anchor' onClick={()=>onClick_text('Exception', item.exception, item.id)}> {item.exception !== 'null' ? 'View' : null}</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>

                </Table>

            </div>

            {/***** MODAL: TEXT VIEWER *******************************************************************************/}

            <Modal
                dimmer={'inverted'}
                onClose={() => set_mdl_textviewer_open(false)}
                open={var_mdl_textviewer_open}
                closeOnEscape={true}
                closeOnDimmerClick={true}
            >
                <div className="modal__header">
                    <div className='modal__header__left'>
                        <div className='display--xs-medium'>{var_textviewer_title}</div>
                    </div>
                </div>

                <div className='modal__content__container'>
                    <div className='modal__content'>
                        <pre>{var_textviewer_text}</pre>
                    </div>
                </div>

                <div className='modal__footer'>
                    <div className='card__header__left footer__btns'>
                        <Button className='primary' onClick={onClick_copy}>{var_copied ? 'Copied' : 'Copy to clipboard'}</Button>
                        <Button className='secondary' onClick={() => set_mdl_textviewer_open(false)}>Close</Button>
                    </div>
                </div>
            </Modal>

            {/***** END MODAL: TEXT VIEWER ***************************************************************************/}
        </>
    )

}
