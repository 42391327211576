import React from 'react';
import propTypes from 'prop-types';
import { Input, Form } from 'semantic-ui-react';
import Icon from '../../cmp_icon';

import './cmp_search.css';

function CMP_SEARCH ({ search_term, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------


    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER ==========================================================================================================

    return (
        <div className='tablecontrols_search_input' role='search' id='search'>
            <Form.Field>
                <Input
                    type='search'
                    value={search_term}
                    onChange={(e, { value }) => onChange(value)}
                    placeholder={'Search'}
                    icon={search_term.length === 0 ? <Icon name='search' className='icon' alt='' /> : <Icon onClick={() => onChange('')} name='xicon_nocircle' className="icon" />}
                />
            </Form.Field>
        </div>
    );
}

CMP_SEARCH.propTypes = {
    search_term: propTypes.string.isRequired,
    onChange: propTypes.func.isRequired
};

export default CMP_SEARCH;