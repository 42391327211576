import React from 'react';
import propTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import Icon from '../../cmp_icon';

import './cmp_refresh.css';


function CMP_REFRESH ({ loading, onClick, tableid }) {

    //  variable declarations ------------------------------------------------------------------------------------------


    //  variable listeners ---------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_refresh() {
        !loading && onClick && onClick();
    }

    // RENDER ==========================================================================================================

    return (
        <div className='pg_refreshdownload'>
            <Button id={'btn_refresh_' + tableid} className={'secondary pg_refresh' + (loading ? ' pg_loading' : '')} onClick={onClick_refresh} aria-label='Refresh table content'>
                <Icon name='refresh' className='icon__refresh' alt='' />
            </Button>
        </div>
    );
}

CMP_REFRESH.propTypes = {
    loading: propTypes.bool.isRequired,
    onClick: propTypes.func.isRequired
}

export default CMP_REFRESH;