import React, { useState, useEffect } from 'react';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from '../../../../../../components/cmp_icon';
import form_helper from '../../../../../../libs/form-lib';
import datelib from '../../../../../../libs/date-lib';
import { FORM_DATEPICKER, FORM_INPUT, FORM_TOGGLE } from '../../../../../../components/cmp_form/cmp_form';

import './crd_details.css';



export default function CRD_DETAILS({ var_location, set_location, status, onClick_nextstep }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_errors, set_errors ] = useState([]);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_errors([]);
    }, [status]);



    //  functions ------------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_toggle(value) {
        let location = {...var_location};
        location.default_org = value;
        set_location(location);
    }

    function onChange_input(e, { name, value }) {
        let location = {...var_location};
        location[name] = value;
        set_location(location);
    }

    function onClick_next() {
        let location = {...var_location};
        // validation: none

        // cleanse properties
        form_helper.cleanse_string_property(location, 'area');
        form_helper.cleanse_string_property(location, 'businessunit');
        form_helper.cleanse_string_property(location, 'code');
        form_helper.cleanse_string_property(location, 'costobject');
        location.startdate = location.startdate_date === null ? null : datelib.localdate_to_utc_midnight_epoch(location.startdate_date);
        location.enddate = location.enddate_date === null ? null : datelib.localdate_to_utc_midnight_epoch(location.enddate_date);
        set_location(location);
        onClick_nextstep(location);
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            {status === 'COMPLETE' || status === 'COMPLETE-PENDING'
                ?
                <div className='card__complete'>
                    {var_location.area && <div>{var_location.area}</div>}
                    {var_location.businessunit && <div>{var_location.businessunit}</div>}
                    {var_location.costobject && <div>{var_location.costobject}</div>}
                </div>
                :
                <div></div>
            }
            {status === 'CURRENT' && render_form()}
        </>
    );

    function render_form() {
        return (
            <>
                {var_errors.length > 0 &&
                <Message error
                         icon={<Icon name='error' className='icon' />}
                         header={'This step contained errors'}
                />
                }
                <Form>
                    <FORM_TOGGLE
                        description='There can only be one default assignment for an organization. If one already exists this will override it to become the new default assignment.'
                        label='Default assignment'
                        value={var_location.default_org}
                        options={[{value: 'YES', text: 'Yes'}, {value: 'NO', text: 'No' }]}
                        onChange={onChange_toggle}
                    />

                    <FORM_INPUT
                        property='code'
                        label='Code (optional)'
                        value={var_location.code}
                        onChange={onChange_input}
                        placeholder='Code'
                        maxLength={100}
                        disabled={false}
                        errors={var_errors}
                    />

                    <FORM_INPUT
                        property='businessunit'
                        label='Business unit (optional)'
                        value={var_location.businessunit}
                        onChange={onChange_input}
                        placeholder='Business unit'
                        maxLength={100}
                        disabled={false}
                        errors={var_errors}
                    />

                    <FORM_INPUT
                        property='area'
                        label='Area (optional)'
                        value={var_location.area}
                        onChange={onChange_input}
                        placeholder='Area'
                        maxLength={100}
                        disabled={false}
                        errors={var_errors}
                    />

                    <FORM_INPUT
                        property='costobject'
                        label='Cost object (optional)'
                        value={var_location.costobject}
                        onChange={onChange_input}
                        placeholder='Name'
                        maxLength={100}
                        disabled={false}
                        errors={var_errors}
                    />

                    <FORM_DATEPICKER
                        property='startdate_date'
                        label='Start date'
                        value={var_location.startdate_date || ''}
                        onChange={(e, { value }) => onChange_input(e, {name: 'startdate_date', value })}
                        placeholder='Select a date'
                        minproperty={null}
                        maxproperty={var_location.enddate_date}
                    />

                    <FORM_DATEPICKER
                        property='enddate_date'
                        label='End date'
                        value={var_location.enddate_date || ''}
                        onChange={(e, { value }) => onChange_input(e, {name: 'enddate_date', value })}
                        placeholder='Select a date'
                        minproperty={var_location.startdate_date}
                        maxproperty={null}
                    />

                </Form>

                <div className='card__footer'>
                    <Button className='primary' onClick={onClick_next}>Save assignment</Button>
                </div>
            </>
        );
    }

};