import React from 'react';
import propTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import Icon from '../cmp_icon';

import './cmp_confirmation.css';


function CMP_CONFIRMATION({ display, title, message, positive_option, negative_option, onConfirm, onCancel }) {

    //  variable declarations ------------------------------------------------------------------------------------------


    //  variable listeners ---------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
        <Modal dimmer={'inverted'}
            open={display}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            className='cmp_confirmation__wrapper'>
            <div className='cmp_confirmation__icon'>
                <Icon name='warning_circle' />
            </div>
            <div className='cmp_confirmation__text'>
                <div className='text--lg-medium cmp_confirmation__title'>
                    {title}
                </div>
                <div className='text--sm-regular cmp_confirmation__message'>
                    {message}
                </div>
                <div className='modal-footer-buttons'>
                    <Button className='primary' onClick={onConfirm}>{positive_option || 'Yes'}</Button>
                    <Button className='secondary' onClick={onCancel}>{negative_option || 'No'}</Button>
                </div>
            </div>
        </Modal>
    )
}

CMP_CONFIRMATION.propTypes = {
    display: propTypes.bool.isRequired,
    title: propTypes.oneOfType([ propTypes.string, propTypes.element ]),
    message: propTypes.oneOfType([ propTypes.string, propTypes.element ]),
    positive_option: propTypes.string,
    negative_option: propTypes.string,
    onConfirm: propTypes.func.isRequired,
    onCancel: propTypes.func.isRequired
};

CMP_CONFIRMATION.defaultProps = {
    display: false
};

export default CMP_CONFIRMATION;