import React from 'react';
import CMP_BREADCRUMB from '../../../components/cmp_breadcrumb/cmp_breadcrumb';

import CRD_SELF_VERIFIED_CREDENTIALS from './crd_self_verified_credentials/crd_self_verified_credentials';


export default function SELF_VERIFIED_CREDENTIALS() {

    //  variable declarations ------------------------------------------------------------------------------------------



    //  event listeners ------------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(
        <div className='page' id='self_verified_credentials'>

            <div className='page__header page__header--background3'>
                <CMP_BREADCRUMB
                    icon='menu_reports'
                    breadcrumb_elements={[{ path: null, text: 'Reports' }]}
                    current_page_text='Self-verified creds'
                />
                <h1 className='display--sm-regular'>Self-verified creds</h1>

            </div>

            <CRD_SELF_VERIFIED_CREDENTIALS />

        </div>
    )
};
