import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../../../libs/api-lib';
import CMP_BREADCRUMB from '../../../../../components/cmp_breadcrumb/cmp_breadcrumb';
import CRD_NAMEADDRESS from './crd_nameaddress/crd_nameaddress';
import CRD_DETAILS from './crd_details/crd_details';


export default function LOCATION_DETAILS() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const params = useParams();

    const [ var_organization_name, set_organization_name ] = useState('');
    const [ var_location_name, set_location_name ] = useState('');

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        load_organization_name();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  functions ------------------------------------------------------------------------------------------------------

    async function load_organization_name() {
        let organization = await API_get_organization();
        set_organization_name(organization.name);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_organization() {
        return API.get('alpha-org', '/get-organization/' + params.organizationid);
    }

    //  event functions ------------------------------------------------------------------------------------------------


    // RENDER APP ======================================================================================================

    return (
        <div className='page'>

            <div className='page__header page__header--background3'>
                <CMP_BREADCRUMB
                    icon='organization'
                    breadcrumb_elements={[{ path: '/organizations', text: 'Organizations' }, { path: '/organizations/' + params.organizationid, text: var_organization_name }, { path: '/organizations/' + params.organizationid + '/locations', text: 'Assignments' }]}
                    current_page_text={var_location_name}
                />
                <h1 className='display--sm-regular'>{var_location_name ? var_location_name : 'Assignment Details'}</h1>

            </div>

            <div className='card rounded-lg shadow-sm'>
                <CRD_NAMEADDRESS location_id={params.locationid} set_location_name={set_location_name} />
            </div>

            <div className='card rounded-lg shadow-sm'>
                <CRD_DETAILS location_id={params.locationid} />
            </div>
        </div>
    );
};