import React, { useEffect, useState } from 'react';
import API from '../../../../../libs/api-lib';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from '../../../../../components/cmp_icon';
import Logo from '../../../../../components/cmp_logo';
import { SELECT_RADIO } from '../../../../../components/cmp_form/cmp_form';
import Processing from '../../../../../components/cmp_processing';
import CMP_WARNING from '../../../../../components/cmp_warning/cmp_warning';
import CMP_CONFIRMATION from '../../../../../components/cmp_confirmation/cmp_confirmation';
import MDL_UPLOAD_LOGO from './mdl_upload_logo/mdl_upload_logo';

import './crd_logos.css';

export default function CRD_LOGOS({ activetab, organization_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_logos, set_logos ] = useState([]);
    const [ var_default_logo, set_default_logo ] = useState(null);
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_cmp_warning_open, set_cmp_warning_open ] = useState(false);
    const [ var_delete_confirmation_open, set_delete_confirmation_open ] = useState(false);
    const [ var_change_default_confirmation_open, set_change_default_confirmation_open ] = useState(false);
    const [ var_logo_for_deletetion, set_logo_for_deletetion ] = useState(null);
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_mdl_upload_logo_open, set_mdl_upload_logo_open ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (!var_cmp_warning_open && var_focuselement) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_cmp_warning_open]);

    useEffect(() => {
        if (!var_change_default_confirmation_open && var_focuselement) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_change_default_confirmation_open]);

    useEffect(() => {
        if (!var_mdl_upload_logo_open && var_focuselement) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_mdl_upload_logo_open]);

    useEffect(() => {
        if (activetab === 'DETAILS') {
            populate_logos();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_logos(focuselement_id) {
        try {
            let record = await API_get_org_logos();
            set_logos(record);
            set_default_logo(record.find(item => item.is_org_default === 'YES')?.logo_id);
        } catch (e) {
            console.log(e);
        }
        if (focuselement_id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${focuselement_id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#select_radio_logo').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        }
    }

    async function change_default_logo() {
        set_processing(true);
        try {
            set_change_default_confirmation_open(false);
            await API_put_org_default_logo();
            populate_logos();
        } catch (e) {
            console.log(e);
            set_save_error('An error has occurred');
        } finally {
            set_processing(false);
        }
    }

    async function delete_logo() {
        set_processing(true);
        try {
            set_delete_confirmation_open(false);
            await API_delete_org_logo();
            populate_logos(var_focuselement?.id);
        } catch (e) {
            console.log(e);
            set_save_error('An error has occurred');
        } finally {
            set_processing(false);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_logos() {
        return API.get('alpha-org', '/get-organization-logos/' + organization_id);
    }

    function API_put_org_default_logo() {
        return API.put('alpha-org', `/put-org-default-logo/${organization_id}/${var_default_logo}`);
    }

    function API_delete_org_logo() {
        return API.del('alpha-org', `/delete-org-logo/${organization_id}/${var_logo_for_deletetion}`);
    }

    //  event functions ------------------------------------------------------------------------------------------------


    function onChange_default_logo(default_logo_id) {
        set_focuselement(document.activeElement);
        set_default_logo(default_logo_id);
        set_change_default_confirmation_open(true);
    }

    function onClick_delete_logo(logo_details) {
        set_focuselement(document.activeElement);
        if (logo_details.is_org_default === 'YES') {
            set_cmp_warning_open(true);
            return;
        }
        set_logo_for_deletetion(logo_details.logo_id);
        set_delete_confirmation_open(true);
    }

    function onClick_upload_logo() {
        set_focuselement(document.activeElement);
        set_mdl_upload_logo_open(true);
    }


    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_profile_logos' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium' id='hdr_logos'>Organization Logo</div>
                </div>
                <div className='card__header__right card__header__btns'>
                    <Button className='secondary' onClick={onClick_upload_logo}>Upload logo</Button>
                </div>
            </div>
            <Form className='card__content' id='form_profile_orglogos' aria-labelledby='hdr_logos'>

                {var_save_error &&
                    <Message error header={var_save_error} />
                }

                <div className='detailsgroup'>
                    <div className='detailsgroup__subgroup rounded-lg'>
                        <div className='text--sm-medium'>Default logo</div>
                        <div className='detailsgroup__subgroup__description text--sm-regular'>The chosen logo will be displayed in all assignments and credentials issued by your organization, unless otherwise specified.</div>
                        {var_logos.length > 0 &&
                            <SELECT_RADIO
                                name='logos'
                                id='select_radio_logo'
                                value={var_default_logo}
                                options={var_logos.map(item => ({
                                    value: item.logo_id,
                                    disabled: item.scan_status !== 'CLEAN',
                                    primary_text:
                                    <div className='detailsgroup__subgroup__content'>
                                        <div className='detailsgroup__subgroup__content__item'>
                                            <Logo targetid={organization_id} classification='logo' filename={item.logo} credivera_on_missing={false} credivera_on_error={false}
                                                ready={!!organization_id} message_on_virus_scan={true} message_on_infected='Virus detected. Please delete the image.' alt='Organization logo'
                                                api_service='alpha_org' api_name='get-logo-file-information' />

                                            <Button type='button' id={'btn_delete_logo_' + item.logo_id} className='tertiary btn_delete_logo' onClick={() => onClick_delete_logo(item)} disabled={item.scan_status !== 'CLEAN' && item.scan_status !== 'INFECTED'} aria-disabled={item.scan_status !== 'CLEAN' && item.scan_status !== 'INFECTED'}>
                                                <Icon name='delete' className={'icon__delete__table color--primary-500' + (item.scan_status !== 'CLEAN' && item.scan_status !== 'INFECTED' ? ' disabled' : '')} />
                                            </Button>
                                        </div>
                                    </div>
                                }))}
                                single_or_multiple='SINGLE'
                                onChange={onChange_default_logo}
                                aria_label='Select the logo you want to make the default for the organization'
                            />
                        }
                    </div>
                </div>

            </Form>

            <div className='card__footer--mobilebtns'>
                <Button className='secondary' onClick={onClick_upload_logo}>Upload logo</Button>
            </div>

            <CMP_WARNING
                display={var_cmp_warning_open}
                title='Delete image'
                message={
                    <div style={ { flexDirection: 'column' } }>
                        <div style={ { marginBottom: '0.5rem' } }>You cannot delete this image as it is currently designated as the default logo for the organization.</div>
                        <div>To proceed with deletion, please choose a new default logo first.</div>
                    </div>}
                onClose={() => set_cmp_warning_open(false)}
            />

            <CMP_CONFIRMATION
                display={var_delete_confirmation_open}
                title='Delete image'
                message={
                    <div style={ { flexDirection: 'column' } }>
                        <div style={{marginBottom: '0.5rem'}}>
                            Deleting this image will permanently remove it from the system. This will impact any assignments or credentials currently using this image.
                        </div>
                        <div>This action can not be undone.</div>
                    </div>}
                positive_option='Delete'
                negative_option='Cancel'
                onConfirm={delete_logo}
                onCancel={() => set_delete_confirmation_open(false)}
            />

            <CMP_CONFIRMATION
                display={var_change_default_confirmation_open}
                title='Default logo update'
                message='Updating the default logo will replace the logo for any assignments or credentials currently using the previous default logo.'
                positive_option='Update'
                negative_option='Cancel'
                onConfirm={change_default_logo}
                onCancel={() => { set_change_default_confirmation_open(false); set_default_logo(var_logos.find(item => item.is_org_default === 'YES').logo_id) }}
            />

            <MDL_UPLOAD_LOGO
                display={var_mdl_upload_logo_open}
                onClose={() => set_mdl_upload_logo_open(false)}
                onChange={populate_logos}
                organization_id={organization_id}
            />

            <Processing display={var_processing} processingtext='Processing' />

        </div>
    );
}