import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import CMP_FILEUPLOAD from '../../cmp_fileupload/cmp_fileupload';

function FORM_FILEUPLOAD({ property, label, onChange, single_or_multiple, var_fileupload_status, set_fileupload_status, max_size, allowed_fileextensions, instructions,
    errors, description, uploaddirectory, onChange_upload, onProcess }) {


    //  event functions ------------------------------------------------------------------------------------------------

    let error = errors ? errors.find(item => item.property === property) : null;

    // RENDER ==========================================================================================================
    return (
        <div className='detailsgroup'>
            {label && <div className='detailsgroup__label text--sm-medium'>{label}</div>}
            <Form.Field>
                {description && <div className='detailsgroup__description text--sm-regular'>{description}</div>}
                <CMP_FILEUPLOAD
                    var_status={var_fileupload_status}
                    set_status={set_fileupload_status}
                    onChange_details={onChange}
                    onChange_upload={onChange_upload}
                    single_or_multiple={single_or_multiple}
                    uploaddirectory={uploaddirectory} max_size={max_size} allowed_fileextensions={allowed_fileextensions}
                    instructions={instructions}
                    onProcess={onProcess} />
                {error &&
                    <div className='field__feedback error'>
                        <div className='text--sm-regular'>{error.description}</div>
                    </div>
                }
            </Form.Field>
        </div>
    );
}


FORM_FILEUPLOAD.propTypes = {
    description: propTypes.string,
    property: propTypes.string,
    value: propTypes.string,
    onChange: propTypes.func,
    placeholder: propTypes.string,
    maxLength: propTypes.number,
    onProcess: propTypes.func
};

export default FORM_FILEUPLOAD;