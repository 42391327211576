import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Message, Popup} from 'semantic-ui-react';
import API from '../../../libs/api-lib';
import Icon from '../../../components/cmp_icon';
import Table, { default_populateconfig } from '../../../components/cmp_table/cmp_table';
import PROGRESSBAR from '../../../components/cmp_progressbar/cmp_progressbar';
import Processing from '../../../components/cmp_processing';
import MDL_ADDLANGUAGE from './mdl_add/mdl_addlanguage';

import './crd_overview.css';



export default function CRD_OVERVIEW({ set_language, set_tab }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_translations, set_translations ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, sortby: 'display_name'});
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);

    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);

    const [ var_statuspopup_id, set_statuspopup_id ] = useState(null);
    const [ var_statuspopup_value, set_statuspopup_value ] = useState(null);

    const [ var_mdl_add_open, set_mdl_add_open ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_translations({ ...var_populateconfig, offset: 0, filter: {} });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_translations(populateconfig) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        set_message_type(null);
        set_message_text(null);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let { totalrows, results } = await API_get_languages_overview(limit, offset, sortby, sortorder, filter);

            set_totalrows(totalrows);
            set_translations(results || []);
            set_loading(false);
            set_ready(true);
        } catch (e) {
            console.log(e);
            set_loadingerror(true);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_languages_overview(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_languages_overview(limit, offset, sortby, sortorder, filter)).results;
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_languages_overview(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('translation', '/get-languages-overview', {
            queryStringParameters: {
                limit: limit,
                offset: offset,
                sortby: sortby,
                sortorder: sortorder,
                filtername: filtername
            },
            body: filter
        });
    }

    function API_put_language() {
        return API.put('translation', `/put-language/${var_statuspopup_id}`, {
            body: {
                status: var_statuspopup_value
            }
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_addlanguage() {
        set_mdl_add_open(true);
    }

    function onClick_status(id, status) {
        let var_status = status === 'Active' ? 'ACTIVE' : 'INACTIVE';
        set_message_text(null);
        set_statuspopup_value(var_status);
        set_statuspopup_id(id);
    }

    function onChange_statuspopup_value(value) {
        set_statuspopup_value(value);
    }

    async function onClick_updateStatus() {
        if (var_statuspopup_value === null) return;

        set_processing(true);
        try {
            await API_put_language();
            populate_translations(null);
            set_message_type('SUCCESS');
            set_message_text('Language status successfully updated.');
        } catch (exception) {
            console.log(exception);
            set_message_type('ERROR');
            set_message_text('An unexpected error occurred. Please try again.');
        }
        set_processing(false);
        set_statuspopup_id(null);
    }

    function onClick_language(language) {
        set_language(language);
        set_tab('EDITOR');
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd__organizations'>

                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>Languages</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        <Button className='primary' onClick={onClick_addlanguage}>Add language</Button>
                    </div>
                </div>

                {var_message_text &&
                    <Message success={var_message_type === 'SUCCESS'} error={var_message_type === 'ERROR'}
                        icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'error'} className='icon' />}
                        header={var_message_text} />
                }


                <Table loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0}
                    refresh={var_refreshtable} totalrows={var_totalrows}
                    populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                    downloadname={'Languages'} downloadfunction={download_records}
                    onChange={populate_translations}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='display_name' datatype='text' filtertype='text'>NAME</Table.HeaderCell>
                            <Table.HeaderCell field='completion' datatype='number' filtertype='number'>COMPLETE</Table.HeaderCell>
                            <Table.HeaderCell field='entries' datatype='number' filtertype='number'>ENTRIES</Table.HeaderCell>
                            <Table.HeaderCell field='verified' datatype='number' filtertype='number'>VERIFIED</Table.HeaderCell>
                            <Table.HeaderCell field='pending' datatype='number' filtertype='number'>PENDING</Table.HeaderCell>
                            <Table.HeaderCell field='missing' datatype='number' filtertype='number'>MISSING</Table.HeaderCell>
                            <Table.HeaderCell field='unused' datatype='number' filtertype='number'>UNUSED</Table.HeaderCell>
                            <Table.HeaderCell field='status' datatype='text' filtertype='option'>STATUS</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {var_translations.map(item =>
                            <Table.Row key={item.name} >
                                <Table.Cell onClick={() => onClick_language(item.id)}>
                                    <div className='link'>{item.display_name}</div>
                                    {item.name === 'en' &&
                                        <div className="sub-text text--sm-regular">Source Language</div>
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                    <PROGRESSBAR number={item.completion} />
                                </Table.Cell>
                                <Table.Cell>{item.entries}</Table.Cell>
                                <Table.Cell>{item.verified}</Table.Cell>
                                <Table.Cell>{item.pending}</Table.Cell>
                                <Table.Cell>{item.missing}</Table.Cell>
                                <Table.Cell>{item.unused}</Table.Cell>
                                <Table.Cell>{render_status(item)}</Table.Cell>

                            </Table.Row>
                            )}
                    </Table.Body>
                </Table>

                <MDL_ADDLANGUAGE
                    display={var_mdl_add_open}
                    onClose={() => set_mdl_add_open(false)}
                    onChange={populate_translations}
                />

            </div>
        </>
    )

    function render_status(item) {
        if (item.name !== 'en') {
            var is_open = item.id === var_statuspopup_id;
        }
        return (
            <Popup
                trigger={
                    <div className={'badge text--sm-medium ' +
                        (   item.status === 'Active' ?
                            'badge--green'
                            : item.status === 'Inactive' ?
                                'badge--gray'
                                :'badge--gray')}>
                        {item.status}
                        {item.name !== 'en' &&
                            <Icon name='badge_dropdown' className='badge__icon'/>
                        }
                    </div>
                }
                content={
                    is_open ?
                        <div className='badge__popup shadow-lg rounded-md'>
                            <div className='badge__popup__title text--md-medium'>{'Change status'}</div>
                            <div className='badge__popup__option'>
                                <Checkbox radio name='updatestatus' label={'Active'} value='ACTIVE' checked={var_statuspopup_value === 'ACTIVE'} onChange={() => onChange_statuspopup_value('ACTIVE')} />
                            </div>
                            <div className='badge__popup__option'>
                                <Checkbox radio name='updatestatus' label={'Inactive'} value='INACTIVE' checked={var_statuspopup_value === 'INACTIVE'} onChange={() => onChange_statuspopup_value('INACTIVE')} />
                            </div>
                            <Button className='badge__popup__button secondary' onClick={onClick_updateStatus}>{'Update'}</Button>
                            <Processing display={var_processing} processingtext='' />
                        </div>
                    : null
                }
                on='click'
                open={is_open}
                onOpen={() => onClick_status(item.id, item.status)}
                onClose={() => set_statuspopup_id(null)}
                position='bottom left'
            />
        );
    };
};