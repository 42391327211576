import config from '../config';
import { v4 as uuid } from 'uuid';
import axios from 'axios';

const auth = {
    // general features
    authentication: null,
    get_authentication: function() {
            if (!this.authentication) {
                let sessionAuthentication = sessionStorage.getItem('authentication');
                sessionAuthentication && (this.authentication = JSON.parse(sessionAuthentication));
            }
            return this.authentication;
        },
    set_authenication: function (auth) { sessionStorage.setItem('authentication', JSON.stringify(auth)); this.authentication = auth; },
    clear: function() { this.authentication = null; },
    remove: function() { this.clear(); sessionStorage.removeItem('authentication'); },
    get is_logging_in() { if (sessionStorage.getItem('auth_is_logging_in') === 'YES') { sessionStorage.removeItem('auth_is_logging_in'); return true; } return false; },
    get is_loggedin() { return !!this.get_authentication() && this.iam.status === 'LOGGED IN'; },

    // user attributes
    get id() { return get_property(this, 'id'); },
    get individual_type() { return get_property(this, 'individual_type'); },
    get organization_id() { return get_property(this, 'organization_id'); },
    get organization() { return get_property(this, 'organization'); },
    get actingorganization_id() { return get_property(this, 'actingorganization_id'); },
    get firstname() { return get_property(this, 'firstname'); },
    get lastname() { return get_property(this, 'lastname'); },
    get email() { return get_property(this, 'email'); },

    // org components
    get org_credentials() { return get_flag(this, 'org_credentials'); },
    get org_dailycheck() { return get_flag(this, 'org_dailycheck'); },
    get org_documents() { return get_flag(this, 'org_documents'); },
    get org_forms() { return get_flag(this, 'org_forms'); },
    get org_guest() { return get_flag(this, 'org_guest'); },
    get org_host() { return get_flag(this, 'org_host'); },
    get org_issuer() { return get_flag(this, 'org_issuer'); },
    get org_qraccess() { return get_flag(this, 'org_qraccess'); },
    get org_r2w() { return get_flag(this, 'org_r2w'); },
    get org_subcontractor() { return get_flag(this, 'org_subcontractor'); },

    // access
    has_access: function(module, access) { return check_access(this, module, access); },

    // constants
    constants: {
        permission_ids: {
            THSuperadmin: '0635A3DB-9214-4468-8582-4978C2FD3673',
            Superadmin: '8BC291D7-C763-441C-B488-45935BFAD1D2'
        },
        org_ids: {
            ABCRC: 'D95FA1D8-9225-4F46-BF02-6D4DD236A1B6'
        }
    },

    iam: {
        login: async function(onLogin) {
            let keycloak_config;
            let current_url = new URL(window.location.href);
            if (current_url.searchParams.has('code')) {
                // we're being redirected back from keycloak
                if (!!sessionStorage.getItem('keycloak_state') && sessionStorage.getItem('keycloak_state') === current_url.searchParams.get('state')) {
                    sessionStorage.removeItem('keycloak_state');
                    // looks good, now get an access token
                    keycloak_config = JSON.parse(sessionStorage.getItem('keycloak_config'));
                    let token_params = {
                        code: current_url.searchParams.get('code'),
                        grant_type: 'authorization_code',
                        client_id: config.iam.client_id,
                        redirect_uri: iam_redirect_uri
                    };
                    let token_response = await axios.post(
                        keycloak_config.token_endpoint,
                        token_params,
                        { headers: { 'content-type': 'application/x-www-form-urlencoded' }});
                    if (this.persist_token(token_response.data)) {
                        onLogin && onLogin();
                    }
                    return;
                } else {
                    console.error('Saved state ' + sessionStorage.getItem('keycloak_state') + ' does not match returned state ' + current_url.searchParams.get('state'));
                }
            }
            // get configuration from iam server
            keycloak_config = (await axios.get(`${config.iam.url}/realms/${config.iam.realm}/.well-known/uma2-configuration`)).data;
            sessionStorage.setItem('keycloak_config', JSON.stringify(keycloak_config));
            // create some variables
            let state = uuid();
            let nonce = uuid();
            sessionStorage.setItem('keycloak_state', state);
            // generate URL
            let url = new URL(keycloak_config.authorization_endpoint);
            url.searchParams.append('client_id', config.iam.client_id);
            url.searchParams.append('redirect_uri', iam_redirect_uri);
            url.searchParams.append('state', state);
            url.searchParams.append('response_type', 'code');
            url.searchParams.append('scope', 'openid');
            url.searchParams.append('nonce', nonce);
            window.location.assign(url);
        },
        logout: async function() {
            if (!localStorage.getItem('iam_access_token')) return;
            try {
                // log out of keycloak
                let keycloak_config = JSON.parse(sessionStorage.getItem('keycloak_config'));
                await axios.post(keycloak_config.end_session_endpoint, {
                    client_id: config.iam.client_id,
                    refresh_token: localStorage.getItem('iam_refresh_token')
                }, {
                    headers: { 'content-type': 'application/x-www-form-urlencoded' }
                });
            } catch {}
            // clear iam storage items
            localStorage.removeItem('iam_access_token');
            localStorage.removeItem('iam_expires_epoch');
            localStorage.removeItem('iam_refresh_expires_epoch');
            localStorage.removeItem('iam_refresh_token');
            localStorage.removeItem('iam_scope');
        },
        change_password: function() {
            let keycloak_config = JSON.parse(sessionStorage.getItem('keycloak_config'));
            let url = new URL(keycloak_config.authorization_endpoint);
            url.searchParams.append('client_id', config.iam.client_id);
            url.searchParams.append('redirect_uri', iam_redirect_uri + '/profile');
            url.searchParams.append('response_type', 'code');
            url.searchParams.append('scope', 'openid');
            url.searchParams.append('kc_action', 'UPDATE_PASSWORD');
            window.location.assign(url);

        },
        get status() {
            if (!localStorage.getItem('iam_access_token')) return 'NOT LOGGED IN';
            if (Number(localStorage.getItem('iam_refresh_expires_epoch')) < Date.now()) return 'EXPIRED';
            return 'LOGGED IN';
        },
        refresh_token: async function() {
            if (!localStorage.getItem('iam_refresh_token')) return;
            if (Number(localStorage.getItem('iam_refresh_expires_epoch')) < Date.now()) {
                // if refresh token is expired
                return;
            }
            let keycloak_config = JSON.parse(sessionStorage.getItem('keycloak_config'));
            let token_response = await axios.post(keycloak_config.token_endpoint, {
                client_id: config.iam.client_id,
                grant_type: 'refresh_token',
                refresh_token: localStorage.getItem('iam_refresh_token')
            }, {
                headers: { 'content-type': 'application/x-www-form-urlencoded' }
            });
            this.persist_token(token_response.data);
        },
        get_access_token: async function() {
            let iam_access_token = localStorage.getItem('iam_access_token'), iam_expires_epoch = localStorage.getItem('iam_expires_epoch'),
                iam_refresh_expires_epoch = localStorage.getItem('iam_refresh_expires_epoch');
            if (!iam_access_token) {
                // if not logged in
                return null;
            }
            if (iam_refresh_expires_epoch < Date.now()) {
                // if refresh token has expired
                return null;
            }
            if (iam_expires_epoch < Date.now() || iam_refresh_expires_epoch < (Date.now() + (5*60*1000))) {
                // if the access token is expired or the refresh token is about to expire
                // then get a new token
                await this.refresh_token();
                iam_access_token = localStorage.getItem('iam_access_token');
            }
            return iam_access_token;
        },
        persist_token: function(token) {
            if (!token?.access_token) return false;
            localStorage.setItem('iam_access_token', token.access_token);
            localStorage.setItem('iam_expires_epoch', Date.now() + (token.expires_in * 1000) - 20000); // save token expiry as 20 seconds less to give time to refresh
            localStorage.setItem('iam_refresh_expires_epoch', Date.now() + (token.refresh_expires_in * 1000) - 20000);
            localStorage.setItem('iam_refresh_token', token.refresh_token);
            localStorage.setItem('iam_scope', token.scope);
            return true;
        }
    }
};


//#region internal functions

const get_property = (auth, property_name) => {
    let authentication = auth.get_authentication();
    return authentication && authentication[property_name];
}

const get_flag = (auth, flag_name) => {
    let authentication = auth.get_authentication();
    return authentication ? authentication[flag_name] === 'YES' : false;
}

const check_access = (auth, module, module_access) => {
    let authentication = auth.get_authentication();
    if (!authentication) return false;
    if (authentication.permission_id === auth.constants.permission_ids.THSuperadmin) return true;
    return authentication.access.some(item => item.module === module && JSON.parse(item.module_access).includes(module_access));
}

const iam_redirect_uri = window.location.protocol + '//' + window.location.host;

//#endregion

export default auth;