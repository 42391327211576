import React, { useState, useEffect, useCallback } from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { Form, Input, Checkbox } from 'semantic-ui-react';
import Icon from '../../cmp_icon';

import '../cmp_form.css';



function SELECT_RADIO({ name, value, options, single_or_multiple, disabled, onChange, errors, id }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_search_value, set_search_value ] = useState('');
    const [ var_throttled_search_value, set_throttled_search_value ] = useState('');
    const [ var_search_results, set_search_results ] = useState([]);
    const MIN_OPTIONS_FOR_SEARCH = 10;

    let error = errors ? errors.find(item => item.property === name) : null;

    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        // reset
        set_search_value('');
        set_throttled_search_value('');
        set_search_results(options || []);
    }, [options]);

    useEffect(() => {
        if (var_throttled_search_value === '') {
            set_search_results(options || []);
        } else {
            const reg_exp = new RegExp(_.escapeRegExp(var_throttled_search_value), 'i');
            const is_match = (result) => reg_exp.test(result.primary_text) || reg_exp.test(result.secondary_text);
            set_search_results((options || []).filter(item => is_match(item)));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_throttled_search_value]);


    //  event functions ------------------------------------------------------------------------------------------------

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const throttle_set_search_value = useCallback(_.throttle(value => set_throttled_search_value(value), 1000), []);

    function onChange_search(e) {
        set_search_value(e.target.value);
        throttle_set_search_value(e.target.value);
    }

    function onChange_checkbox(e, data) {
        let new_value;
        if (single_or_multiple === 'SINGLE') {
            new_value = data.value;
        } else if (data.checked) {
            new_value = [...value];
            new_value.push(data.value);
        } else {
            new_value = value.filter(item => item !== data.value);
        }
        onChange && onChange(new_value);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
        <div id={id} className='select_radio__container'>
            {single_or_multiple === 'SINGLE' ? render_single() : render_multiple()}
            {render_disabled(disabled)}
        </div>
        {error &&
            <div className='field__feedback error'>
                <div className='text--sm-regular'>{error.description}</div>
            </div>
        }
        </>
    );

    function render_single() {
        return (
            <>
                {options.length > MIN_OPTIONS_FOR_SEARCH && render_search_input()}
                <div className='select_radio__results'>
                    {var_search_results.map((item, index) =>
                        <div className='select_radio__item' key={'select_radio__itemkey_' + item.value}>
                            <Checkbox
                                radio
                                name={'select_radio__checkbox_' + name}
                                value={item.value}
                                checked={item.value === value}
                                onChange={onChange_checkbox}
                                disabled={item.disabled}
                                />
                            <div className='select_radio__item__twoline'>
                                <div className='text--sm-regular'>
                                    {item.primary_text}
                                </div>
                                <div className={(item.secondary_text_css ? item.secondary_text_css : 'text--xs-regular')}>
                                    {item.secondary_text}
                                </div>
                            </div>
                            {render_disabled(item.disabled)}
                        </div>
                    )}
                </div>
            </>
        );
    }

    function render_multiple() {
        return (
            <>
                {options.length > MIN_OPTIONS_FOR_SEARCH && render_search_input()}
                <div className='select_radio__results'>
                    {var_search_results.map((item, index) =>
                        <div className='select_radio__item' key={'select_radio__itemkey_' + item.value}>
                            <Checkbox
                                value={item.value}
                                checked={value.includes(item.value)}
                                onChange={onChange_checkbox}
                                disabled={item.disabled}
                                />
                            <div className='select_radio__item__twoline'>
                                <div className='text--sm-regular'>
                                    {item.primary_text}
                                </div>
                                <div className={(item.secondary_text_css ? item.secondary_text_css : 'text--xs-regular')}>
                                    {item.secondary_text}
                                </div>
                            </div>
                            {render_disabled(item.disabled)}
                        </div>
                    )}
                </div>
            </>
        );
    }

    function render_search_input() {
        return (
            <Form.Field>
                <Input
                    value={var_search_value}
                    onChange={onChange_search}
                    placeholder='Search'
                    icon={<Icon name='search' className='icon icon__search' />}
                    className='select_radio__search'
                />
            </Form.Field>
        );
    }

    function render_disabled(disabled) {
        if (!disabled) return null;
        return (
            <div className='select_radio__disabled' />
        );
    }
}

SELECT_RADIO.propTypes = {
    name: propTypes.string.isRequired,
    value: propTypes.oneOfType([propTypes.string, propTypes.array]),
    options: propTypes.arrayOf(propTypes.shape({
        value: propTypes.string.isRequired,
        primary_text: propTypes.oneOfType([ propTypes.string, propTypes.element ]).isRequired,
        secondary_text: propTypes.string,
        secondary_text_css : propTypes.string,
        disabled: propTypes.bool
    })),
    single_or_multiple: propTypes.oneOf(['SINGLE', 'MULTIPLE']).isRequired,
    disabled: propTypes.bool.isRequired,
    onChange: propTypes.func
};

SELECT_RADIO.defaultProps = {
    single_or_multiple: 'SINGLE',
    disabled: false
};

export default SELECT_RADIO;