import React from 'react';
import propTypes from 'prop-types';
import Icon from '../../cmp_icon';
import { Button } from 'semantic-ui-react';



function CMP_CELLCONTENT_TWOLINE({ primary_text, secondary_text, display_chevron }) {

    //  variable declarations ------------------------------------------------------------------------------------------



    //  variable listeners ---------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return display_chevron ? (
            <>
                <div className='cell__text--2rows'>
                    <div className='text--sm-medium'>
                        {primary_text}
                    </div>
                    <div className='text--sm-regular'>
                        {secondary_text}
                    </div>
                </div>
                <Button className='tertiary' >
                    <Icon name='chevron_right' className='cell__icon__chevron cell__icon__chevron--right color--primary-500' alt='' />
                </Button>
            </>
        ) : (
            <div className='cell__text--2rows'>
                <div className='text--sm-medium'>
                    {primary_text}
                </div>
                <div className='text--xs-regular'>
                    {secondary_text}
                </div>
            </div>
        );
}

CMP_CELLCONTENT_TWOLINE.propTypes = {
    primary_text: propTypes.oneOfType([propTypes.string, propTypes.element]),
    secondary_text: propTypes.oneOfType([propTypes.string, propTypes.element]),
    display_chevron: propTypes.bool.isRequired
};

CMP_CELLCONTENT_TWOLINE.defaultProps = {
    display_chevron: false
};

export default CMP_CELLCONTENT_TWOLINE;