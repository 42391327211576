import React from 'react';
import CMP_BREADCRUMB from '../../../components/cmp_breadcrumb/cmp_breadcrumb';

import CRD_CORPORATESCORECARD from './crd_corporate_scorecard/crd_corporate_scorecard';



export default function CORPORATE_SCORECARD() {

    //  variable declarations ------------------------------------------------------------------------------------------



    //  event listeners ------------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(
        <div className='page' id='corporate_scorecard'>

            <div className='page__header page__header--background3'>
                <CMP_BREADCRUMB
                    icon='menu_reports'
                    breadcrumb_elements={[{ path: null, text: 'Reports' }]}
                    current_page_text='Corporate scorecard'
                />
                <h1 className='display--sm-regular'>Corporate scorecard</h1>

            </div>

            <CRD_CORPORATESCORECARD />

        </div>
    )
};
