import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import DATEPICKER from './datepicker';

function FORM_DATEPICKER({ property, label, value, onChange, onOpen, onClose, placeholder, minproperty, maxproperty, disabled, errors, description }) {


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER ==========================================================================================================
    return (
        <div className='detailsgroup'>
            <div className='detailsgroup__label text--sm-medium'>{label}</div>
            <Form.Field className='detailsgroup__value'>
                {description && <div className='detailsgroup__description text--sm-regular'>{description}</div>}
                <DATEPICKER
                    property={property}
                    value={value}
                    onChange={onChange}
                    onOpen={onOpen}
                    onClose={onClose}
                    placeholder={placeholder}
                    minproperty={minproperty}
                    maxproperty={maxproperty}
                    disabled={disabled}
                    errors={errors}
                />
            </Form.Field>
        </div>
    );
}


FORM_DATEPICKER.propTypes = {
    description: propTypes.string,
    property: propTypes.string,
    value: propTypes.oneOfType([propTypes.string, propTypes.instanceOf(Date)]),
    onChange: propTypes.func,
    onOpen: propTypes.func,
    onClose: propTypes.func,
    placeholder: propTypes.string
};

export default FORM_DATEPICKER;