import React from 'react';
import propTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';

function TOGGLE({ disabled, value, onChange, options }) {

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_button(button_value) {
        button_value !== value && onChange && onChange(button_value);
    }


    // RENDER ==========================================================================================================

    return (
        <Button.Group>
            {options.map(item =>
                <Button disabled={disabled} key={uuidv4()} className={value === item.value ? 'active' : ''} onClick={() => onClick_button(item.value)}>{item.text}</Button>
            )}
        </Button.Group>
    );
}

TOGGLE.propTypes = {
    disabled: propTypes.bool.isRequired,
    value: propTypes.oneOfType([propTypes.string, propTypes.bool]),
    onChange: propTypes.func,
    options: propTypes.arrayOf(propTypes.exact({
        value: propTypes.oneOfType([propTypes.string, propTypes.bool]),
        text: propTypes.string
    })).isRequired
};

TOGGLE.defaultProps = {
    disabled: false
};

export default TOGGLE;