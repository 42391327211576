import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../../../../../libs/api-lib';
import { Button, Message } from 'semantic-ui-react';
import Icon from '../../../../../components/cmp_icon';
import CMP_BREADCRUMB from '../../../../../components/cmp_breadcrumb/cmp_breadcrumb';
import Processing from '../../../../../components/cmp_processing';

import CRD_NAMEADDRESS from './crd_nameaddress/crd_nameaddress';
import CRD_DETAILS from './crd_details/crd_details';


export default function LOCATION_ADD() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const params = useParams();
    const navigate = useNavigate();

    const [ var_organization_id, set_organization_id ] = useState(null);
    const [ var_organization_name, set_organization_name ] = useState('');
    const [ var_location, set_location ] = useState({default_org: 'NO'});
    const [ var_stepstatus, set_stepstatus ] = useState([
        { step: 1, status: 'CURRENT' },
        { step: 2, status: 'INCOMPLETE' }
    ]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_save_error, set_save_error ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------


    useEffect(() => {
        set_organization_id(params.organizationid);
        load_organization_name(params.organizationid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  functions ------------------------------------------------------------------------------------------------------

    async function load_organization_name(organization_id) {
        let organization = await API_get_organization(organization_id);
        set_organization_name(organization.name);
    }

    async function save_location(location) {
        set_processing(true);
        set_save_error(false);
        try {
            let new_location_id = await API_post_location(location);
            set_processing(false);
            navigate('/organizations/' + var_organization_id + '/locations/' + new_location_id);
        } catch (exception) {
            console.log(exception);
            set_save_error(true);
            set_processing(false);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_organization(organization_id) {
        return API.get('alpha-org', '/get-organization/' + organization_id);
    }

    function API_post_location(location) {
        location.organization_id = var_organization_id;
        return API.post('alpha-org', '/post-org-location', {
            body: location
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_nextstep(location) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        let stepstatus = var_stepstatus.map(item => ({...item}));
        let currentstep = stepstatus.find(item => item.status === 'CURRENT');
        if (currentstep.step === 2) {
            save_location(location);
        } else {
            currentstep.status = 'COMPLETE';
            stepstatus.find(item => item.step === currentstep.step + 1).status = 'CURRENT';
            set_stepstatus(stepstatus);
        }
    }

    function onClick_editstep(step) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        let stepstatus = var_stepstatus.map(item => ({...item}));
        stepstatus.find(item => item.status === 'CURRENT').status = 'INCOMPLETE';
        stepstatus.find(item => item.step === step).status = 'CURRENT';
        stepstatus.filter(item => item.step > step && item.status === 'COMPLETE').forEach(item => item.status = 'COMPLETE-PENDING');
        set_stepstatus(stepstatus);
    }



    // RENDER APP ======================================================================================================

    return (
        <div className='page'>
            <div className='page__header page__header--background3'>
                <CMP_BREADCRUMB
                    icon='organization'
                    breadcrumb_elements={[{ path: '/organizations', text: 'Organizations' }, { path: '/organizations/' + var_organization_id, text: var_organization_name }, { path: '/organizations/' + var_organization_id + '/locations', text: 'Assignments' }]}
                    current_page_text='Add Assignment'
                />
                <h1 className='display--sm-regular'>Add Assignment</h1>

            </div>

            {var_save_error &&
                <Message error
                    header='There was a problem saving.  Please try again later'
                />
            }
            {render_card(1, 'Assignment name & address', 'Set the Name and Address for the assignment.', CRD_NAMEADDRESS)}
            {render_card(2, 'Assignment details', 'Set additional details such as Start and End date for the assignment.', CRD_DETAILS)}
        </div>
    );

    function render_card(step, title, description, Card, other_attributes = {}) {
        let stepstatus = var_stepstatus.find(item => item.step === step);
        return (
            <div className='card rounded-lg shadow-sm'>
                <div className={'card__header ' + (stepstatus.status !== 'CURRENT' ? 'card__header__inactive' : '')}>
                    <div className='card__header__icon'>
                        {stepstatus.status === 'COMPLETE' || stepstatus.status === 'COMPLETE-PENDING' ?
                            <Icon name='checkmark' className='icon__checkmark' alt='checkmark icon' />
                            :
                            <div className='card__header__icon__step text--xl-bold icon--dark_gray'>{step}</div>
                        }
                    </div>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>{title}</div>
                        {stepstatus.status !== 'COMPLETE' && stepstatus.status !== 'COMPLETE-PENDING' &&
                        <div className='text--sm-regular'>{description}</div>
                        }
                    </div>
                    {stepstatus.status === 'COMPLETE' &&
                        <div className='card__header__right'>
                            <Button className='secondary btn_edit' onClick={() => onClick_editstep(step)}>Edit</Button>
                        </div>
                    }
                </div>

                <div className={'card__content' + (stepstatus.status === 'CURRENT' ? ' edit' : '')}>
                    <Card var_organization_id={var_organization_id} var_location={var_location} set_location={set_location} status={stepstatus.status} onClick_nextstep={onClick_nextstep} {...other_attributes} />
                </div>
                <Processing display={var_processing} />
            </div>
        );
    }
};