import React, { useState, useEffect } from 'react';
import './cmp_progressbar.css';

function PROGRESSBAR({ number }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_progress, set_progress ] = useState(0);
    const [ var_color, set_color ] = useState('var(--color-red-600)');


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        const calculate_progress = () => {
            const calculated_progress = number <= 100 ? number : 100;
            set_progress(calculated_progress);
        };

        calculate_progress();
    }, [ number ]);

    useEffect(() => {
        const calculate_color = () => {
            const calculated_color =
                var_progress < 75 ? 'var(--color-red-500)' : var_progress < 99 ? 'var(--color-yellow-500)' : 'var(--color-green-500)';
            set_color(calculated_color);
        };

        calculate_color();
    }, [ var_progress ]);


    // RENDER ==========================================================================================================

    return (
        <div>
            <div className='progress-bar-container'>
            <div
                className='progress-bar'
                style={{ width: `${var_progress}%`, backgroundColor: var_color }}
            />
            </div>
            <div className='progress-value text--sm-regular'>{`${var_progress}%`}</div>
        </div>
    );
}

export default PROGRESSBAR;
