import React from 'react';
import propTypes from 'prop-types';
import Logo from '../../cmp_logo';

function FORM_DETAILSGROUP({ label, value, type, linktext, badgecolor, logo_details=null }) {


    //  variable declarations ------------------------------------------------------------------------------------------



    //  functions ------------------------------------------------------------------------------------------------------

    function onClick_internallink(value) {
        if (value && typeof value === 'function') {
            value();
        } else {
            window.open(value);
        }
    }


    // RENDER ==========================================================================================================
    return (
        <div className='detailsgroup'>
            <div className='detailsgroup__label text--sm-medium'>{label}</div>
            {type === 'logo' ?
                <div className='detailsgroup__value logo'>
                    <Logo targetid={logo_details.targetid} classification={logo_details.classification} filename={logo_details.filename}
                            credivera_on_missing={logo_details.credivera_on_missing} credivera_on_error={logo_details.credivera_on_error}
                            alt={logo_details.alt} api_service='alpha_org' api_name='get-logo-file-information' />
                </div>
                : type === 'internallink' ?
                    <div className='detailsgroup__value text--sm-regular text--anchor' >
                        <div onClick={() => onClick_internallink(value)}>{linktext ? linktext : 'Click link'}</div>
                    </div>
                    : type === 'badge' ?
                        <div className={'badge text--sm-medium ' + badgecolor }>{value}</div>
                        : label === 'Email' ? 
                            <div className='detailsgroup__value truncate text--sm-regular'>{value}</div>
                            : <div className='detailsgroup__value text--sm-regular'>{value}</div>
            }
        </div>
    );
}


FORM_DETAILSGROUP.propTypes = {
    label: propTypes.string,
    value: propTypes.oneOfType([propTypes.string, propTypes.func, propTypes.element])
};

export default FORM_DETAILSGROUP;