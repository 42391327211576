import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import INPUT from './input';

function FORM_INPUT({ autoComplete, property, label, value, onChange, onKeyPress, placeholder, maxLength, disabled, errors, description }) {


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER ==========================================================================================================
    return (
        <div className='detailsgroup'>
            <div className='detailsgroup__label text--sm-medium'>{label}</div>
            <Form.Field className='detailsgroup__value'>
                {description && <div className='detailsgroup__description text--sm-regular'>{description}</div>}
                <INPUT
                    autoComplete={autoComplete}
                    property={property}
                    value={value}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    disabled={disabled}
                    errors={errors}
                />
            </Form.Field>
        </div>
    );
}


FORM_INPUT.propTypes = {
    description: propTypes.string,
    property: propTypes.string,
    value: propTypes.string,
    onChange: propTypes.func,
    placeholder: propTypes.string,
    maxLength: propTypes.number,
};

export default FORM_INPUT;