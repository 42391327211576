import React, { useEffect, useState } from 'react';
import API from '../../libs/api-lib';
import CMP_BREADCRUMB from '../../components/cmp_breadcrumb/cmp_breadcrumb';

import CRD_OVERVIEW from './crd_overview/crd_overview';
import CRD_EDITOR from './crd_editor/crd_editor';

export default function TRANSLATIONS() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_tab, set_tab ] = useState('OVERVIEW');
    const [ var_language, set_language ] = useState('');

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (!var_language) {
            get_default_language();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------

    async function get_default_language() {
        try {
            let results = await API_get_languages();
            let default_id = results.find(language => language.name === 'en').id;
            set_language(default_id);
        } catch (e) {
            console.log(e);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_languages() {
        return API.get('translation', '/get-languages');
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_activetab) {
        set_tab(var_activetab);
    }

    // RENDER APP ======================================================================================================

    return(
        <div className='page' id='organizations_list'>

            <div className='page__header page__header--background3'>
                <CMP_BREADCRUMB
                    icon='dashboard'
                    breadcrumb_elements={[]}
                    current_page_text='Translations'
                />
                <h1 className='display--sm-regular'>Translations</h1>

            </div>
            <div className='tab'>
                <div className={'tab__item text--md-bold' + (var_tab === 'OVERVIEW' ? ' current' : '')} onClick={() => onClick_tab('OVERVIEW')}>Overview</div>
                <div className={'tab__item text--md-bold' + (var_tab === 'EDITOR' ? ' current' : '')} onClick={() => onClick_tab('EDITOR')}>Editor</div>
            </div>

            {var_tab === 'OVERVIEW' &&
                <CRD_OVERVIEW
                    set_language={set_language}
                    set_tab={set_tab}
                />
            }
            {var_tab === 'EDITOR' &&
                <CRD_EDITOR
                    set_language={set_language}
                    var_language={var_language}
                />
            }
        </div>
    )
};
