import React from 'react';

import CRD_CHANGE_PASSWORD from './crd_change_password/crd_change_password';



export default function TAB_SECURITY() {

    //  variable declarations ------------------------------------------------------------------------------------------

    //  event listeners ------------------------------------------------------------------------------------------------

    //  functions ------------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------

    // RENDER APP ======================================================================================================

    return (
        <CRD_CHANGE_PASSWORD />
    );
};