import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CMP_BREADCRUMB from '../../components/cmp_breadcrumb/cmp_breadcrumb';

import TAB_INDIVIDUALS from './tab_individuals/tab_individuals';
import TAB_LOCKEDWALLETS from './tab_lockedwallets/tab_lockedwallets';



export default function INDIVIDUALS() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const params = useParams();

    const [ var_tab, set_tab ] = useState('INDIVIDUALS');



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (params.tab) {
            set_tab(params.tab.toUpperCase());
        }
    },[params.tab]);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_activetab){
        set_tab(var_activetab);
    }



    // RENDER APP ======================================================================================================

    return(
        <div className='page'>

            <div className='page__header page__header--background3'>
                <CMP_BREADCRUMB
                    icon='individuals'
                    breadcrumb_elements={[]}
                    current_page_text='Individuals'
                />
                <h1 className='display--sm-regular'>Individuals</h1>

            </div>

            <div className='tab'>
                <div className={'tab__item text--md-bold' + (var_tab === 'INDIVIDUALS' ? ' current' : '')} onClick={()=>onClick_tab('INDIVIDUALS')}>Individuals</div>
                <div className={'tab__item text--md-bold' + (var_tab === 'LOCKEDWALLETS' ? ' current' : '')} onClick={()=>onClick_tab('LOCKEDWALLETS')}>Locked wallets</div>
            </div>

            {var_tab === 'INDIVIDUALS' && <TAB_INDIVIDUALS />}
            {var_tab === 'LOCKEDWALLETS' && <TAB_LOCKEDWALLETS />}
        </div>
    )
};
