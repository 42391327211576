import React, { useState, useEffect } from 'react';
import API from '../../../../../../libs/api-lib';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from '../../../../../../components/cmp_icon';
import form_helper from '../../../../../../libs/form-lib';
import { FORM_DETAILSGROUP, FORM_INPUT, FORM_SELECT } from '../../../../../../components/cmp_form/cmp_form';



export default function CRD_NAMEADDRESS({ location_id, set_location_name }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_mode, set_mode ] = useState('LOADING');
    const [ var_location, set_location ] = useState({});
    const [ var_working_location, set_working_location ] = useState({});
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);

    const [ var_countries ] = useState([{value: null, text: 'Select country'}, {value: 'Canada', text: 'Canada'}, {value: 'United States', text: 'United States'}]);
    const [ var_regions, set_regions ] = useState([]);
    const [ var_disableregions, set_disableregions ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_record();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (var_working_location.country === 'Canada') {
            set_regions([{value: null, text: 'Select region'},
                {value: 'Alberta', text: 'Alberta'},
                {value: 'British Columbia', text: 'British Columbia'},
                {value: 'Manitoba', text: 'Manitoba'},
                {value: 'New Brunswick', text: 'New Brunswick'},
                {value: 'Newfoundland and Labrador', text: 'Newfoundland and Labrador'},
                {value: 'Northwest Territories', text: 'Northwest Territories'},
                {value: 'Nova Scotia', text: 'Nova Scotia'},
                {value: 'Nunavut', text: 'Nunavut'},
                {value: 'Ontario', text: 'Ontario'},
                {value: 'Prince Edward Island', text: 'Prince Edward Island'},
                {value: 'Quebec', text: 'Quebec'},
                {value: 'Saskatchewan', text: 'Saskatchewan'},
                {value: 'Yukon', text: 'Yukon'}]);
            set_disableregions(false);
        } else if (var_working_location.country === 'United States') {
            set_regions([{value: null, text: 'Select region'},
                {value: 'Alabama', text: 'Alabama'},
                {value: 'Alaska', text: 'Alaska'},
                {value: 'Arizona', text: 'Arizona'},
                {value: 'Arkansas', text: 'Arkansas'},
                {value: 'California', text: 'California'},
                {value: 'Colorado', text: 'Colorado'},
                {value: 'Connecticut', text: 'Connecticut'},
                {value: 'Delaware', text: 'Delaware'},
                {value: 'District of Columbia', text: 'District of Columbia'},
                {value: 'Florida', text: 'Florida'},
                {value: 'Georgia', text: 'Georgia'},
                {value: 'Guam', text: 'Guam'},
                {value: 'Hawaii', text: 'Hawaii'},
                {value: 'Idaho', text: 'Idaho'},
                {value: 'Illinois', text: 'Illinois'},
                {value: 'Indiana', text: 'Indiana'},
                {value: 'Iowa', text: 'Iowa'},
                {value: 'Kansas', text: 'Kansas'},
                {value: 'Kentucky', text: 'Kentucky'},
                {value: 'Louisiana', text: 'Louisiana'},
                {value: 'Maine', text: 'Maine'},
                {value: 'Maryland', text: 'Maryland'},
                {value: 'Massachusetts', text: 'Massachusetts'},
                {value: 'Michigan', text: 'Michigan'},
                {value: 'Minnesota', text: 'Minnesota'},
                {value: 'Mississippi', text: 'Mississippi'},
                {value: 'Missouri', text: 'Missouri'},
                {value: 'Montana', text: 'Montana'},
                {value: 'Nebraska', text: 'Nebraska'},
                {value: 'Nevada', text: 'Nevada'},
                {value: 'New Hampshire', text: 'New Hampshire'},
                {value: 'New Jersey', text: 'New Jersey'},
                {value: 'New Mexico', text: 'New Mexico'},
                {value: 'New York', text: 'New York'},
                {value: 'North Carolina', text: 'North Carolina'},
                {value: 'North Dakota', text: 'North Dakota'},
                {value: 'Ohio', text: 'Ohio'},
                {value: 'Oklahoma', text: 'Oklahoma'},
                {value: 'Oregon', text: 'Oregon'},
                {value: 'Pennsylvania', text: 'Pennsylvania'},
                {value: 'Puerto Rico', text: 'Puerto Rico'},
                {value: 'Rhode Island', text: 'Rhode Island'},
                {value: 'South Carolina', text: 'South Carolina'},
                {value: 'South Dakota', text: 'South Dakota'},
                {value: 'Tennessee', text: 'Tennessee'},
                {value: 'Texas', text: 'Texas'},
                {value: 'Utah', text: 'Utah'},
                {value: 'Vermont', text: 'Vermont'},
                {value: 'Virginia', text: 'Virginia'},
                {value: 'Washington', text: 'Washington'},
                {value: 'West Virginia', text: 'West Virginia'},
                {value: 'Wisconsin', text: 'Wisconsin'},
                {value: 'Wyoming', text: 'Wyoming'}]);
            set_disableregions(false);
        } else {
            set_regions([]);
            set_disableregions(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_working_location.country]);



    //  functions ------------------------------------------------------------------------------------------------------

    async function populate_record() {
        let record = await API_get_org_location();
        set_location(record);
        set_location_name(record.location_name);
        set_mode('VIEW');
    }

    async function save_record(working_location) {
        set_processing(true);
        try {
            await API_put_org_location(working_location);
            set_location({...var_location, ...working_location});
            set_location_name(working_location.location_name);
        } finally {
            set_mode('VIEW');
            set_processing(false);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_location() {
        return API.get('alpha-org', '/get-org-location/' + location_id);
    }

    function API_put_org_location(working_location) {
        return API.put('alpha-org', '/put-org-location', {
            body: working_location
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        let {organization_id, id, code, location_name, address, city, province, country} = var_location;
        set_working_location({organization_id, id, code, location_name, address, city, province, country});
        set_errors([]);
        set_mode('EDIT');
    }

    function onClick_cancel() {
        set_mode('VIEW');
    }

    function onChange_input(event) {
        let working_location = {...var_working_location};
        working_location[event.target.name] = event.target.value;
        set_working_location(working_location);
    }

    function onClick_save() {
        if (var_processing) return;

        let location = {...var_working_location};
        let errors = [];
        // validation
        if (!form_helper.validate_required_string(location.location_name)) {
            errors.push({property: 'location_name', description: 'Assignment name is required.'});
        }
        set_errors(errors);
        if (errors.length > 0) return;

        // cleanse properties
        form_helper.cleanse_string_property(location, 'code');
        form_helper.cleanse_string_property(location, 'location_name');
        form_helper.cleanse_string_property(location, 'address');
        form_helper.cleanse_string_property(location, 'city');
        form_helper.cleanse_string_property(location, 'province');
        form_helper.cleanse_string_property(location, 'country');
        set_working_location(location);
        save_record(location);
    }

    function onChange_country(e, { value }) {
        let working_location = { ...var_working_location };
        if (working_location.country !== value) {
            working_location.province = null;
        }
        working_location.country = value;
        set_working_location(working_location);
    }

    function onChange_region(e, { value }) {
        let working_location = { ...var_working_location };
        working_location.province = value;
        set_working_location(working_location);
    }



    // RENDER APP ======================================================================================================

    return (
        <>

            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>Assignment name &amp; address</div>
                </div>
                <div className='card__header__right'>
                    {var_mode === 'VIEW' &&
                    <Button className='secondary' onClick={() => onClick_edit()}>Edit</Button>
                    }
                </div>
            </div>

            <div className='card__content'>
                <Form>
                    {
                        var_mode === 'VIEW' ?
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <FORM_DETAILSGROUP label='Code' value={var_location.code} />
                            <FORM_DETAILSGROUP label='Assignment name' value={var_location.location_name} />
                            <FORM_DETAILSGROUP label='Address' value={var_location.address} />
                            <FORM_DETAILSGROUP label='City' value={var_location.city} />
                            <FORM_DETAILSGROUP label='Province' value={var_location.province} />
                            <FORM_DETAILSGROUP label='Country' value={var_location.country} />
                        </div>
                        : var_mode === 'EDIT' ?
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {var_errors.length > 0 &&
                                <Message error
                                    icon={<Icon name='error' className='icon' />}
                                    header='There are some errors with your inputs'
                                />
                            }

                            <FORM_INPUT
                                property='code'
                                label='Code'
                                value={var_working_location.code}
                                onChange={onChange_input}
                                placeholder='Code'
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />

                            <FORM_INPUT
                                property='location_name'
                                label='Assignment name'
                                value={var_working_location.location_name}
                                onChange={onChange_input}
                                placeholder='Assignment name'
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />

                            <FORM_INPUT
                                property='address'
                                label='Address'
                                value={var_working_location.address}
                                onChange={onChange_input}
                                placeholder='Address'
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />

                            <FORM_SELECT
                                property='country'
                                label='Country'
                                value={var_working_location.country || ''}
                                onChange={onChange_country}
                                placeholder='Select'
                                options={var_countries}
                                disabled={false}
                                errors={var_errors}
                            />

                            <FORM_SELECT
                                property='province'
                                label='Province/State'
                                value={var_working_location.province || ''}
                                onChange={onChange_region}
                                placeholder='Select'
                                options={var_regions}
                                disabled={var_disableregions}
                                errors={var_errors}
                            />

                            <FORM_INPUT
                                property='city'
                                label='City'
                                value={var_working_location.city}
                                onChange={onChange_input}
                                placeholder='City'
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />

                            <div className='card__footer'>
                                <Button className='primary' onClick={onClick_save}>Save</Button>
                                <Button className='secondary' onClick={onClick_cancel}>Cancel</Button>
                            </div>
                        </div>
                        : null
                    }
                </Form>
            </div>
        </>
    );

};