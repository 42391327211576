import config from '../config';
import auth from './auth-lib';
import axios from 'axios';

class API {
    get(apiName, path, init) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return send_request(config['api_' + apiName.replace(/-/g, '_')].url, path, 'GET', init);
    }
    put(apiName, path, init) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return send_request(config['api_' + apiName.replace(/-/g, '_')].url, path, 'PUT', init);
    }
    post(apiName, path, init) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return send_request(config['api_' + apiName.replace(/-/g, '_')].url, path, 'POST', init);
    }
    del(apiName, path, init) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return send_request(config['api_' + apiName.replace(/-/g, '_')].url, path, 'DELETE', init);
    }
    async upload(file, filename) {
        let access_token = await auth.iam.get_access_token();
        if (!access_token) {
            throw new Error('Not authenticated');
        }
        let form_data = new FormData();
        form_data.append('filename', filename);
        form_data.append('file', file);
        await axios({
            method: 'post',
            url: config.api_gamma_admin.url + '/upload',
            data: form_data,
            headers: {
                Authorization: 'Bearer ' + access_token,
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    endpoint(apiName) {
        return config['api_' + apiName.replace(/-/g, '_')].url;
    }
}

async function send_request(base_path, path, method, parameters) {
    let options = {
        method,
        url: base_path + path,
    };
    if (parameters && parameters.queryStringParameters) {
        options.params = parameters.queryStringParameters;
    }
    if (parameters && parameters.body) {
        options.data = parameters.body;
    }
    let access_token = await auth.iam.get_access_token();
    if (access_token) {
        options.headers = {
            Authorization: 'Bearer ' + access_token
        };
    }
    let response;
    try {
        response = await axios(options);
    } catch (error) {
        let error_message = 'send_request error:';
        if (axios.isAxiosError(error)) {
            error_message = 'send_request Axios error:';
        }
        console.error(error_message, error);
        throw error;
    }
    return response.data;
}

export default new API();