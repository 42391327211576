import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../../../../../../libs/api-lib';
import { Form, Button, Message } from 'semantic-ui-react';
import Icon from '../../../../../../components/cmp_icon';
import config from '../../../../../../config';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import form_helper from '../../../../../../libs/form-lib';
import datelib from '../../../../../../libs/date-lib';
import Processing from '../../../../../../components/cmp_processing';
import { FORM_SELECT, CHECKBOX  } from '../../../../../../components/cmp_form/cmp_form';



export default function TAB_SINGLE({ organization_id, assignments }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const navigate = useNavigate();
    const [ var_invitedata, set_invitedata ] = useState({ sendemail: 'YES' });
    const [ var_file, set_file ] = useState(null);
    const [ var_permission_options, set_permission_options ] = useState([]);
    const [ var_location_options, set_location_options ] = useState([]);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_processing_batchid, set_processing_batchid ] = useState(null);
    const [ var_save_error, set_save_error ] = useState(false);

    const language_options = Object.keys(config.language).map(key => ({ key, text: config.language[key].name, value: key }));

    //  event listeners ------------------------------------------------------------------------------------------------


    useEffect(() => {
        load_permission_options();
        load_location_options();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  functions ------------------------------------------------------------------------------------------------------

    async function load_permission_options() {
        let permissions = await API_get_permissions();
        set_permission_options(permissions.map(item => ({value: item.id, text: item.permission})));
    }

    async function load_location_options() {
        let locations = await API_get_dd_locations();
        set_location_options(locations.map(item => ({value: item.id, text: item.location_name})));
        if (locations.length > 0) {
            set_invitedata({...var_invitedata, location_id: locations[0].id});
        }
    }

    async function invite_individuals() {
        set_processing(true);
        set_save_error(false);
        try {
            let filedata = await new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = () => {
                    let xlsxData = XLSX.read(reader.result, { type: 'binary' });
                    let worksheet = xlsxData.Sheets[xlsxData.SheetNames[0]];
                    resolve(XLSX.utils.sheet_to_json(worksheet, { skipHeader: true }));
                }
                reader.onerror = reject;
                reader.readAsBinaryString(var_file);
            });
            let records = [];
            let invalid_records = [];
            for (let record of filedata) {
                if (record['FIRST NAME'] && record['LAST NAME'] && record['EMAIL ADDRESS'] && form_helper.validate_email_address(record['EMAIL ADDRESS'].trim(), false)) {
                    records.push({
                        firstname: record['FIRST NAME'].trim(),
                        lastname: record['LAST NAME'].trim(),
                        email: record['EMAIL ADDRESS'].trim()
                    })
                } else {
                    invalid_records.push(record);
                }
            }
            if (invalid_records.length > 0) {
                set_save_error(true);
            }

            let result = null;
            if (records.length > 0) {
                // break up into 25,000 record chunks if necessary.  otherwise we could hit the 6MB limit of the lambdas
                const BATCH_SIZE = 25000;
                let start_counter = 0;
                while (start_counter < records.length) {
                    result = await API_post_indv_invite_bulk(records.slice(start_counter, start_counter + BATCH_SIZE), result);
                    start_counter += BATCH_SIZE;
                }
                set_processing_batchid(result);
            }
        } catch (exception) {
            console.log(exception);
            set_save_error(true);
            set_processing(false);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------


    function API_get_permissions() {
        return API.get('alpha-org', '/get-permissions');
    }

    function API_get_dd_locations() {
        return API.get('alpha-org', '/get-dd-locations/' + organization_id, { queryStringParameters: { active: 'YES' } });
    }

    function API_post_indv_invite_bulk(individuals, batch_id) {
        return API.post('alpha-org', '/post-indv-invite-bulk', {
            body: {
                batch_id,
                organization_id,
                permission_id: var_invitedata.permission_id,
                location_id: var_invitedata.location_id,
                sendemail: var_invitedata.sendemail,
                individuals,
                tz: datelib.timezone,
                email_language: var_invitedata.email_language
            }
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_downloadtemplate() {
        let headers = [['FIRST NAME', 'LAST NAME', 'EMAIL ADDRESS']];

        //  create excel spreadsheet -----------------------------------------------------------------------------------

        const wb = XLSX.utils.book_new();
        var ws = XLSX.utils.aoa_to_sheet(headers);
        XLSX.utils.book_append_sheet(wb, ws, 'Invite Template');

        //  write file -------------------------------------------------------------------------------------------------

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        FileSaver.saveAs(data, 'Bulk invite template.xlsx');
    }

    function onClick_selectfile() {
        document.getElementById('btn_hiddenfileinput').click();
    }

    function onChange_selectfile(event) {
        if (event.target.files[0]) {
            set_file(event.target.files[0]);
        }
    }

    function onChange_input(event, { name, value }) {
        let invitedata = {...var_invitedata};
        invitedata[name] = value;
        set_invitedata(invitedata);
        if (var_errors.length > 0) {
            set_errors(var_errors.filter(error => error.property !== event.target.name))
        }
    }

    function onClick_save() {
        if (var_processing) return;

        let invitedata = { ...var_invitedata };
        let file = var_file;
        let errors = [];
        // validation:
        if (!file) {
            errors.push({property: 'file', description: 'Please select a file'});
        } else if (!['csv','xls','xlsx'].includes(file.name.split('.').pop().toLowerCase())) {
            errors.push({property: 'file', description: 'You have selected an invalid file type'});
        }
        if (!invitedata.permission_id) {
            errors.push({property: 'permission_id', description: 'Please select a permission level'});
        }
        if (!invitedata.email_language) {
            errors.push({property: 'email_language', description: 'Please select the language for the email.'});
        }
        set_errors(errors);
        if (errors.length > 0) return;

        invite_individuals();
    }

    function onClick_cancel() {
        navigate('/organizations/' + organization_id + '/individuals');
    }

    function onComplete_processing(total_items, results) {
        set_processing_batchid(null);
        let errors_exist = var_save_error || results.some(result => JSON.parse(result.results).errors.length > 0);
        set_save_error(errors_exist);
        set_processing(false);
        if (!errors_exist) {
            navigate('/organizations/' + organization_id + '/individuals');
        }
    }



    // RENDER APP ======================================================================================================

    return (

        <div className='card rounded-lg shadow-sm' id='individual_details__tab__bulkinvite'>

            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>Add multiple individuals</div>
                    <div className='text--sm-regular'>You can add multiple individuals to the organization by completing the following steps:</div>
                    <div className='text--sm-regular'>
                        <ol>
                            <li>Download and complete the Excel template.</li>
                            <li>Upload the completed Excel template.</li>
                            <li>Select the permission level. All individuals will have the same permission level applied to them.</li>
                            <li>Add individuals.</li>
                        </ol>
                    </div>
                </div>
            </div>

            <div className='card__content'>

                {var_save_error &&
                    <Message error
                        header='There was a problem saving.  Please try again later'
                    />
                }

                {var_errors.length > 0 &&
                    <Message error
                                icon={<Icon name='error' className='icon' />}
                                header={'This page contained errors'}
                    />
                }

                <Form>
                    <div className='detailsgroup'>
                        <div className='detailsgroup__label text--sm-medium'>Excel template</div>
                        <Form.Field style={{display: 'flex', flexDirection: 'row'}}>
                            <Icon name='description' className='icon--sm' />
                            <a onClick={onClick_downloadtemplate} href={onClick_downloadtemplate}>Bulk invite template.xlsx</a>
                        </Form.Field>
                    </div>

                    <div className='detailsgroup'>
                        <div className='detailsgroup__label text--sm-medium'>Data file</div>
                        <Form.Field>
                            <div className='text--sm-regular field__description'>Upload the completed Excel template.</div>
                            <input
                                id='btn_hiddenfileinput'
                                type='file'
                                style={{display: 'none'}}
                                onChange={onChange_selectfile}
                                accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                            />
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <input className='file__upload' readOnly={true} value={var_file ? var_file.name : ''} placeholder='File' style={{margin: '0 5px 0 0'}} />
                                <Button className='secondary' onClick={onClick_selectfile}>Select file</Button>
                            </div>
                            {var_errors.find(item => item.property === 'file') &&
                            <div className='field__feedback error'>
                                <div className='description text--sm-regular'>{var_errors.find(item => item.property === 'file').description}</div>
                            </div>
                            }
                        </Form.Field>
                    </div>
                    <FORM_SELECT
                        description='Select the permission level you want to assign to these individuals.'
                        property='permission_id'
                        label='Permission level'
                        value={var_invitedata['permission_id'] || ''}
                        onChange={onChange_input}
                        placeholder='Select'
                        options={var_permission_options}
                        disabled={false}
                        errors={var_errors}
                    />

                    {assignments === 'YES' &&
                        <FORM_SELECT
                            property='location_id'
                            label='Assignment'
                            value={var_invitedata['location_id'] || ''}
                            onChange={onChange_input}
                            placeholder='Select'
                            options={var_location_options}
                            disabled={false}
                            errors={var_errors}
                        />
                    }

                    <FORM_SELECT
                        property='email_language'
                        label='Email language'
                        description='Choose the language for the invite email. This selection may be overridden if the individual has already specified a preferred language in their profile.'
                        value={var_invitedata.email_language}
                        onChange={onChange_input}
                        placeholder='Select'
                        options={language_options}
                        errors={var_errors}
                    />

                    <div className='detailsgroup' style={{ borderTop: 'none', paddingTop: '0' }}>
                        <div className='detailsgroup__label'></div>
                        <CHECKBOX name='suppress_invite' label='Do not send an invite email to these individuals' checked={var_invitedata.sendemail === 'NO'} onChange={(e) => onChange_input(e, { name:'sendemail', value: var_invitedata.sendemail === 'YES' ? 'NO' : 'YES' })} />
                    </div>


                </Form>
            </div>

            <div className='card__footer'>
                <Button className='primary' onClick={onClick_save}>Add individuals</Button>
                <Button className='secondary' onClick={onClick_cancel}>Cancel</Button>
            </div>

            <Processing display={var_processing} batch_id={var_processing_batchid} onComplete={onComplete_processing} />
        </div>
    );

};