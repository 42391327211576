import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../libs/api-lib';
import CMP_BREADCRUMB from '../../../components/cmp_breadcrumb/cmp_breadcrumb';

import TAB_DETAILS from './tab_details/tab_details';
import TAB_INDIVIDUALS from './tab_individuals/tab_individuals';
import TAB_LOCATIONS from './tab_locations/tab_locations';
import TAB_SETTINGS from './tab_settings/tab_settings';



export default function ORGANIZATION_DETAILS() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const params = useParams();

    const [ var_tab, set_tab ] = useState('DETAILS');
    const [ var_organizationname, set_organizationname ] = useState('');
    const [ var_features, set_features ] = useState({});


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (params.tab) {
            set_tab(params.tab.toUpperCase());
        }
        populate_record();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_record() {
        let record = await API_get_organization_features();
        set_features({id: params.organizationid, ...record});
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_organization_features() {
        return API.get('alpha-org', '/get-organization-features/' + params.organizationid);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_activetab){
        set_tab(var_activetab);
    }



    // RENDER APP ======================================================================================================

    return(
        <div className='page' id='organization_details'>

            <div className='page__header page__header--background3'>
                <CMP_BREADCRUMB
                    icon='organization'
                    breadcrumb_elements={[{ path: '/organizations', text: 'Organizations' }]}
                    current_page_text={var_organizationname}
                />
                <h1 className='display--sm-regular'>{var_organizationname}</h1>

            </div>

            <div className='tab'>
                <div className={'tab__item text--md-bold' + (var_tab === 'DETAILS' ? ' current' : '')} onClick={()=>onClick_tab('DETAILS')}>Details</div>
                <div className={'tab__item text--md-bold' + (var_tab === 'INDIVIDUALS' ? ' current' : '')} onClick={()=>onClick_tab('INDIVIDUALS')}>Individuals</div>
                { var_features.assignments === 'YES' &&
                    <div className={'tab__item text--md-bold' + (var_tab === 'LOCATIONS' ? ' current' : '')} onClick={()=>onClick_tab('LOCATIONS')}>Assignments</div>
                }
                <div className={'tab__item text--md-bold' + (var_tab === 'SETTINGS' ? ' current' : '')} onClick={()=>onClick_tab('SETTINGS')}>Settings</div>
            </div>

            {var_tab === 'DETAILS' &&
            <TAB_DETAILS
                organization_id={params.organizationid}
                activetab={var_tab}
                onChange_organization_name={set_organizationname}/>
            }

            {var_tab === 'INDIVIDUALS' &&
            <TAB_INDIVIDUALS
                var_organizationid={params.organizationid}
                assignments={var_features.assignments}/>
            }

            {var_tab === 'LOCATIONS' &&
            <TAB_LOCATIONS
                var_organizationid={params.organizationid}
            />
            }

            {var_tab === 'SETTINGS' &&
            <TAB_SETTINGS
                var_organizationid={params.organizationid}
            />
            }

        </div>
    )
};
