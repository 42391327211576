import React from 'react';
import propTypes from 'prop-types';
import {Form, Select} from 'semantic-ui-react';
import _ from 'lodash';

import './cmp_rowsperpage.css'
import Icon from '../../cmp_icon';


function CMP_ROWSPERPAGE({ limit, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const rows_per_page_id = _.uniqueId('rows_per_page-');


    //  variable listeners ---------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_limit(e, { value }) {
        onChange && onChange(value);
    }


    // RENDER APP ======================================================================================================

    return (
        <div className='table__controls__rowsperpage text--sm-regular' id={rows_per_page_id}>
            Rows per page:
            <Form.Field>
                <Select
                    placeholder='10'
                    onChange={onChange_limit}
                    value={limit || 10}
                    options={[
                        {'text': '10', 'value': 10},
                        {'text': '15', 'value': 15},
                        {'text': '25', 'value': 25},
                        {'text': '50', 'value': 50},
                        {'text': '100', 'value': 100}]}
                    icon={<Icon name='chevron_down' className='icon' />}
                    aria-labelledby={rows_per_page_id}
                />
            </Form.Field>
        </div>
    );
}

CMP_ROWSPERPAGE.propTypes = {
    limit: propTypes.number.isRequired,
    onChange: propTypes.func.isRequired
};

export default CMP_ROWSPERPAGE;
