import React, { useState, useEffect } from 'react';
import CMP_BREADCRUMB from '../../components/cmp_breadcrumb/cmp_breadcrumb';
import { useParams } from 'react-router-dom';

import TAB_ALPHA from './tab_alpha/tab_alpha';



export default function SETTINGS() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const params = useParams();

    const [ var_tab, set_tab ] = useState('ALPHA');


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (params.tab) {
            set_tab(params.tab.toUpperCase());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_activetab){
        set_tab(var_activetab);
    }



    // RENDER APP ======================================================================================================

    return(
        <div className='page'>

            <div className='page__header page__header--background3'>
                <CMP_BREADCRUMB
                    icon='dashboard'
                    breadcrumb_elements={[]}
                    current_page_text='Platform Settings'
                />
                <h1 className='display--sm-regular'>Platform Settings</h1>

            </div>

            <div className='tab'>
                <div className={'tab__item text--md-bold' + (var_tab === 'ALPHA' ? ' current' : '')} onClick={()=>onClick_tab('ALPHA')}>Alpha</div>
            </div>

            {var_tab === 'ALPHA' &&
            <TAB_ALPHA var_activetab={var_tab} />
            }

        </div>
    )
};