import React, { useState, useEffect } from 'react';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from '../../../../components/cmp_icon';
import form_helper from '../../../../libs/form-lib';
import {CHECKBOX, FORM_INPUT, FORM_SELECT} from "../../../../components/cmp_form/cmp_form";



export default function CRD_LOCATIONS({ var_organization, set_organization, status, onClick_nextstep }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_can_use_org_address, set_can_use_org_address ] = useState(false);
    const [ var_use_org_address, set_use_org_address ] = useState(false);
    const [ var_errors, set_errors ] = useState([]);

    const [ var_countries ] = useState([{value: null, text: 'Select country'}, {value: 'Canada', text: 'Canada'}, {value: 'United States', text: 'United States'}]);
    const [ var_regions, set_regions ] = useState([]);
    const [ var_disableregions, set_disableregions ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_errors([]);
        if (status === 'CURRENT') {
            set_can_use_org_address(var_organization.address1 || var_organization.city || var_organization.province || var_organization.country);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, set_can_use_org_address]);

    useEffect(() => {
        if (var_organization.location.country === 'Canada') {
            set_regions([{value: null, text: 'Select region'},
                {value: 'Alberta', text: 'Alberta'},
                {value: 'British Columbia', text: 'British Columbia'},
                {value: 'Manitoba', text: 'Manitoba'},
                {value: 'New Brunswick', text: 'New Brunswick'},
                {value: 'Newfoundland and Labrador', text: 'Newfoundland and Labrador'},
                {value: 'Northwest Territories', text: 'Northwest Territories'},
                {value: 'Nova Scotia', text: 'Nova Scotia'},
                {value: 'Nunavut', text: 'Nunavut'},
                {value: 'Ontario', text: 'Ontario'},
                {value: 'Prince Edward Island', text: 'Prince Edward Island'},
                {value: 'Quebec', text: 'Quebec'},
                {value: 'Saskatchewan', text: 'Saskatchewan'},
                {value: 'Yukon', text: 'Yukon'}]);
            set_disableregions(false);
        } else if (var_organization.location.country === 'United States') {
            set_regions([{value: null, text: 'Select region'},
                {value: 'Alabama', text: 'Alabama'},
                {value: 'Alaska', text: 'Alaska'},
                {value: 'Arizona', text: 'Arizona'},
                {value: 'Arkansas', text: 'Arkansas'},
                {value: 'California', text: 'California'},
                {value: 'Colorado', text: 'Colorado'},
                {value: 'Connecticut', text: 'Connecticut'},
                {value: 'Delaware', text: 'Delaware'},
                {value: 'District of Columbia', text: 'District of Columbia'},
                {value: 'Florida', text: 'Florida'},
                {value: 'Georgia', text: 'Georgia'},
                {value: 'Guam', text: 'Guam'},
                {value: 'Hawaii', text: 'Hawaii'},
                {value: 'Idaho', text: 'Idaho'},
                {value: 'Illinois', text: 'Illinois'},
                {value: 'Indiana', text: 'Indiana'},
                {value: 'Iowa', text: 'Iowa'},
                {value: 'Kansas', text: 'Kansas'},
                {value: 'Kentucky', text: 'Kentucky'},
                {value: 'Louisiana', text: 'Louisiana'},
                {value: 'Maine', text: 'Maine'},
                {value: 'Maryland', text: 'Maryland'},
                {value: 'Massachusetts', text: 'Massachusetts'},
                {value: 'Michigan', text: 'Michigan'},
                {value: 'Minnesota', text: 'Minnesota'},
                {value: 'Mississippi', text: 'Mississippi'},
                {value: 'Missouri', text: 'Missouri'},
                {value: 'Montana', text: 'Montana'},
                {value: 'Nebraska', text: 'Nebraska'},
                {value: 'Nevada', text: 'Nevada'},
                {value: 'New Hampshire', text: 'New Hampshire'},
                {value: 'New Jersey', text: 'New Jersey'},
                {value: 'New Mexico', text: 'New Mexico'},
                {value: 'New York', text: 'New York'},
                {value: 'North Carolina', text: 'North Carolina'},
                {value: 'North Dakota', text: 'North Dakota'},
                {value: 'Ohio', text: 'Ohio'},
                {value: 'Oklahoma', text: 'Oklahoma'},
                {value: 'Oregon', text: 'Oregon'},
                {value: 'Pennsylvania', text: 'Pennsylvania'},
                {value: 'Puerto Rico', text: 'Puerto Rico'},
                {value: 'Rhode Island', text: 'Rhode Island'},
                {value: 'South Carolina', text: 'South Carolina'},
                {value: 'South Dakota', text: 'South Dakota'},
                {value: 'Tennessee', text: 'Tennessee'},
                {value: 'Texas', text: 'Texas'},
                {value: 'Utah', text: 'Utah'},
                {value: 'Vermont', text: 'Vermont'},
                {value: 'Virginia', text: 'Virginia'},
                {value: 'Washington', text: 'Washington'},
                {value: 'West Virginia', text: 'West Virginia'},
                {value: 'Wisconsin', text: 'Wisconsin'},
                {value: 'Wyoming', text: 'Wyoming'}]);
            set_disableregions(false);
        } else {
            set_regions([]);
            set_disableregions(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_organization.location.country]);



    //  functions ------------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event) {
        let organization = {...var_organization, location: {...var_organization.location}};
        organization.location[event.target.name] = event.target.value;
        set_organization(organization);
        if (var_errors.length > 0) {
            const errors = var_errors.filter(error => error.property !== event.target.name)
            set_errors(errors);
        }
    }

    function onChange_use_org_address() {
        let selected = !var_use_org_address;
        set_use_org_address(selected);
        if (selected) {
            let organization = {...var_organization, location: {...var_organization.location}};
            organization.location.address = organization.address1;
            organization.location.city = organization.city;
            organization.location.province = organization.province;
            organization.location.country = organization.country;
            set_organization(organization);
        }
    }

    function onChange_country(e, { value }) {
        let organization = { ...var_organization };
        if (organization.location.country !== value) {
            organization.location.province = null;
        }
        organization.location.country = value;
        set_organization(organization);
    }

    function onChange_region(e, { value }) {
        let organization = { ...var_organization };
        organization.location.province = value;
        set_organization(organization);
    }

    function onClick_next() {
        let organization = {...var_organization, location: {...var_organization.location}};
        let errors = [];
        if (errors.length > 0) return;

        // cleanse properties
        form_helper.cleanse_string_property(organization.location, 'code');
        form_helper.cleanse_string_property(organization.location, 'location_name');
        form_helper.cleanse_string_property(organization.location, 'address');
        form_helper.cleanse_string_property(organization.location, 'city');
        form_helper.cleanse_string_property(organization.location, 'province');
        set_organization(organization);
        onClick_nextstep();
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            {status === 'COMPLETE' || status === 'COMPLETE-PENDING'
                ?
                <div className='card__complete'>
                    <div>{var_organization.location.code ? var_organization.location.code + ': ' : ''}{var_organization.location.location_name}</div>
                    {render_address()}
                </div>
                :
                <div></div>
            }
            {status === 'CURRENT' && render_form()}
        </>
    );

    function render_address() {
        let lines = [];
        var_organization.location.address && lines.push(var_organization.location.address);
        if (var_organization.location.city) {
            lines.length === 0 ? lines.push(var_organization.location.city) : lines[lines.length-1] += ', ' + var_organization.location.city;
        }
        if (var_organization.location.province) {
            lines.length === 0 ? lines.push(var_organization.location.province) : lines[lines.length-1] += ', ' + var_organization.location.province;
        }
        if (var_organization.location.country) {
            lines.length === 0 ? lines.push(var_organization.location.country) : lines[lines.length-1] += ', ' + var_organization.location.country;
        }
        var_organization.location.postal && lines.push(var_organization.location.postal);
        return (
            <>
                {lines.map((item, index) => <div key={'locaddress-' + index}>{item}</div>)}
            </>
        );
    }

    function render_form() {
        return (
            <>
                {var_errors.length > 0 &&
                <Message error
                         icon={<Icon name='error' className='icon' />}
                         header={'Required fields are empty or contain invalid data.'}
                />
                }
                <Form>
                    {var_can_use_org_address &&
                        <div className='detailsgroup'>
                            <Form.Field>
                                <CHECKBOX name='useaddress'
                                          label='The address of the default assignment is the same as the address of the organization.'
                                          // value={item.code}
                                          checked={var_use_org_address}
                                          onChange={onChange_use_org_address}/>
                            </Form.Field>
                        </div>
                        // <FORM_TOGGLE
                        //     label=''
                        //     description='The address of the default assignment is the same as the address of the organization.'
                        //     value={var_use_org_address}
                        //     options={[{value: 'YES', text: 'Yes'}, {value: 'NO', text: 'No' }]}
                        //     onChange={onChange_use_org_address}
                        // />
                    }

                    <FORM_INPUT
                        property='location_name'
                        label='Assignment name (required)'
                        value={var_organization.location.location_name}
                        onChange={onChange_input}
                        placeholder='Name'
                        maxLength={100}
                        disabled={false}
                        errors={var_errors}
                    />

                    <FORM_INPUT
                        property='code'
                        label='Code'
                        value={var_organization.location.code}
                        onChange={onChange_input}
                        placeholder='Code'
                        maxLength={100}
                        disabled={false}
                        errors={var_errors}
                    />

                    <FORM_INPUT
                        property='address'
                        label='Address'
                        value={var_organization.location.address}
                        onChange={onChange_input}
                        placeholder='Address'
                        maxLength={100}
                        disabled={var_use_org_address}
                        errors={var_errors}
                    />

                    <FORM_SELECT
                        property='country'
                        label='Country'
                        value={var_organization.location.country || ''}
                        onChange={onChange_country}
                        placeholder='Select'
                        options={var_countries}
                        disabled={var_use_org_address}
                        errors={var_errors}
                    />

                    <FORM_SELECT
                        property='province'
                        label='Province/State'
                        value={var_organization.location.province || ''}
                        onChange={onChange_region}
                        placeholder='Select'
                        options={var_regions}
                        disabled={var_disableregions||var_use_org_address}
                        errors={var_errors}
                    />

                    <FORM_INPUT
                        property='city'
                        label='City'
                        value={var_organization.location.city}
                        onChange={onChange_input}
                        placeholder='City'
                        maxLength={100}
                        disabled={var_use_org_address}
                        errors={var_errors}
                    />

                </Form>
                <div className='card__footer'>
                    <Button className='primary' onClick={onClick_next}>Save organization</Button>
                </div>
            </>
        );
    }

};
