import React from 'react';
import CMP_BREADCRUMB from '../../../components/cmp_breadcrumb/cmp_breadcrumb';

import CRD_ORGANIZATIONS from './crd_organizations/crd_organizations';



export default function ORGANIZATION_LIST() {

    //  variable declarations ------------------------------------------------------------------------------------------



    //  event listeners ------------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return(
        <div className='page' id='organizations_list'>

            <div className='page__header page__header--background3'>
                <CMP_BREADCRUMB
                    icon='organization'
                    breadcrumb_elements={[]}
                    current_page_text='Organizations'
                />
                <h1 className='display--sm-regular'>Organizations</h1>

            </div>

            <CRD_ORGANIZATIONS/>

        </div>
    )
};
