import React from 'react';
import { useNavigate } from 'react-router-dom';
import auth from '../../libs/auth-lib';

import Icon from '../../components/cmp_icon'

import './notfound.css';
import config from '../../config';


export default function NotFound() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const navigate = useNavigate();

    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------

    async function onClick_link(e) {
        e && e.preventDefault();
        navigate('/');
    }

    // RENDER APP ======================================================================================================

    return (
        auth.is_loggedin ?
        <div className='page' id='notfound'>
            <div className='card rounded-lg shadow-sm'>
                <div className='card__header'>
                    <div className='text--sm-bold'>Error code 404</div>
                </div>
                <div className='card__header'>
                    <div className='text--sm-medium'>The page you were looking for does not exist.</div>
                </div>
                <div className='card__header'>
                    <div className='email__link'>
                        {'We\'re not quite sure what went wrong. You can go back, or try '}
                        <a href='mailto:help@credivera.com'>
                            contacting us
                        </a>
                        {' if you need a hand.'}
                    </div>
                </div>
            </div>
        </div>
        :
        <div id='notfound__offline'>
            <div className='nav'>
                <div className='nav__header'>
                    <img className='logo__img' src={`${config.images.url}${config.images.assets.Credivera_Horizontal_White}${config.images.stage}`} alt='Credivera logo'></img>
                </div>
            </div>
            <div className='content'>
                <div className='information'>
                    <div>
                        <div className='text--sm-bold'>Whoops!</div>
                    </div>
                    <div className='card__header'>
                        <div className='text--sm-medium'>The page you were looking for does not exist.</div>
                    </div>
                    <div className='email__link'>
                        {'Please check the URL and try again or you can head over to our '}
                            <a onClick={(event) => onClick_link(event)} href='/#'>
                                sign in page
                            </a>
                        {' instead.'}
                    </div>
                </div>
                <div className='notfound404__img'>
                    <Icon name='notfound' className='icon__notfound' alt='' />
                </div>
            </div>
        </div>
    );
};