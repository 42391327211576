import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import TEXTAREA from './textarea';

function FORM_TEXTAREA({ property, label, value, onChange, placeholder, maxLength, disabled, errors, description }) {


    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER ==========================================================================================================
    return (
        <div className='detailsgroup'>
            <div className='detailsgroup__label text--sm-medium'>{label}</div>
            <Form.Field className='detailsgroup__value'>
                {description && <div className='detailsgroup__description text--sm-regular'>{description}</div>}
                <TEXTAREA
                    property={property}
                    value={value || ''}
                    onChange={onChange}
                    placeholder={placeholder || ''}
                    maxLength={maxLength}
                    disabled={disabled}
                    errors={errors}
                />
            </Form.Field>
        </div>
    );
}


FORM_TEXTAREA.propTypes = {
    description: propTypes.string,
    property: propTypes.string,
    value: propTypes.string,
    onChange: propTypes.func,
    placeholder: propTypes.string,
    maxLength: propTypes.number,
};

export default FORM_TEXTAREA;