import React from 'react';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import Icon from '../cmp_icon';

import './cmp_breadcrumb.css';

function CMP_BREADCRUMB({ icon, breadcrumb_elements, current_page_text }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const navigate = useNavigate();

    //  event listeners ------------------------------------------------------------------------------------------------


    //  functions ------------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    // RENDER APP ======================================================================================================


    return (
        <nav aria-label='Breadcrumb' id='breadcrumb'>
            <ol className='page__breadcrumb'>
                <li className='page__breadcrumb__item'>
                    <Icon name={icon} className='page__breadcrumb__icon' alt={icon} />
                </li>
                {breadcrumb_elements.map((item, index) =>
                    item.path ?
                    <li className='page__breadcrumb__item' key={'breadcrumb_element_' + index}>
                        <Button onClick={() => navigate(item.path)} >{item.text}</Button>
                    </li>
                    :
                    <li className='page__breadcrumb__item inactive' key={'breadcrumb_element_' + index}>{item.text}</li>
                )}
                <li className='page__breadcrumb__item current' aria-current='page'>{current_page_text}</li>
            </ol>
        </nav>
    );
}

CMP_BREADCRUMB.propTypes = {
    breadcrumb_elements: propTypes.arrayOf(propTypes.exact({
        path: propTypes.string,
        text: propTypes.string.isRequired
    })).isRequired,
    current_page_text: propTypes.string.isRequired,
};

export default CMP_BREADCRUMB;