import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../../../libs/api-lib';
import CMP_BREADCRUMB from '../../../../../components/cmp_breadcrumb/cmp_breadcrumb';
import TAB_SINGLE from './tab_single/tab_single';
import TAB_BULK from './tab_bulk/tab_bulk';



export default function INDIVIDUAL_ADD() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const params = useParams();

    const [ var_tab, set_tab ] = useState('SINGLE');
    const [ var_organization_id, set_organization_id ] = useState(null);
    const [ var_organization_name, set_organization_name ] = useState('');
    const [ var_features, set_features ] = useState({});


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_organization_id(params.organizationid);
        load_organization_name(params.organizationid);
        populate_record();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function load_organization_name(organization_id) {
        let organization = await API_get_organization(organization_id);
        set_organization_name(organization.name);
    }

    async function populate_record() {
        let record = await API_get_organization_features();
        set_features({id: params.organizationid, ...record});
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_organization(organization_id) {
        return API.get('alpha-org', '/get-organization/' + organization_id);
    }

    function API_get_organization_features() {
        return API.get('alpha-org', '/get-organization-features/' + params.organizationid);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_activetab){
        set_tab(var_activetab);
    }



    // RENDER APP ======================================================================================================

    return(
        <div className='page'>

            <div className='page__header page__header--background3'>
                <CMP_BREADCRUMB
                    icon='organization'
                    breadcrumb_elements={[{ path: '/organizations', text: 'Organizations' }, { path: '/organizations/' + var_organization_id, text: var_organization_name }, { path: '/organizations/' + var_organization_id + '/individuals', text: 'Individuals' }]}
                    current_page_text='Add Individual'
                />
                <h1 className='display--sm-regular'>Add Individual</h1>

            </div>

            <div className='tab'>
                <div className={'tab__item text--md-bold' + (var_tab === 'SINGLE' ? ' current' : '')} onClick={()=>onClick_tab('SINGLE')}>Single invite</div>
                <div className={'tab__item text--md-bold' + (var_tab === 'BULK' ? ' current' : '')} onClick={()=>onClick_tab('BULK')}>Bulk invite</div>
            </div>

            {var_tab === 'SINGLE' && <TAB_SINGLE organization_id={params.organizationid} assignments={var_features.assignments} />}

            {var_tab === 'BULK' && <TAB_BULK organization_id={params.organizationid} assignments={var_features.assignments} />}
        </div>
    )
};