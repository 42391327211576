import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import Icon from '../cmp_icon';


import './cmp_warning.css';


export default function CMP_WARNING ({ display, title, message, onClose }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_modal, set_modal ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('button:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            set_modal(document.querySelector('#cmp_warning'));
        }
    }, [display]);


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------

    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            open={display}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            id='cmp_warning'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_warning'
        >
            <div>
                <div className='warning__icon'>
                    <Icon name='warning_circle' className='warning-icon--yellow' alt='' />
                </div>
                <div className='warning__content'>
                    <div className='text--lg-medium' id='hdr_warning'>{title}</div>
                    <div className='text--sm-regular'>{message}</div>
                    <div className='card__header__left footer__btns'>
                        <Button className='secondary' onClick={onClose}>Close</Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}