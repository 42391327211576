import React from 'react';
import propTypes from 'prop-types';
import { Form, Select } from 'semantic-ui-react';
import Icon from '../../cmp_icon';

function SELECT({ property, value, onChange, placeholder, options, disabled, errors }) {

    //  event functions ------------------------------------------------------------------------------------------------
    let error = errors.find(item => item.property === property);



    // RENDER ==========================================================================================================
    return (
        <Form.Field>
            <Select
                name={property}
                value={value || ''}
                onChange={onChange}
                placeholder={placeholder || ''}
                options={options}
                disabled={disabled}
                fluid
                search={!disabled}
                icon={<Icon name='chevron_down' className='icon' />}
                error={!!error}
            />
            {error &&
                <div className='field__feedback error'>
                    <div className='text--sm-regular'>{error.description}</div>
                </div>
            }
        </Form.Field>
    );
}

SELECT.propTypes = {
    property: propTypes.string,
    value: propTypes.string,
    onChange: propTypes.func,
    placeholder: propTypes.string,
    options: propTypes.array,
    errors: propTypes.array
};

SELECT.defaultProps = {
    errors: []
};

SELECT.defaultProps = {
    errors: []
};

export default SELECT;