import React, { useEffect, useState } from 'react';
import API from '../../../../libs/api-lib';
import { Modal, Button } from 'semantic-ui-react';
import { FORM_TEXTAREA } from '../../../../components/cmp_form/cmp_form';
import Icon from '../../../../components/cmp_icon';
import Processing from '../../../../components/cmp_processing';

import './mdl_addkey.css';

export default function MDL_ADDKEY({ display, onChange, onClose }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_translation, set_translation ] = useState('');
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_translation('');
        set_errors([]);
    }, [display]);

    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_key() {
        return API.post('translation', '/post-key', {
            body: {
                code: var_translation,
            }
        });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event) {
        set_errors([]);
        set_translation(event.target.value);
    }

    async function onClick_save() {
        let errors = [];
        if (var_translation.length === 0) {
            errors.push({property: 'key', description: 'Please provide a key name to continue.'});
        }
        set_errors(errors);
        if (errors.length > 0) return;

        set_processing(true);
        try {
            await API_post_key();
            onChange(true, 'SUCCESS', 'Key Added');
        } catch (exception) {
            if (exception.response.status === 400) {
                let errors = [];
                if (exception.response.data.error_code === 'KEY_IS_REQUIRED') {
                    errors.push({property: 'key', description: 'Please provide a key name to continue.'});
                } else if (exception.response.data.error_code === 'DUPLICATE_KEY') {
                    errors.push({property: 'key', description: 'This key already exists.'});
                }
                set_errors(errors);
                if (errors.length > 0) return;
            } 
            console.log(exception);
            onChange(true, 'ERROR', 'An unexpected error occured. Please try again.');
        } finally {
            set_processing(false);
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='translations__crd_editor__mdl_addkey'
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
        >

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium'>Translation</div>
                </div>
                <div className='modal__header__right'>
                    <div className='modal__header__closeicon color--primary-500'>
                        <Icon onClick={onClose} name='xicon_nocircle' className='' alt='x icon' />
                    </div>
                </div>
            </div>

            <div className='modal__content'>
                <FORM_TEXTAREA
                    property='key'
                    label='Key'
                    value={var_translation}
                    onChange={onChange_input}
                    placeholder='Key'
                    maxLength={500}
                    errors={var_errors}
                />
            </div>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_save}>Add key</Button>
                    <Button className='secondary' onClick={onClose}>Close</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext='Processing' />

        </Modal>
    );

}