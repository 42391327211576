import React from 'react';
import propTypes from 'prop-types';
import {Form, Pagination, Select} from 'semantic-ui-react';

import './cmp_pagination.css'
import Icon from '../../cmp_icon';


function CMP_PAGINATION({ ready, offset, limit, totalrows, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const var_activepage = () => Math.floor(offset / limit) + 1;
    const var_totalpages = () => Math.ceil(totalrows / limit);
    const var_pages = () => Array.apply(null, Array(var_totalpages())).map((item, index) => ({value: index + 1, text: index + 1}));

    //  variable listeners ---------------------------------------------------------------------------------------------


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_page(e, { activePage }) {
        onChange && onChange(activePage);
    }

    function onChange_pageMobile(e, { value }) {
        onChange && onChange(value);
    }


    // RENDER APP ======================================================================================================

    return (
        <div className='pagination text--sm-medium'>
            {totalrows > limit && ready &&
                <>
                    <div className='table__controls--desktop'>
                        <Pagination
                            activePage={var_activepage()}
                            boundaryRange={1}
                            siblingRange={1}
                            totalPages={var_totalpages()}
                            onPageChange={onChange_page}
                            firstItem={null}
                            lastItem={null}
                            prevItem={null}
                            nextItem={null}
                        />
                    </div>

                    <div className='table__controls--mobile text--sm-regular'>
                        Page:
                        <Form.Field>
                            <Select
                                compact
                                onChange={onChange_pageMobile}
                                value={var_activepage()}
                                options={var_pages()}
                                icon={<Icon name='chevron_down' className='icon' />}
                            />
                        </Form.Field>
                    </div>
                </>
            }
        </div>
    );
}

CMP_PAGINATION.propTypes = {
    ready: propTypes.bool.isRequired,
    offset: propTypes.number.isRequired,
    limit: propTypes.number.isRequired,
    totalrows: propTypes.number.isRequired,
    onChange: propTypes.func.isRequired
};

export default CMP_PAGINATION;