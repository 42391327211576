import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import Icon from '../../cmp_icon';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import datelib from '../../../libs/date-lib';

import './cmp_export.css';

function CMP_EXPORT({ loading, totalrows, downloadname, downloadfunction, downloadconfig, tableid }) {
    //  variable declarations ------------------------------------------------------------------------------------------
    const MAX_BATCH_SIZE = 1000;

    const [ var_processing, set_processing ] = useState(false);

    //  general functions ----------------------------------------------------------------------------------------------

    function format_value(config, value) {
        return value === undefined || value === null
            ? null
            : config.datatype === 'date'
            ? datelib.epoch_to_date_string(value)
            : config.datatype === 'datetime'
            ? datelib.epoch_to_datetime_string(value, true)
            : value;
    }

    //  event functions ------------------------------------------------------------------------------------------------

    async function onClick_export() {
        // Make sure the page is ready and we're not in the middle of a download
        if (loading || totalrows === 0 || var_processing) {
            return;
        }

        set_processing(true);
        try {
            const workbook = XLSX.utils.book_new();
            let worksheet = XLSX.utils.aoa_to_sheet([downloadconfig.map(item => item.label)]);
            let offset = 0;
            while (offset < totalrows) {
                let data = await downloadfunction(MAX_BATCH_SIZE, offset);
                let formatted_data = data.map(record => {
                    let formatted_record = {};
                    downloadconfig.forEach(item => {
                        formatted_record[item.field] = format_value(item, record[item.field]);
                    });
                    return formatted_record;
                });
                XLSX.utils.sheet_add_json(worksheet, formatted_data, {skipHeader: true, origin: 'A' + (offset + 2)});
                offset += MAX_BATCH_SIZE;
            }

            XLSX.utils.book_append_sheet(workbook, worksheet, downloadname || 'Download');

            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const excelData = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
            FileSaver.saveAs(excelData, (downloadname || 'Download') + '.xlsx');
        } catch (e) {
            console.log(e);
            alert('Unable to download');
        } finally {
            set_processing(false);
        }
    }

    // RENDER ==========================================================================================================

    return (
        <Button id={'btn_export_' + tableid} className={'secondary btn_export' + (loading ? ' not_ready' : '')} onClick={onClick_export} aria-label='Export table data'>
            <Icon name='export' className='icon__export' alt='export icon' />
        </Button>
    );
}

export default CMP_EXPORT;