import React, { useState, useEffect } from 'react';
import API from './libs/api-lib';
import { useNavigate } from 'react-router-dom';
import Routes from './routes';
import Icon from './components/cmp_icon';
import auth from './libs/auth-lib';
import Processing from './components/cmp_processing';

import './ui_app.css';


function App() {

    //  variable declarations --------------------------------------------------------------------------------------------

    const navigate = useNavigate();
    const [ var_hamburger, set_hamburger ] = useState(false);
    const [ var_activemenuitem, set_activemenuitem ] = useState('organizations');
    const [ var_systemstatus, set_systemstatus ] = useState('OFFLINE');
    const [ var_reports_open, set_reports_open ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_systemstatus === 'OFFLINE' && auth.is_loggedin) {
            // user refreshed, just change the status to online
            set_systemstatus('ONLINE');
            set_activemenuitem(sessionStorage.getItem('activemenuitem'));
        } else if (var_systemstatus === 'LOGGEDIN') {
            // user just logged in, get authentication
            set_activemenuitem('organizations');
            sessionStorage.setItem('activemenuitem', 'organizations');
            get_authentication();
        } else if (var_systemstatus === 'OFFLINE') {
            // user just came to the app or logged out, redirect to the login page
           auth.iam.login(() => set_systemstatus('LOGGEDIN'));
        } else if (var_systemstatus === 'REDIRECTING') {
            set_systemstatus('ONLINE');
        } else if (var_systemstatus === 'ONLINE') {
            // user is online, add polling to make sure user is active
            const var_interval = setInterval(() => {
                if (Math.floor(Date.now() / 1000) - localStorage.getItem('activetime') > 1800) {
                    onClick_signout();
                }
            }, 60000);
            return () => clearInterval(var_interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_systemstatus]);



    //  functions calls --------------------------------------------------------------------------------------------------

    async function get_authentication() {
        let authentication = await API_get_authentication();
        if (!authentication) {
            // If we are unable to get authentication, sign user out
            onClick_signout();
            return;
        }
        auth.set_authenication({...authentication, authenticated: true}); // save authentication to storage
        set_systemstatus('REDIRECTING');
        navigate('/organizations');
    }


    //  API calls --------------------------------------------------------------------------------------------------------

    function API_get_authentication() {
        return API.get('gamma-admin', '/get-authentication');
    }

    //  event functions --------------------------------------------------------------------------------------------------

    function onClick_hamburger() {
        set_hamburger(!var_hamburger)
    }

    async function onClick_signout() {
        await auth.iam.logout();
        auth.remove();
        sessionStorage.clear();
        set_systemstatus('OFFLINE');
        set_hamburger(false);
    }

    function onClick_option(menuitem, var_route){
        set_activemenuitem(menuitem);
        sessionStorage.setItem('activemenuitem', menuitem);
        navigate(var_route);
        set_hamburger(false);
    }


    // RENDER APP ========================================================================================================

    return (
        <div className='app'>
            {var_systemstatus !== 'ONLINE'
            ?
                <div className='offline'>
                    <Processing display={true} style={{ backgroundColor: 'unset' }} />
                </div>
            :   <>

                    <div className={'nav text--md-medium' + (var_hamburger ? ' nav__expanded--display' : ' nav__expanded--hide')}>

                        <div className='nav__header'>

                            <div className='nav__header__portal text--xl-bold'>
                                <img src={'/assets/Credivera White Icon.png'} alt='Credivera logo'/>
                                Admin
                            </div>

                            <div className='nav__header__hamburger' onClick={() => onClick_hamburger()}>
                                {var_hamburger
                                    ? <Icon name='hamburger_close' className='icon__hamburger_close' alt='close menu icon' />
                                    : <Icon name='hamburger' className='icon__hamburger' alt='hamburger menu icon' />
                                }
                            </div>

                        </div>

                        <div className='nav__expanded'>

                            <div className='nav__expanded__options'>

                                <div className={'nav__expanded__option' + (var_activemenuitem === 'individuals' ? ' active' : '')} onClick={() => onClick_option('individuals', '/individuals')}>
                                    <Icon name='individuals' className='icon__menuitem' alt='' />
                                    <div className='nav__expanded__option__primarytext text--md-medium'>Individuals</div>
                                </div>
                                <div className={'nav__expanded__option' + (var_activemenuitem === 'organizations' ? ' active' : '')} onClick={() => onClick_option('organizations', '/organizations')}>
                                    <Icon name='organization' className='icon__menuitem' alt='organizations icon' />
                                    <div className='nav__expanded__option__primarytext text--md-medium'>Organizations</div>
                                </div>
                                <div className={'nav__expanded__option' + (var_activemenuitem === 'api' ? ' active' : '')} onClick={() => onClick_option('api', '/api')}>
                                    <Icon name='api' className='icon__menuitem' alt='api icon' />
                                    <div className='nav__expanded__option__primarytext text--md-medium'>API</div>
                                </div>
                                <div className='nav__expanded__option' onClick={() => set_reports_open(!var_reports_open)}>
                                    <Icon name='menu_reports' className='icon__menuitem' alt='reports icon' />
                                    <div className='nav__expanded__option__primarytext text--md-medium'>Reports</div>
                                    <div style={ {flex: 1 }} />
                                    {var_reports_open
                                        ? <Icon name='chevron_up' className='icon__nav_chevron' alt='' />
                                        : <Icon name='chevron_down' className='icon__nav_chevron' alt='' />
                                    }
                                </div>

                                {var_reports_open &&
                                    <>
                                    <div className={'nav__expanded__option' + (var_activemenuitem === 'corporate_scorecard' ? ' active' : '')}
                                        onClick={() => onClick_option('corporate_scorecard', 'reports/corporate-scorecard')}>
                                        <div className='icon__menuitem'></div>
                                        <div className='nav__expanded__option__primarytext text--sm-medium'>Corporate scorecard</div>
                                    </div>
                                    <div className={'nav__expanded__option' + (var_activemenuitem === 'self_verified_creds' ? ' active' : '')}
                                        onClick={() => onClick_option('self_verified_creds', 'reports/self-verified-credentials')}>
                                        <div className='icon__menuitem'></div>
                                        <div className='nav__expanded__option__primarytext text--sm-medium'>Self-verified creds</div>
                                    </div>
                                    </>
                                }

                                <div className={'nav__expanded__option' + (var_activemenuitem === 'settings' ? ' active' : '')} onClick={() => onClick_option('settings', '/settings')}>
                                    <Icon name='settings' className='icon__menuitem' alt='settings icon' />
                                    <div className='nav__expanded__option__primarytext text--md-medium'>Platform Settings</div>
                                </div>

                                <div className={'nav__expanded__option' + (var_activemenuitem === 'translations' ? ' active' : '')} onClick={() => onClick_option('translations', '/translations')}>
                                    <Icon name='translations' className='icon__menuitem' alt='translations icon' />
                                    <div className='nav__expanded__option__primarytext text--md-medium'>Translations</div>
                                </div>

                                </div>

                                <div className='nav__expanded__divider'></div>

                                <div className='nav__expanded__account'>

                                    <div className={'nav__expanded__option'+ (var_activemenuitem === 'profile' ? ' active' : '')} onClick={() => onClick_option('profile', '/profile')}>
                                        <Icon name='account_setting' className='icon__account_setting' alt='account settings icon' />
                                        <div className='nav__expanded__option__textwrapper'>
                                            <div className='nav__expanded__option__primarytext text--md-medium'>{auth.firstname} {auth.lastname}</div>
                                            <div className='nav__expanded__option__secondarytext text--xs-regular profile_email'>{auth.email}</div>
                                        </div>
                                    </div>

                                    <div className='nav__expanded__option'>
                                        <Icon name='signout' className='icon__signout' alt='signout icon' />
                                        <div className='nav__expanded__option__primarytext text--md-medium' onClick={() => onClick_signout()}>Sign out</div>
                                    </div>

                            </div>

                        </div>

                    </div>

                    <div className={'cont' + (var_hamburger ? ' nav__expanded--display' : ' nav__expanded--hide')}>
                        <Routes appProps={{set_systemstatus}} />
                    </div>
                </>
            }

        </div>
    );
}

export default App;
