import Moment from 'moment';

const datelib = {
    // Gets the local timezone
    get timezone() { return Intl.DateTimeFormat().resolvedOptions().timeZone; },

    // Gets the local timezone encoded for a querystring
    get querystring_timezone() { return encodeURIComponent(this.timezone); },

    get utc_offset() { return Moment().format('Z'); },

    get floor_date() { return new Date('1900-01-01T00:00:00') },
    get ceiling_date() { return new Date('2100-01-01T00:00:00') },

    get_minimum_date(date) {
        return this.constrain_date(date, 'floor');
    },

    get_maximum_date(date) {
        return this.constrain_date(date, 'ceiling');
    },

    get_datepicker_open_to_date(date, minimum, maximum) {
        let open_to_date = new Date();
        if (date) {
            open_to_date = (date.getTime() > minimum.getTime()) ? date : minimum;
            open_to_date = (date.getTime() < maximum.getTime()) ? open_to_date : maximum;
        }
        return open_to_date;
    },

    constrain_date(date, constrain_direction) {
        let constrained_date = constrain_direction === 'floor' ? this.floor_date : this.ceiling_date;
        if (!date) return constrained_date;
        if (date.getTime() > this.floor_date.getTime() && date.getTime() < this.ceiling_date.getTime()) return date;
        return constrained_date;
    },

    // Converts a date to its epoch
    date_to_epoch: function(thedate) { return this.moment_to_epoch(Moment(thedate)); },

    // takes a date and converts it to the end of the day of that date (ie: Mon Nov 01 2021 23:59:59)
    date_to_end_of_day_epoch(thedate) { return this.moment_to_epoch(Moment(new Date(thedate.getFullYear(), thedate.getMonth(), thedate.getDate(), 23, 59, 59))); },

    // takes a date and converts it to midnight of the same date
    date_to_midnight_date: function(date) { return new Date(date.setHours(0, 0, 0, 0)); },

    // Converts a moment to its epoch
    moment_to_epoch: function(themoment) { return Math.floor(themoment.valueOf() / 1000); },

    // Converts an epoch to its moment
    epoch_to_utcmoment: function(epoch) { return Moment.utc(epoch*1000); },

    // Takes a date in local time (ie: Mon Nov 01 2021 00:00:00 GMT-0600 (Mountain Daylight Time))
    // and converts it to the moment of midnight utc of the same date.  It does not convert timezone.
    localdate_to_utc_midnight_moment: function(localdate) { return Moment.utc(Moment(localdate).format('YYYY-MM-DD'), 'YYYY-MM-DD'); },

    // Takes a date in local time (ie: Mon Nov 01 2021 00:00:00 GMT-0600 (Mountain Daylight Time))
    // and converts it to midnight utc of the same date.  It does not convert timezone.
    localdate_to_utc_midnight_date: function(localdate) { return this.localdate_to_utc_midnight_moment(localdate).toDate(); },

    // Takes a date in local time (ie: Mon Nov 01 2021 00:00:00 GMT-0600 (Mountain Daylight Time))
    // and converts it to the epoch of midnight utc of the same date.  It does not convert timezone.
    localdate_to_utc_midnight_epoch: function(localdate) { return this.moment_to_epoch(this.localdate_to_utc_midnight_moment(localdate)); },

    // Takes a utc moment and converts it to the moment of midnight local time of the same date.  It does not convert timezone.
    utcmoment_to_local_midnight_moment: function(utcdate) { return Moment(utcdate.format('YYYY-MM-DD'), 'YYYY-MM-DD'); },

    // Takes a utc moment and converts it to midnight local time of the same date.  It does not convert timezone.
    utcmoment_to_local_midnight_date: function(utcdate) { return this.utcmoment_to_local_midnight_moment(utcdate).toDate(); },

    // Takes a utc moment and converts it to the epoch of midnight local time of the same date.  It does not convert timezone.
    utcmoment_to_local_midnight_epoch: function(utcdate) { return this.moment_to_epoch(this.utcmoment_to_local_midnight_moment(utcdate)); },

    // Takes a epoch and gets the display date string
    epoch_to_date_string: function(epoch, convert_tz = false) { if (!epoch) return null; return Moment.unix(epoch).utc(convert_tz).format('YYYY-MM-DD'); },

    // Takes a epoch and gets the display datetime string
    epoch_to_datetime_string: function(epoch, convert_tz = false) { if (!epoch) return null; return Moment.unix(epoch).utc(convert_tz).format('YYYY-MM-DD h:mm A'); },

    // Takes a isodate and gets the display datetime string
    iso_to_datetime_string: function(iso, convert_tz = false) { if (!iso) return null; return Moment(iso).utc(convert_tz).format('YYYY-MM-DD h:mm A'); },

    // Takes a isodate and gets the display date string
    iso_to_date_string: function(iso, convert_tz = false) { if (!iso) return null; return Moment(iso).utc(convert_tz).format('YYYY-MM-DD'); },

    datepicker_format: 'yyyy-MM-dd',

    // Takes an object and returns if it is a valid Date object
    is_valid_date: function (d) { return d === null || (d instanceof Date && !isNaN(d)); },

    /**
     * Returns a string including '-' in raw_value string to match 'YYYY-MM-DD'
     * @param {String} raw_value
     * @returns {String}
    **/
    format_date_text:
        function (raw_value) {
            if (!raw_value) return null;
            let value_without_dashes = raw_value.replace(/-/g, '');
            let formatted_value = ''
            for (let index = 0; index < value_without_dashes.length; index++) {
                if (index === 4) {
                    formatted_value += '-'
                }
                if (index === 6) {
                    formatted_value += '-'
                }
                formatted_value += value_without_dashes[index];
            }
            return formatted_value;
        }
};

export default datelib;