import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Button, Checkbox, Form, Input } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import Icon from '../../cmp_icon';
import Moment from 'moment';
import datelib from '../../../libs/date-lib';
import _ from 'lodash';

import './cmp_filter.css';
import 'react-datepicker/dist/react-datepicker.css';

function CMP_FILTER({ custom_props, is_open, field, datatype, filtertype,
    filterinformation, populatefilterfunction,
    onHide, onSort, onFilterChange }) {
    //  variable declarations ------------------------------------------------------------------------------------------


    const filter_id = _.uniqueId('mdl_column_filter_');

    const [ var_startdate, set_startdate ] = useState(null);
    const [ var_enddate, set_enddate ] = useState(null);
    const [ var_minnumber, set_minnumber ] = useState(null);
    const [ var_maxnumber, set_maxnumber ] = useState(null);
    const [ var_active_datefield, set_active_datefield ] = useState('From');
    const [ var_searchterm, set_searchterm ] = useState('');
    const [ var_modal, set_modal ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('form.sort_modal__content, button:not([disabled]), input[type="text"]:not([disabled]), input[type="search"]:not([disabled]), input[type="checkbox"]:not([disabled]), input[type="number"]:not([disabled]), .text--anchor.clear');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus({ preventScroll: true });

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        const onKeyDown = (e) => {
            e.key === 'Escape' && onHide && onHide();
        }
        const onClick = (e) => {
            onHide && onHide();
        }
        window.addEventListener('keydown', onKeyDown);
        window.addEventListener('click', onClick);

        return () => {
            window.removeEventListener('keydown', onKeyDown);
            window.removeEventListener('click', onClick);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (field) {
            switch (filtertype) {
                case 'date':
                    load_dates();
                    break;
                case 'number':
                    load_numeric_range();
                    break;
                case 'option':
                    load_lookup_values();
                    break;
                case 'text':
                    load_searchterm();
                    break;
                default:
                    throw new Error('Unknown filter type');
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field]);


    useEffect(() => {
        if (is_open && filterinformation.length > 0) {
            set_modal(document.querySelector(`#${filter_id}`));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open, filterinformation]);

    //  functions ------------------------------------------------------------------------------------------------------

    function load_dates() {
        let filteritem = filterinformation.find(item => item.name === field);
        if (filteritem) {
            if (datatype === 'date') {
                set_startdate(filteritem.startdate ? datelib.utcmoment_to_local_midnight_date(datelib.epoch_to_utcmoment(filteritem.startdate)) : null);
                set_enddate(filteritem.enddate ? datelib.utcmoment_to_local_midnight_date(datelib.epoch_to_utcmoment(filteritem.enddate)) : null);
            } else {
                set_startdate(filteritem.startdate ? datelib.epoch_to_utcmoment(filteritem.startdate).local().toDate() : null);
                set_enddate(filteritem.enddate ? datelib.epoch_to_utcmoment(filteritem.enddate).local().toDate() : null);
            }
        } else {
            const filter = [...filterinformation];
            filter.push({ name: field, datatype, filtertype, startdate: null, enddate: null, applied: false });
            onFilterChange(filter);
            set_startdate(null);
            set_enddate(null);
        }
    }

    async function load_numeric_range() {
        let filteritem = filterinformation.find(item => item.name === field);
        if (filteritem) {
            set_minnumber(filteritem.minnumber);
            set_maxnumber(filteritem.maxnumber);
        } else {
            const filter = [...filterinformation];
            filter.push({ name: field, datatype, filtertype, minnumber: null, maxnumber: null, applied: false });
            onFilterChange(filter);
            set_minnumber(null);
            set_maxnumber(null);
        }
    }

    async function load_lookup_values() {
        try {
            if (filterinformation.some(item => item.name === field)) return;

            let results = await populatefilterfunction(field, datatype === 'datetime' ? 'descending' : 'ascending');
            let options = results.reduce((acc, item) => item[field] !== null && item[field] !== '' ? acc.concat({value: item[field], selected: true, blank: false}) : acc, []);
            if (options.length < results.length){
                options.unshift({value: '(Blanks)', selected: true, blank: true});
            }
            const filter = [...filterinformation];
            filter.push({ name: field, datatype, filtertype, options, applied: false });
            onFilterChange(filter);
        } catch(e) {
            console.log(e);
        }
    }

    function load_searchterm() {
        let filteritem = filterinformation.find(item => item.name === field);
        if (filteritem) {
            set_searchterm(filteritem.searchterm.trim().toLowerCase());
        } else {
            const filter = [...filterinformation];
            filter.push({ name: field, datatype, filtertype, searchterm: '', applied: false });
            onFilterChange(filter);
            set_searchterm('');
        }
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClear_date() {
        set_startdate(null);
        set_enddate(null);
        const filter = [...filterinformation];
        const filteritem = filter.find(item => item.name === field);
        filteritem.startdate = null;
        filteritem.enddate = null;
        filteritem.applied = false;
        onFilterChange(filter, true);
    }

    function onKeyDown_clear_date(e) {
        if (e.key === 'Enter') {
            set_startdate(null);
            set_enddate(null);
            const filter = [...filterinformation];
            const filteritem = filter.find(item => item.name === field);
            filteritem.startdate = null;
            filteritem.enddate = null;
            filteritem.applied = false;
            onFilterChange(filter, true);
        }
    }

    function onChange_date(date) {
        if (!date) return;
        var_active_datefield === 'From' ? set_startdate(date) : set_enddate(date);
        const filter = [...filterinformation];
        const filteritem = filter.find(item => item.name === field);
        let new_date = datatype === 'date'
            ? datelib.localdate_to_utc_midnight_epoch(date)
            : var_active_datefield === 'From'
            ? datelib.moment_to_epoch(Moment(date).utc())
            : (datelib.moment_to_epoch(Moment(date).add(1, 'days').utc()) - 1);
        if ((var_active_datefield === 'From' && filteritem.startdate === new_date)
            || (var_active_datefield === 'To' && filteritem.enddate === new_date)) {
                return; // if the date hasn't changed then do nothing
        }
        var_active_datefield === 'From' ? filteritem.startdate = new_date : filteritem.enddate = new_date;
        filteritem.applied = true;
        onFilterChange(filter, true);
    }

    function onChangeRaw_date(e) {
        let new_value = e.target.value;
        // Only accept numbers and dashes
        if (!/^[0-9-]*$/.test(new_value)) return;

        let formatted_value = datelib.format_date_text(new_value);
        let formatted_value_as_date = new Date(formatted_value + 'T00:00:00');

        // User typed full date
        if (formatted_value?.length >= 10) {
            if (datelib.is_valid_date(formatted_value_as_date)) {
                onChange_date(formatted_value_as_date);
            }
        }
    }

    function onChange_numericrange (e, { value }) {
        value = value.length === 0 ? null : Number(value);
        e.target.name === 'sort_modal__minnumber' ? set_minnumber(value) : set_maxnumber(value);
        const filter = [...filterinformation];
        const filteritem = filter.find(item => item.name === field);
        if ((e.target.name === 'sort_modal__minnumber' && filteritem.minnumber === value)
            || (e.target.name === 'sort_modal__maxnumber' && filteritem.maxnumber === value)) {
            return; // if the number hasn't changed then do nothing.
        }
        e.target.name === 'sort_modal__minnumber' ? filteritem.minnumber = value : filteritem.maxnumber = value;
        filteritem.applied = filteritem.minnumber !== null || filteritem.maxnumber !== null;
        onFilterChange(filter, true);
    }

    function onClear_numericrange() {
        set_minnumber(null);
        set_maxnumber(null);
        const filter = [...filterinformation];
        const filteritem = filter.find(item => item.name === field);
        filteritem.minnumber = null;
        filteritem.maxnumber = null;
        filteritem.applied = false;
        onFilterChange(filter, true);
    }

    function onKeyDown_numericrange(e) {
        if (e.key === 'Enter') {
            set_minnumber(null);
            set_maxnumber(null);
            const filter = [...filterinformation];
            const filteritem = filter.find(item => item.name === field);
            filteritem.minnumber = null;
            filteritem.maxnumber = null;
            filteritem.applied = false;
            onFilterChange(filter, true);
        }
    }

    function onClick_option(value) {
        const filter = [...filterinformation];
        const filteritem = filter.find(item => item.name === field);
        const option = filteritem.options.find(option => option.value === value);
        option.selected = !option.selected;
        filteritem.applied = filteritem.options.some(option => !option.selected);
        onFilterChange(filter, true);
    }

    function onChange_searchterm(e, { value }) {
        set_searchterm(value);
        const filter = [...filterinformation];
        const filteritem = filter.find(item => item.name === field);
        filteritem.searchterm = value.trim().toLowerCase();
        filteritem.applied = filteritem.searchterm.length > 0;
        onFilterChange(filter, true);
    }

    function onClear_searchterm() {
        set_searchterm('');
        const filter = [...filterinformation];
        const filteritem = filter.find(item => item.name === field);
        filteritem.searchterm = '';
        filteritem.applied = false;
        onFilterChange(filter, true);
    }

    function get_displayoptions() {
        let filteritem = filterinformation.find(item => item.name === field);
        if (filtertype === 'option') {
            return (filteritem && filteritem.options) ? filteritem.options : [];
        }
    }

    // RENDER APP ======================================================================================================

    return (
        <div
            id={filter_id}
            className='sort_modal'
            onClick={e => e.stopPropagation()}
            aria-modal='true'
            role='dialog'
            aria-label='Table column filter menu'
        >
            <Form tabIndex='0' className='sort_modal__content'>
                <div className='text--sm-medium sort_modal__header'>Sort</div>
                <div className='sort_modal__content__buttons'>
                    <Button type='button' className='secondary sort_ascending' onClick={() => {onSort && onSort(field, true); onHide && onHide();}}>
                        <div>Ascending</div>
                    </Button>
                    <Button type='button' className='secondary sort_descending' onClick={() => {onSort && onSort(field, false); onHide && onHide();}}>
                        <div>Descending</div>
                    </Button>
                </div>
                {
                    filtertype === 'date'
                    ? render_date()
                    : filtertype === 'option'
                    ? render_option()
                    : filtertype === 'text'
                    ? render_text()
                    : filtertype === 'number'
                    ? render_number()
                    : null
                }
            </Form>
        </div>
    );

    function render_date() {
        return (
            <>
                <div className='text--sm-medium sort_modal__header'>Date {(var_startdate || var_enddate) && <div className='text--anchor clear' onClick={onClear_date} onKeyDown={onKeyDown_clear_date} tabIndex='0'>Clear</div>}</div>
                <div className='sort_modal__content'>
                    <div className='sort_modal__content__buttons date'>
                        <Button type='button' className='secondary calendar_btn' onClick={() => set_active_datefield('From')}>
                            {var_startdate ? datelib.epoch_to_date_string(datelib.date_to_epoch(new Date(var_startdate)), false) : 'From'}
                        </Button>
                        <Icon name='arrow_right' className='sort_modal__icon__arrow' alt='' />
                        <Button type='button' className='secondary calendar_btn' onClick={() => set_active_datefield('To')}>
                            {var_enddate ? datelib.epoch_to_date_string(datelib.date_to_epoch(new Date(var_enddate)), false) : 'To'}
                        </Button>
                    </div>
                    {var_active_datefield &&
                        <div className='sort_modal__content__calendar'>
                            <Form.Field>
                                <label>{var_active_datefield}</label>
                                <DatePicker
                                    showIcon
                                    className='datepicker'
                                    onChange={onChange_date}
                                    onChangeRaw={onChangeRaw_date}
                                    dateFormat={datelib.datepicker_format}
                                    isClearable={false}
                                    placeholderText='YYYY-MM-DD'
                                    selected={var_active_datefield === 'From' ? var_startdate : var_enddate}
                                    showYearDropdown
                                    allowSameDay={true}
                                    icon={<Icon name='calendar' className='icon icon__calendar' alt='calendar icon'/>}
                                />
                            </Form.Field>
                        </div>
                    }
                </div>
            </>
        );
    }

    function render_option() {
        return (
            <>
                <div className='text--sm-medium sort_modal__header'>Filter</div>
                <div className='sort_modal__content'>
                    {get_displayoptions().map(option => (
                            <div key={'filteroption-' + option.value}>
                                <Checkbox checked={option.selected}
                                    onClick={() => onClick_option(option.value)}
                                    label={option.value}
                                />
                            </div>
                        ))
                    }
                </div>
            </>
        );
    }

    function render_number() {
        return (
            <>
                <div className='text--sm-medium sort_modal__header'>Range {(var_minnumber !== null || var_maxnumber !== null) && <div className='text--anchor clear' onClick={onClear_numericrange}>Clear</div>}</div>
                <div className='sort_modal__content'>
                    <div className='sort_modal__content__inputs'>
                        <Form.Field>
                            <Input
                                name='sort_modal__minnumber'
                                className='sort_modal__content__inputs__range__left'
                                type='number'
                                inputMode='numeric'
                                value={var_minnumber === null ? '' : var_minnumber}
                                onChange={onChange_numericrange}
                                placeholder='Min'
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                name='sort_modal__maxnumber'
                                className='sort_modal__content__inputs__range__right'
                                type='number'
                                inputMode='numeric'
                                value={var_maxnumber === null ? '' : var_maxnumber}
                                onChange={onChange_numericrange}
                                placeholder='Max'
                            />
                        </Form.Field>
                    </div>
                </div>
            </>
        );
    }

    function render_text() {
        return (
            <>
                <div className='text--sm-medium sort_modal__header'>Search</div>
                <div className='sort_modal__content'>
                    <Form.Field>
                        <Input
                            type='search'
                            value={var_searchterm}
                            onChange={onChange_searchterm}
                            placeholder='Search'
                            icon={var_searchterm.trim().length === 0 ? <Icon name='search' className='icon icon__search' alt='' /> : <Icon onClick={onClear_searchterm} name='xicon_nocircle' className='icon icon__clear' alt='' />}
                        />
                    </Form.Field>
                </div>
            </>
        );
    }
}

CMP_FILTER.propTypes = {
    custom_props: propTypes.object,
    is_open: propTypes.bool,
    field: propTypes.string.isRequired,
    datatype: propTypes.oneOf(['date', 'datetime', 'number', 'text']).isRequired,
    filtertype: propTypes.oneOf(['date', 'number', 'option', 'text']).isRequired,
    filterinformation: propTypes.array.isRequired,
    populatefilterfunction: propTypes.func.isRequired,
    onHide: propTypes.func.isRequired,
    onSort: propTypes.func.isRequired,
    onFilterChange: propTypes.func.isRequired
};

export default CMP_FILTER;