import React from 'react';
import CRD_DETAILS from './crd_details/crd_details';
import CRD_LOGOS from './crd_logos/crd_logos';



export default function TAB_DETAILS({organization_id, activetab, onChange_organization_name}){

    //  variable declarations ------------------------------------------------------------------------------------------


    //  event listeners ------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------


    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (

        <>
            <CRD_DETAILS activetab={activetab} organization_id={organization_id} onChange_organization_name={onChange_organization_name} />
            <CRD_LOGOS activetab={activetab} organization_id={organization_id} />
        </>
    );
};